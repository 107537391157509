import styled from '@emotion/styled';

export const SplashScreenWrapper = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    height: 100vh;
    img {
        width: 200px;
        height: auto;
        margin-right: 16px;
    }
`;

export const Text = styled.span``;
