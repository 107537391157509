import styled from '@emotion/styled';
import { NavLink, Link } from 'react-router-dom';

export const Container = styled.div`
    background: ${(props) => props.theme.colors.primary};
    height: 100%;
`;

export const Logo = styled(Link)`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 20px;
    white-space: nowrap;
    text-decoration: none;
    outline: none;
    img {
        width: 32px;
        height: auto;
        margin-right: 16px;
    }
`;

export const Text = styled.span``;
export const VersionText = styled.span`
	position: absolute;
	bottom: 80px;
`;

export const MobileMenu = styled.div`
    ${(props) => props.theme.breakPoint.mobile} {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center
    }
`;

export const Hamburger = styled.button`
    cursor: pointer;
    display: none;
    justify-content: space-between;
    align-items: center;
    width: 32px;
    background: transparent;
    outline: none; 
    border: none;
    ${(props) => props.theme.breakPoint.mobile} {
        display: flex;
        margin-right: 20px;
    }
`;

export const HamburgerIcon = styled.div`
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background-color: ${(props) => props.theme.colors.light};
`;

export const Navbar = styled.div`
    padding: 0 15px;
    margin-top: 10px;
    height: 100%;
    overflow: hidden;
`;

export const NavItems = styled.ul`
    padding: 0;
    margin: 0;
    list-style: none;
    height: calc(100vh - 250px);
    overflow-y: auto;
    overflow-x: hidden;
    padding-bottom: 10px;
`;

export const ListItem = styled.li`
    padding-bottom: 0;
    position: relative;
    list-style-type: none;
    outline: none;
    svg {
        font-size: 20px;
        width: 20px;
        transition: all 0.3s ease-out 0s;
    }
    a {
        outline: none;
    }
`;

export const NavLinkText = styled.span`
    transition: all 0.3s ease-out 0s;
    text-decoration: none;
`;

export const Separator = styled.div`
    height: 2px;
    width: 20px;
    background-color: ${(props) => props.theme.colors.light};
    margin-left: 15px;
    margin-bottom: 17px;
`;

export const Dropdown = styled.button`
    font-weight: normal;
    font-size: 14px;
    white-space: nowrap;
    color: ${(props) => props.theme.colors.light};
    position: relative;
    padding: 13px 15px;
    line-height: 17px;
    display: flex;
    align-items: center;
    overflow: hidden;
    border-radius: 15px;
    text-transform: capitalize;
    text-decoration: none;
    background-color: transparent;
    outline: none;
    border: none;
    cursor: pointer;
    font-family: inherit;
    svg {
        width: 20px;
        height: 20px;
    }
    &:hover {
        background-color: ${(props) => props.theme.colors.shadow}10;
        color: ${(props) => props.theme.colors.primaryLight};
    }
`;

export const DropdownItem = styled.span`
    display: flex;
    align-items: center;
    svg {
        width: 20px;
        height: 20px;
        margin-left: 10px;
        transition: all 0.3s;
        transform: ${({ subnav }) => subnav ? 'rotate(180deg)' : 'rotate(0deg)'};
        ${(props) => props.theme.breakPoint.mobile} {
            margin-right: 0px;
        }
    }
    &:hover {
        color: ${(props) => props.theme.colors.primaryLight};
    }
    ${(props) => props.theme.breakPoint.mobile} {
        display: flex;
        align-items: center;
        justify-content: center;
    }
`;

export const SidebarDropdownButtonSpanText = styled.span`
    ${(props) => props.theme.breakPoint.mobile} {
        display: none;
    }
`;

export const SubNavItems = styled.ul`
    display: ${({ subnav }) => subnav ? 'block' : 'none'};
    margin-left: 10px;
`;

export const SubNavListItem = styled.li`
    width: 100%;
    list-style-type: none;
    text-decoration:none;
    font-size: 14px;
    line-height: 26px;
`;

export const SubNavLink = styled(NavLink)`
    display: block;
    padding: 5px 10px;
    color: ${(props) => props.theme.colors.light};
    font-weight: 400;
    text-decoration: none;
    border-radius: 10px;
    cursor: pointer;
    outline: none;
    transition: all 0.3s;
    margin: 5px 0;
    &.active {
        background-color: ${(props) => props.theme.colors.light};
        color: ${(props) => props.theme.colors.primary};
    }
`;

export const Toggle = styled.button`
    width: 32px;
    height: 32px;
    border-radius: 50%;
    border: 2px solid ${(props) => props.theme.colors.light};
    background: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${(props) => props.theme.colors.light};
    position: absolute;
    bottom: 30px;
    left: 24px;
    outline: none;
    cursor: pointer;
    transition: all 0.3s;
    transform: ${({ iconChange }) => iconChange ? 'rotate(-180deg)' : 'rotate(0deg)'};
    svg {
        margin-right: 0 !important;
    }
    ${(props) => props.theme.breakPoint.tabletLandscape} {
        display: none;
    }
    ${(props) => props.theme.breakPoint.mobileAndTablet} {
        display: none;
    }
`;