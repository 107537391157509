import { createAsyncThunk } from '@reduxjs/toolkit';
import { callGetAPI, callPostAPI } from '../../services/axios';

export const getHospitalList = createAsyncThunk(
	'getHospitalList',
	async(params, { rejectWithValue }) => {
		try {
			const response = await callGetAPI({ route: 'superadmin/unassign/hospital/list' });
			return { response };
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	},
);
export const getDoctors = createAsyncThunk(
	'getDoctors',
	async(params, { rejectWithValue }) => {
		try {
			const response = await callGetAPI({ route: 'superadmin/doctor/list', params });
			return { response };
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	},
);
export const addDoctor = createAsyncThunk(
	'addDoctor',
	async(params, { rejectWithValue }) => {
		try {
			const response = await callPostAPI({ route: 'superadmin/add/doctor', body: params.body });
			return { response };
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	},
);
export const getAssignHospitalList = createAsyncThunk(
	'getAssignHospitalList',
	async(params, { rejectWithValue }) => {
		try {
			const response = await callGetAPI({ route: 'superadmin/assign/hospital/list' });
			return { response };
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	},
);
export const filterDoctor = createAsyncThunk(
	'filterDoctor',
	async(params, { rejectWithValue }) => {
		try {
			const response = await callGetAPI({ route: `superadmin/doctor/list?${params.body.route}` });
			return { response };
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	},
);
export const updateDoctor = createAsyncThunk(
	'updateUser',
	async(params, { rejectWithValue }) => {
		try {
			const response = await callPostAPI({ route: 'superadmin/update/doctor', body: params.body });
			return { response };
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	},
);
export const forgetDoctorPassword = createAsyncThunk(
	'forgetDoctorPassword',
	async(params, { rejectWithValue }) => {
		try {
			const response = await callPostAPI({ route: 'hospital/forgotpassword', body: params.body });
			return { response };
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	},
);
export const deleteDoctor = createAsyncThunk(
	'deleteDoctor',
	async(params, { rejectWithValue }) => {
		try {
			const response = await callPostAPI({ route: 'superadmin/delete/doctor', body: params.body });
			return { response };
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	},
);
export const getEditAssignedHospitalList = createAsyncThunk(
	'getEditAssignedHospitalList',
	async(params, { rejectWithValue }) => {
		const queryParams = {
			doctor_id: params.doctor_id,
		};
		try {
			const response = await callGetAPI({ route: 'superadmin/doctor/hospital/list', params: queryParams });
			return { response };
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	},
);
export const getEditDoctor = createAsyncThunk(
	'getEditDoctor',
	async(params, { rejectWithValue }) => {
		const queryParams = {
			doctor_id: params.doctor_id,
		};
		try {
			const response = await callGetAPI({ route: 'superadmin/get/doctor', params: queryParams });
			return { response };
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	},
);