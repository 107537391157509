import { createSlice } from '@reduxjs/toolkit';
import { handleLogin, forgotPassword, verifyOtp, handleLogOut, resendOtp, resetPassword } from '../auth';

const initialState = {
	isLoggedIn: false,
	isOTPSent: false,
	isResendOTPSent: false,
	loginSuccessMessage: '',
	loginErrorMessage: '',
	otpMessage: '',
	error: false,
	loading: false,
	loginResponse: {},
	forgotSuccessMessage: {},
	isForgotSuccess: false,
	forgotErrorMessage: '',
	resendOtpCode: {},
	setToken: '',
	userDetails: [],
	resetPasswordSuccess:{},
	resetPasswordError:'',
};

const authSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		loggedUserInfo: (state, { payload }) => {
			state.userDetails = payload;
		},
	},
	extraReducers: {
		[handleLogin.fulfilled]: (state, { payload }) => {
			state.isOTPSent = true;
			state.loading = false;
			state.loginResponse = payload.response;
			state.loginSuccessMessage = payload.response?.data.message;
			state.loginErrorMessage = '';
		},
		[handleLogin.pending]: (state) => {
			state.loading = true;
		},
		[handleLogin.rejected]: (state, err) => {
			state.error = true;
			state.loading = false;
			state.loginErrorMessage = err.payload?.message;
		},
		[forgotPassword.fulfilled]: (state, { payload }) => {
			state.isForgotSuccess = true;
			state.forgotSuccessMessage = payload.response;
			state.loginErrorMessage = '';
		},
		[forgotPassword.rejected]: (state, err) => {
			state.isForgotReject = true;
			state.forgotErrorMessage = err.payload.message;
			state.loginErrorMessage = '';
		},
		[resetPassword.pending]: (state) => {
			state.loading = true;
			state.resetPasswordSuccess = {};
			state.resetPasswordError = '';
		},
		[resetPassword.fulfilled]: (state, { payload }) => {
			state.loading = false;
			state.resetPasswordSuccess = payload.response;
			state.resetPasswordError = '';
		},
		[resetPassword.rejected]: (state, err) => {
			state.loading = false;
			state.resetPasswordSuccess = {};
			state.resetPasswordError = err.payload.message;
		},
		[verifyOtp.fulfilled]: (state, { payload }) => {
			state.isLoggedIn = true;
			state.setToken = payload.response?.data.access_token;
			state.userDetails = payload.response?.data.data;
			localStorage.setItem('authToken', payload.response?.data.data.access_token);
			localStorage.setItem('userId', payload.response && payload.response.data.data.id);
		},
		[verifyOtp.rejected]: (state, err) => {
			state.otpMessage = err?.payload.message;
		},
		[handleLogOut.fulfilled]: (state) => {
			state.isLoggedIn = false;
			state.isOTPSent = false;
			state.otpMessage = '';
		},
		[resendOtp.pending]: (state) => {
			state.isResendOTPSent = false;
			state.otpMessage = '';
		},
		[resendOtp.fulfilled]: (state, { payload }) => {
			state.otpMessage = payload.response?.data.message;
			state.isResendOTPSent = true;
		},
		[resendOtp.rejected]: (state, err) => {
			state.otpMessage = err?.payload.message;
		},
	},
});

export const { loggedUserInfo } = authSlice.actions;
export default authSlice.reducer;