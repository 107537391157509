import { createAsyncThunk } from '@reduxjs/toolkit';
import { callGetAPI, callPostAPI } from '../../services/axios';

export const getUsers = createAsyncThunk(
	'getUsers',
	async(params, { rejectWithValue }) => {
		const queryParams = {
			page: params.page,
			type: params.type,
			search: params.search,
		};
		try {
			const response = await callGetAPI({ route: 'getusers', params: queryParams });
			return { response };
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	},
);

export const userProfile = createAsyncThunk(
	'userProfile',
	async(params, { rejectWithValue }) => {
		const queryParams = {
			user_id: params.user_id,
		};
		try {
			const response = await callGetAPI({ route: 'getuser', params: queryParams });
			return { response };
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	},
);

export const updateUser = createAsyncThunk(
	'updateUser',
	async(params, { rejectWithValue }) => {
		try {
			const response = await callPostAPI({ route: 'updateuser', body: params.body, headersProps: { 'Content-Type': 'multipart/form-data' }, });
			return { response };
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	},
);

export const addUser = createAsyncThunk(
	'addUser',
	async(params, { rejectWithValue }) => {
		try {
			const response = await callPostAPI({ route: 'adduser', body: params.body, headersProps: { 'Content-Type': 'multipart/form-data' }, });
			return { response };
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	},
);

export const deleteUser = createAsyncThunk(
	'deleteUser',
	async(params, { rejectWithValue }) => {
		try {
			const response = await callPostAPI({ route: 'deleteuser', body: params.body });
			return { response };
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	},
);