import styled from '@emotion/styled';

export const Wrapper = styled.div`
    margin:200px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const Loading = styled.div`
   justify-content: "space-between"
   border: 16px solid ${(props) => props.theme.colors.light};
   border-radius: 50%;
   border-top: 16px solid ${(props) => props.theme.colors.primary};
   border-bottom: 16px solid ${(props) => props.theme.colors.primary};
   width: 120px;
   height: 120px;
   -webkit-animation: spin 2s linear infinite;
   animation: spin 2s linear infinite;
    @-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
  }
   @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;