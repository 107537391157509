import styled from '@emotion/styled';

export const Container = styled.div`
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    ${(props) => props.theme.breakPoint.mobile} {
        padding: 16px;
    }
`;

export const Wrapper = styled.div`
    padding: 40px 60px;
    border-radius: 10px;
    background: ${(props) => props.theme.colors.light};
    max-width: 570px;
    width: 100%;
    ${(props) => props.theme.breakPoint.mobile} {
        padding: 16px;
    }
`;

export const Forms = styled.div`
    margin-top: 30px;
`;