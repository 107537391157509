import React from 'react';
import { bool, string, any } from 'prop-types';
import { Label, CheckboxInput, Wrapper } from './styled';

const Checkbox = ({
	label,
	id,
	name,
	disabled,
	value
}) => {
	return (
		<Wrapper>
			<CheckboxInput type={'checkbox'} id={id} name={name} disabled={disabled} value={value} />
			<Label htmlFor={id}>{label}</Label>
		</Wrapper>
	);
};

Checkbox.prototype = {
	disabled: bool,
	id: string,
	label: string,
	name: string,
	value: any
};

export default Checkbox;