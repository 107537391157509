import React from 'react';
import { ImSpinner6 } from 'react-icons/im';
import { any, bool, func, oneOf, string } from 'prop-types';
import theme from '../../themes/base.json';
import { Wrapper } from './styled.js';

const { buttons } = theme;
const Button = ({
	children,
	disabled,
	type,
	onClick,
	loading,
	title,
	...rest
}) => {
	return (
		<Wrapper {...rest}>
			<button
				title={title}
				className={`button ${loading && 'loading'}`}
				disabled={disabled || loading}
				type={type}
				onClick={onClick}
			>
				{children}
				{loading && <ImSpinner6 />}
			</button>
		</Wrapper>
	);
};

Button.propTypes = {
	children: any,
	disabled: bool,
	variant: oneOf(Object.keys(buttons)),
	size: oneOf(['xl', 'sm', 'xs', 'default', 'block']),
	mobileSize: oneOf(['xl', 'sm', 'xs', 'default', 'block']),
	type: oneOf(['button', 'submit']),
	onClick: func,
	height: string,
	mobileHeight: string,
	color: string,
	loading: bool
};

Button.defaultProps = {
	type: 'button',
	variant: 'primary',
	block: false,
	loading: false
};

export default Button;