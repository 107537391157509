import styled from '@emotion/styled';

export const Wrapper = styled.div`
    overflow-y: auto;
`;

export const Container = styled.table`
    min-width: 100%;
    text-align: left;
    border-spacing: 0px;
`;

export const Head = styled.thead`
    tr {
        cursor: default;
        opacity: 1;
        &:hover {
            background: ${(props) => props.theme.colors.light};
        }
    }
`;

export const Body = styled.tbody``;

export const TR = styled.tr`
    border-bottom: 1px solid ${(props) => props.theme.colors.lightgrey};
    opacity: 0.8;
    transition: all 0.3s;
    &:hover {
        background: ${(props) => props.theme.colors.primaryLight};
        opacity: 1;
    }
`;

export const TD = styled.td`
    border-bottom: 1px solid ${(props) => props.theme.colors.lightgrey};
    font-size: 16px;
    padding: 0px 10px;
    color: ${(props) => props.theme.colors.text.black};
    ${(props) => props.theme.breakPoint.mobile} {
        font-size: 14px;
    }
    button {
        margin: 10px 16px 10px 0;
    }
    &.flex {
        display: flex;
    }
    &.message {
        padding: 15px;
        text-align: center;
    }
`;

export const TH = styled.th`
    color: ${(props) => props.theme.colors.text.black};
    font-weight: 500;
    border-bottom: 1px solid ${(props) => props.theme.colors.lightgrey};
    padding: 12px 5px;
    font-size: 16px;
    white-space: nowrap;
    ${(props) => props.theme.breakPoint.mobile} {
        font-size: 14px;
    }
`;
export const A = styled.a`
    cursor: pointer;
    color: ${(props) => props.theme.colors.text.primary};

`;
