import { createAsyncThunk } from '@reduxjs/toolkit';
import { callGetAPI, callPostAPI } from '../../services/axios';

export const getPlanList = createAsyncThunk(
	'getPlanList',
	async(params, { rejectWithValue }) => {
		try {
			const response = await callGetAPI({ route: 'superadmin/plans/list', params });
			return { response };
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	},
);

export const addPlan = createAsyncThunk(
	'addPlan',
	async(params, { rejectWithValue }) => {
		try {
			const response = await callPostAPI({ route: 'superadmin/add/plan', body: params.body });
			return { response };
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	},
);
export const deletePlan = createAsyncThunk(
	'deletePlan',
	async(params, { rejectWithValue }) => {
		try {
			const response = await callPostAPI({ route: 'superadmin/delete/plan', body: params.body });
			return { response };
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	},
);
export const getEditPlan = createAsyncThunk(
	'getEditPlan',
	async(params, { rejectWithValue }) => {
		const queryParams = {
			plan_id: params.plan_id,
		};
		try {
			const response = await callGetAPI({ route: 'superadmin/edit/plan', params: queryParams });
			return { response };
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	},
);
export const updatePlan = createAsyncThunk(
	'updatePlan',
	async(params, { rejectWithValue }) => {
		try {
			const response = await callPostAPI({ route: 'superadmin/update/plan', body: params.body });
			return { response };
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	},
);

export const getHospitalPlanList = createAsyncThunk(
	'getHospitalPlanList',
	async(params, { rejectWithValue }) => {
		try {
			const response = await callGetAPI({ route: 'superadmin/purchase/plans', params });
			return { response };
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	},
);
export const addHospitalPlan = createAsyncThunk(
	'addHospitalPlan',
	async(params, { rejectWithValue }) => {
		try {
			const response = await callPostAPI({ route: 'superadmin/purchase/plan', body: params.body });
			return { response };
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	},
);
export const addPayment = createAsyncThunk(
	'addPayment',
	async(params, { rejectWithValue }) => {
		try {
			const response = await callPostAPI({ route: 'superadmin/plan/billing/payment/status', body: params.body });
			return { response };
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	},
);
export const setDefaultPlan = createAsyncThunk(
	'setDefaultPlan',
	async(params, { rejectWithValue }) => {
		try {
			const response = await callPostAPI({ route: 'superadmin/default/plan', body: params.body });
			return { response };
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	},
);
export const getBilling = createAsyncThunk(
	'getBilling',
	async(params, { rejectWithValue }) => {
		try {
			const response = await callGetAPI({ route: 'superadmin/plan/billings', params });
			return { response };
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	},
);