import React from 'react';
import { Table, TableDiv, TCell, TRow } from './styled';
const SimpleTable = ({ tableData, tableFields }) => {
	return (
		<Table>
			<TableDiv>
				{tableData &&
					tableData.map((value, index) => {
						return (<TRow key={index}>
							{tableFields && tableFields.map((field, findex) => {
								return <TCell key={findex}>{value[field]}</TCell>;
							})}
						</TRow>);
					})
				}
			</TableDiv>
		</Table>
	);
};

export default SimpleTable;