import styled from '@emotion/styled';

export const Wrapper = styled.div`
    display: flex;
    align-items: center;
    margin: 5px 15px 0px 0px;
`;

export const CheckboxInput = styled.input`
    width: 16px;
    height: 16px;
    margin: 0;
    & + label {
        position: relative;
        cursor: pointer;
        padding: 0;
    }
    & + label:before {
        content: '';
        margin: 2px 5px 0 -1px;
        display: inline-block;
        vertical-align: text-top;
        width: 16px;
        height: 16px;
        background: ${(props) => props.theme.colors.light};
        border: 1px solid ${(props) => props.theme.colors.primary};
        border-radius: 4px;
    }
    &:checked + label:before {
        background: ${(props) => props.theme.colors.primary};
    }
    :checked+label:after {
        content: '';
        position: absolute;
        left: 3px;
        top: 10px;
        background: ${(props) => props.theme.colors.light};
        width: 2.3px;
        height: 2.3px;
        font-weight: 900;
        box-shadow: 2px 0 0 ${(props) => props.theme.colors.light},
                    4px 0 0 ${(props) => props.theme.colors.light},
                    4px -2px 0 ${(props) => props.theme.colors.light},
                    4px -4px 0 ${(props) => props.theme.colors.light},
                    4px -6px 0 ${(props) => props.theme.colors.light},
                    4px -8px 0 ${(props) => props.theme.colors.light};
        transform: rotate(45deg);
    }
`;

export const Label = styled.label`
    transition: all 0.3s;
    font-size: 16px;
    margin-left: -16px;
`;