/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import { t } from 'i18next';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { MdOutlineBed } from 'react-icons/md';
import PageTitle from '../../components/PageTitle/index.js';
import { StatsHorizontal } from '../../components/StatisticsCard/index.js';
import { detailHospital } from '../../redux/hospital/index.js';
import { UserPlaceholder } from '../../images';
import { Container, Wrapper, Card, CardBody, ProfileHeading, ProfileDetail, ProfileImgWrapper, ProfileImg, ProfileTitle, ProfileSubTitle, Stats, ProfileDescription, Detail, Value, Label } from './styled.js';

const ViewHospital = () => {
	const dispatch = useDispatch();
	const { hospitalDetail } = useSelector(state => state.hospital);
	const { id } = useParams();
	const [hospitalData, setHospitalData] = useState([]);

	useEffect(() => {
		if (hospitalDetail.length < 1) {
			dispatch(detailHospital({ hospital_id: id }));
		}
	}, []);

	useEffect(() => {
		if (hospitalDetail) {
			setHospitalData(hospitalDetail);
		}
	}, [hospitalDetail]);

	return (
		<>
			<PageTitle >{t('DETAILHOSPITAL.HEADING')}</PageTitle>
			<Container>
				<Wrapper>
					<Card>
						<CardBody>
							<ProfileHeading />
							<ProfileDetail>
								<ProfileImgWrapper>
									<ProfileImg src={hospitalData?.profile_picture || UserPlaceholder} />
								</ProfileImgWrapper>
								<ProfileTitle>
									{(hospitalData?.name || '')}
								</ProfileTitle>
								<ProfileSubTitle>

								</ProfileSubTitle>
								<ProfileDescription>
									<Detail>
										<Label>
											{t('GENERAL.ADDRESS') + ': '}
										</Label>
										<Value>
											{hospitalData?.address}
										</Value>
									</Detail>
									<Detail>
										<Label>
											{t('GENERAL.CITY') + ': '}
										</Label>
										<Value>
											{hospitalData?.city}
										</Value>
									</Detail>
									<Detail>
										<Label>
											{t('GENERAL.STATE') + ': '}
										</Label>
										<Value>
											{hospitalData?.state}
										</Value>
									</Detail>
									<Detail>
										<Label>
											{t('GENERAL.ZIP') + ': '}
										</Label>
										<Value>
											{hospitalData?.zip}
										</Value>
									</Detail>
									<Detail>
										<Label>
											{t('GENERAL.TELEPHONE') + ': '}
										</Label>
										<Value>
											{hospitalData?.telephone}
										</Value>
									</Detail>
									<Detail>
										<Label>
											{t('GENERAL.STATUS') + ': '}
										</Label>
										<Value>
											{hospitalData?.status}
										</Value>
									</Detail>
									<Detail>
										<Label>
											{t('GENERAL.POSTFIX') + ': '}
										</Label>
										<Value>
											{hospitalData?.postfix}
										</Value>
									</Detail>
								</ProfileDescription>
							</ProfileDetail>
						</CardBody>
					</Card>
					<Stats>
						<StatsHorizontal icon={<MdOutlineBed size={25} />} mobileSize='100%' size='50%' variant='primary' stats={hospitalData?.total_beds_allocations} statTitle={t('DETAILHOSPITAL.TOTALBEDS')} />
					</Stats>
				</Wrapper>
			</Container>
		</>
	);
};

export default ViewHospital;
