import React, { useEffect, useState } from 'react';
import { t } from 'i18next';
import { useNavigate } from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import { useSelector, useDispatch } from 'react-redux';
import { HiChevronDoubleLeft, HiChevronDoubleRight } from 'react-icons/hi';
import { AiOutlineEdit, AiOutlineDelete } from 'react-icons/ai';
import Table from '../../components/Table';
import Button from '../../components/Button';
import Typography from '../../components/Typography';
import Input from '../../components/Input';
import SelectDropdown from '../../components/SelectDropdown';
import Loader from '../../components/Loader';
import { deleteUser, getUsers } from '../../redux/users';
import useDebounce from '../../utils/useDebounceHook';
import {
	Buttons,
	Footer,
	TableContent,
	Header,
	Modal,
	Overlay,
	Container,
	Content,
	SearchSelect,
	SelectContainer,
	InputContainer,
} from './styled';

const options = [
	{ value: 'all', label: 'All' },
	{ value: 'superadmin', label: 'Super Admin' },
	{ value: 'user', label: 'User' }
];

const UserList = () => {
	const TableHeadings = ['ID', 'User Name', 'FName', 'LName', 'Email', 'Number', 'Role', ''];
	const TableFields = ['id', 'username', 'first_name', 'last_name', 'email', 'mobile_number', 'user_role'];
	const TableFormatFields = [
		{
			'name': 'id',
			'handleClick': (item) => handleView(item.id),
			'variant': 'primary',
			'text': (item) => item.id
		}
	];
	const TableActions = [
		{
			'name': 'edit',
			'handleClick': (item) => handleEdit(item.id),
			'size': 'xs',
			'variant': 'primary',
			'title': 'Edit',
			'text': <AiOutlineEdit />
		},
		{
			'name': 'delete',
			'handleClick': (item) => handleDelete(item.id),
			'size': 'xs',
			'variant': 'danger',
			'title': 'Delete',
			'text': <AiOutlineDelete />
		}
	];
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { userList, isUserDeletedSuccess, userData, Loading } = useSelector(state => state.users);
	const [currentItems, setCurrentItems] = useState([]);
	const [pageCount, setPageCount] = useState(0);
	const [userRole, setUserRole] = useState(options[0]);
	const [searchUserName, setSearchUserName] = useState('');
	const [deleteMsg, setDeleteMsg] = useState({ show: false, id: null });
	const debouncedSearchTerm = useDebounce(searchUserName, 500);
	const [page, setPage] = useState(1);

	useEffect(() => {
		setCurrentItems(userList);
		userList.length ? setPageCount(userData.last_page) : setPageCount(0);
	}, [userList]);

	useEffect(() => {
		if (userRole.value !== 'all' && debouncedSearchTerm == '') {
			dispatch(getUsers({ page: page, type: userRole.value }));
		}
		else if (userRole.value !== 'all' && debouncedSearchTerm !== '') {
			dispatch(getUsers({ page: page, type: userRole.value, search: debouncedSearchTerm }));
		}
		else if (userRole.value === 'all' && debouncedSearchTerm !== '') {
			dispatch(getUsers({ page: page, search: debouncedSearchTerm }));
		}
		else {
			dispatch(getUsers({ page: page }));
		}
	}, [page, userRole, debouncedSearchTerm]);

	useEffect(() => {
		if (isUserDeletedSuccess) {
			dispatch(getUsers({ page: page }));
		}
	}, [isUserDeletedSuccess]);

	const handleView = (id) => {
		navigate(`/user-management/viewuser/${id}/${page}`);
	};

	const handleEdit = (id) => {
		navigate(`/user-management/edituser/${id}/${page}`);
	};

	const handleDelete = (id) => {
		setDeleteMsg({
			show: true,
			id,
		});
	};

	const hendleDeleteYes = () => {
		if (deleteMsg.show && deleteMsg.id) {
			const body = { user_id: deleteMsg.id };
			dispatch(deleteUser({ body }));
			setDeleteMsg({
				show: false,
				id: null,
			});
		}
	};

	const handlePageClick = (event) => {
		const pageNum = event.selected + 1;
		setPage(pageNum);
	};

	const userListByRole = (event) => {
		setUserRole(event);
		setPage(1);
	};

	const handleSearchByName = (event) => {
		const inputVal = event.target.value;
		setSearchUserName(inputVal);
		setPage(1);
	};

	return (
		<Container>
			<Content>
				<Header>
					<Typography
						variant="h4"
						color="black"
						weight="500"
					>
						{t('USERLIST.HEADING')}
					</Typography>
				</Header>
				<SearchSelect>
					<SelectContainer>
						<SelectDropdown
							options={options}
							className={'select'}
							onChange={userListByRole}
							value={userRole}
						/>
					</SelectContainer>
					<InputContainer>
						<Input placeholder='Search here...' onChange={handleSearchByName} value={searchUserName} />
					</InputContainer>
				</SearchSelect>
				<TableContent>
					{Loading ? <Loader /> : <Table tableHeading={TableHeadings} tableFields={TableFields} tableData={currentItems} tableActions={TableActions} tableFormatFields={TableFormatFields} />}
					<Footer>
						<ReactPaginate
							breakLabel="..."
							nextLabel={<HiChevronDoubleRight />}
							onPageChange={handlePageClick}
							pageRangeDisplayed={5}
							pageCount={pageCount}
							previousLabel={<HiChevronDoubleLeft />}
							forcePage={pageCount === 0 ? -1 : page - 1}
						/>
					</Footer>
				</TableContent>
			</Content>
			{deleteMsg.show ?
				<Overlay>
					<Modal>
						<Typography
							variant="h4"
							color="black"
						>
							{t('USERLIST.DELETECONFIRM')}
						</Typography>
						<Buttons>
							<Button title={t('USERLIST.BTNNO')} onClick={() => setDeleteMsg(false)} size={'sm'}>{t('USERLIST.BTNNO')}</Button>
							<Button title={t('USERLIST.BTNYES')} onClick={hendleDeleteYes} size={'sm'} variant={'danger'}>{t('USERLIST.BTNYES')}</Button>
						</Buttons>
					</Modal>
				</Overlay>
				: null}
		</Container>
	);
};

export default UserList;