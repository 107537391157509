import React from 'react';
import { array } from 'prop-types';
import kebabCase from 'lodash/kebabCase';
import Typography from '../Typography/index.js';
import Input from '../Input/index.js';
import Radio from '../Radio/index.js';
import Checkbox from '../Checkbox/index.js';
import TextArea from '../TextArea/index.js';
import { Seperator, TCell, TRow, Table, TableDiv, Wrapper } from './styled.js';
const PreviewFormCompoent = ({
	fields
}) => {
	return (
		fields.map((field, index) => {
			let fieldname = kebabCase(field.title);
			return (
				<Wrapper key={index}>
					{field.type == 'label' &&
						<Typography
							variant='h3'
							align='left'
							display='block'
						>{field.title}</Typography>
					}
					{field.type == 'note' &&
						<Typography
							variant='h6'
							align='left'
							display='block'
						>{field.default_values}</Typography>
					}
					{field.type == 'separator' &&
						<Seperator />
					}
					{field.type != 'label' && field.type != 'note' && field.type != 'separator' &&
						<Typography
							variant='h5'
							align='left'
							display='block'
						>{field.title}{field.required ? '*' : null}</Typography>
					}
					{field.type == 'text' &&
						<Input
							onChange={() => { }}
							type={field.type}
							placeholder={field.placeholder}
							value={field.default_values}
							required={(field.required ? 'required' : null)} />
					}
					{field.type == 'lab-report-values' &&
						<Input
							onChange={() => { }}
							type={field.type}
							placeholder={field.placeholder}
							value={field.default_values}
							required={(field.required ? 'required' : null)} />
					}
					{field.type == 'regno' &&
						<Input
							onChange={() => { }}
							type={field.type}
							placeholder={field.placeholder}
							value={field.default_values}
							required={(field.required ? 'required' : null)} />
					}
					{field.type == 'number' &&
						<Input
							onChange={() => { }}
							type={field.type}
							placeholder={field.placeholder}
							value={field.default_values}
							required={(field.required ? 'required' : null)} />
					}
					{field.type == 'date' &&
						<Input
							onChange={() => { }}
							type={field.type}
							placeholder={field.placeholder}
							value={field.default_values}
							required={(field.required ? 'required' : null)} />
					}
					{field.type == 'datetime' &&
						<Input
							onChange={() => { }}
							type={'datetime-local'}
							placeholder={field.placeholder}
							value={field.default_values}
							required={(field.required ? 'required' : null)} />
					}
					{field.type == 'time' &&
						<Input
							onChange={() => { }}
							type={field.type}
							placeholder={field.placeholder}
							value={field.default_values}
							required={(field.required ? 'required' : null)} />
					}
					{field.type == 'file' &&
						<Input
							onChange={() => { }}
							type={field.type}
							placeholder={field.placeholder}
							required={(field.required ? 'required' : null)} />
					}
					{field.type == 'textarea' &&
						<TextArea
							onChange={() => { }}
							themeType={field.theme_type}
							placeholder={field.placeholder}
							value={field.default_values}
							required={(field.required ? 'required' : null)} />
					}
					{field.type == 'radio' &&
						<div style={{ display: 'flex' }}>
							{field.possible_values && field.possible_values.split(',').map((option, index) =>
								<Radio
									key={index}
									id={fieldname + '_' + kebabCase(option)}
									name={fieldname}
									value={option}
									label={option} />
							)}
						</div>
					}
					{field.type == 'checkbox' &&
						<div style={{ display: 'flex' }}>
							{field.possible_values && field.possible_values.split(',').map((option, index) =>
								<Checkbox
									key={index}
									id={fieldname + '_' + kebabCase(option)}
									name={fieldname}
									value={option}
									label={option} />
							)}
						</div>
					}
					{field.type == 'subform' && field.subform &&
						<Table>
							<TableDiv>
								<TRow>
									{field.subform.fields && field.subform.fields.map((value, index) =>
										<TCell key={index}>{value.title}</TCell>
									)}
								</TRow>
								<TRow>{field.subform.fields && field.subform.fields.map((value, index) =>
									<TCell key={index}>&nbsp;</TCell>
								)}</TRow>
							</TableDiv>
						</Table>
					}
				</Wrapper>
			);
		})
	);
};

PreviewFormCompoent.propTypes = {
	fields: array,
};

export default PreviewFormCompoent;