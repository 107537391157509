import React from 'react';
import FileSaver from 'file-saver';

export const AuthContext = React.createContext(null);

export const useAuth = () => React.useContext(AuthContext);

export const slugify = (str) => {
	return str
		.toLowerCase()
		.trim()
		.replace(/[^a-z0-9]/gi, '');
};

export const downloadFile = (fileData) => {
	const bufferArray = new Uint8Array(fileData.file).buffer;
	const blob = new Blob([bufferArray], {
		type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
	});
	let fileName = fileData.file_name;

	// Check if the file name already has an extension
	if (!fileName.includes('.')) {
		fileName += '.xlsx';
	}

	return FileSaver.saveAs(blob, fileName);
	// return FileSaver.saveAs(blob, `${fileData.file_name}.xlsx`);
};
