import React from 'react';
import Select from 'react-select';
import { func } from 'prop-types';
import theme from '../../themes/base.json';
import { Wrapper, Alert } from './styled';

const customStyles = {
	control: (base) => ({
		...base,
		border: `1px solid ${theme.colors.gray}`,
		borderRadius: '10px',
		width: '100%',
		padding: '3px'
	}),
	option: (styles, { isFocused, isSelected }) => ({
		...styles,
		background: isFocused
			? `${theme.colors.primary}20`
			: isSelected
				? `${theme.colors.primary}20`
				: undefined,
		zIndex: 1,
		color: `${theme.colors.gray}`,
		border: `1px solid ${theme.colors.gray}`,
		margin: 0,
		cursor: 'pointer',
		'&:active': {
			background: `${theme.colors.primary}20`
		}
	}),
	dropdownIndicator: (base) => ({
		...base,
		color: theme.colors.primary
	}),
	indicatorSeparator: (base) => ({
		...base,
		border: `1px solid ${theme.colors.gray}`,
	}),
	singleValue: (base) => ({
		...base,
		color: theme.colors.primary
	})
};
const SelectDropdown = ({
	options,
	onChange,
	value,
	errormessage,
	error,
	placeholder,
	isMulti,
	defaultValue,
	setValue,
}) => {
	const attributes = {
		options,
		onChange,
		value,
		errormessage,
		error, defaultValue, setValue
	};
	return (
		<Wrapper>
			<Select styles={customStyles} placeholder={placeholder} isMulti={isMulti} theme={(theme) => ({
				...theme,
				colors: {
					...theme.colors,
					primary: '#099FAF'
				},
			})} {...attributes} className={'select'} />
			{error && <Alert>{errormessage}</Alert>}
		</Wrapper>
	);
};

SelectDropdown.propTypes = {
	onChange: func,
};

SelectDropdown.defaultProps = {
	error: false
};

export default SelectDropdown;