import styled from '@emotion/styled';

export const Wrapper = styled.div`
    width: 100%;
    background-color: ${(props) => props.theme.colors.light};
    border-radius: 25px;
    box-shadow: 0px 4px 5px 1px ${(props) => props.theme.colors.shadow}10;
    ${(props) => props.theme.breakPoint.mobile} {
        border-radius: 15px;
    }
`;

export const Forms = styled.div`
    padding: 16px;
    ${(props) => props.theme.breakPoint.mobile} {
        padding: 10px;
    }
`;

export const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding-bottom: 16px;
    border-bottom: 1px solid ${(props) => props.theme.colors.lightgrey};
    ${(props) => props.theme.breakPoint.mobile} {
        padding-bottom: 10px;
    }
`;

export const Container = styled.div`
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    padding: 16px 0;
    color: ${(props) => props.theme.colors.primary}
`;

export const Row = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    max-width: 40%;
    padding: 10px 15px;
    border: 1px solid #d3e0e1;
    margin: 10px;
    border-radius: 6px;  
    cursor: pointer;
    background-color: ${(props) => props.theme.colors.primaryLight};
    box-shadow: 0px 4px 5px 1px ${(props) => props.theme.colors.shadow}10;

`;
export const DraggableItem = styled.div`
    flex-grow: 1;
    text-align: left;
    margin: 2px 5px;
`;
/*
width: 100%;
    text-align: center;


    .css-8zxawk {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-align-items: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: justify;
        -webkit-justify-content: space-between;
        justify-content: space-between;
        width: 100%;
        padding: 10px 15px;
        border: 2px solid #d7dbda;
        margin: 10px 0px 10px 0px;
        border-radius: 6px;
        cursor: pointer;
        justify-content: flex-start;
        max-width: 40%;
        margin: 10px auto;
    } */