import styled from '@emotion/styled';

export const Container = styled.section`
    width: 100%;
    height: 100%;
    position: relative;
`;

export const Content = styled.div`
    width: 100%;
    background-color: ${(props) => props.theme.colors.light};
    box-shadow: 0px 4px 5px 1px ${(props) =>  props .theme.colors.shadow}10;
    border-radius: 25px;
    padding: 16px;
    ${(props) =>  props .theme.breakPoint.mobile} {
        border-radius: 15px;
    }
`;

export const Header = styled.div`
    width: 100%;
    padding-bottom: 16px;
    border-bottom: 1px solid ${(props) =>  props .theme.colors.lightgrey};
    ${( props ) => props.theme.breakPoint.mobile} {
        padding-bottom: 10px;
    }
`;

export const TableContent = styled.div``;

export const SearchInput = styled.div`
    width: 60%;
    margin-left: auto;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 16px;
    ${(props) => props.theme.breakPoint.mobileAndTablet} {
        width: 100%;
    }
    ${(props) => props.theme.breakPoint.mobile} {
        flex-wrap: wrap;
    }
`;

export const SelectContainer = styled.div`
    width: 50%;
    margin-right: 10px;
    ${(props) => props.theme.breakPoint.mobile} {
        width: 100%;
        margin: 0;
    }
`;

export const InputContainer = styled.div`
    width: 50%;
    ${(props) => props.theme.breakPoint.mobile} {
        width: 100%;
    }
`;

export const Footer = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 12px 0px 7px 0px;
    font-family: ${(props) => props.theme.font};
    ${(props) => props.theme.breakPoint.mobile} {
        justify-content: center;
    }
    ul {
        display: flex;
        align-items: center;
        max-width: 100%;
        justify-content: space-between;
        padding-left: 0;
        li {
            list-style-type: none;
            overflow: hidden;
            cursor: pointer;
            width: 32px;
            height: 32px;
            margin: 0px 6px;
            a {
                width: 100%;
                height: 100%;
                font-family: ${(props) => props.theme.font};
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 18px;
                line-height: 32px;
                color: ${(props) => props.theme.colors.primary};
                font-weight: 700;
                &:hover {
                    box-shadow: 0px 4px 5px 1px ${(props) =>  props .theme.colors.shadow}10;
                    border-radius: 4px;
                }
                ${(props) => props.theme.breakPoint.mobile} {
                    font-size: 16px;
                }
            }
            &:first-of-type {
                border-radius: 4px;
                background-color: ${(props) => props.theme.colors.primary};
                a {
                    color: ${(props) => props.theme.colors.primaryLight};
                    font-size: 22px;
                    line-height: 32px;
                    transition: all 0.4s;
                    &:hover {
                        color: ${(props) => props.theme.colors.primaryLight};
                        background-color: ${(props) => props.theme.colors.primary};
                    }
                    ${(props) => props.theme.breakPoint.mobile} {
                        font-size: 20px;
                    }
                }
            }
            &:last-of-type {
                border-radius: 4px;
                background-color: ${(props) => props.theme.colors.primary};
                a {
                    color: ${(props) => props.theme.colors.primaryLight};
                    font-size: 22px;
                    line-height: 1.5px;
                    transition: all 0.4s;
                    &:hover {
                        color: ${(props) => props.theme.colors.primaryLight};
                        background-color: ${(props) => props.theme.colors.primary};
                    }
                    ${(props) => props.theme.breakPoint.mobile} {
                        font-size: 20px;
                    }
                }
            }
            &.selected {
                background-color: ${(props) => props.theme.colors.primary};
                box-shadow: 0px 4px 5px 1px ${(props) =>  props .theme.colors.shadow}10;
                width: 32px;
                height: 32px;
                border-radius: 4px;
                color: ${(props) => props.theme.colors.primaryLight};
                transition: all 0.4s;
                a {
                    color: ${(props) => props.theme.colors.primaryLight};
                }
            }
        }
    }
`;

export const Overlay = styled.div`
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${(props) => props.theme.colors.black}60;
    z-index: 10;
`;

export const Modal = styled.div`
    max-width: 650px;
    background-color: ${(props) => props.theme.colors.light};
    height: 150px;
    width: 100%;
    border-radius: 25px;
    padding: 15px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    box-shadow: 0px 4px 5px 1px ${(props) =>  props .theme.colors.shadow}10;
    ${(props) => props.theme.breakPoint.mobile} {
        margin: 15px;
    }
`;

export const Buttons = styled.div`
    display: flex;
    width: 60%;
    align-items: center;
    justify-content: space-around;
`;

export const NoRecords =  styled.div`
display: flex;
align-items: center;
justify-content: center;
`;