/* eslint-disable camelcase */
import React from 'react';
import { t } from 'i18next';
import { useSelector } from 'react-redux';
import PageTitle from '../../components/PageTitle/index.js';
import { Container, Wrapper, Card, CardBody, ProfileHeading, ProfileDetail, ProfileImgWrapper, ProfileImg, ProfileTitle, ProfileRole, ProfileDescription, Detail } from './styled.js';
import { UserPlaceholder } from '../../images';

const Profile = () => {
	const { userDetails } = useSelector(state => state.auth);

	return (
		<>
			<PageTitle>{t('GENERAL.PROFILE')}</PageTitle>
			<Container>
				<Wrapper>
					<Card>
						<CardBody>
							<ProfileHeading />
							<ProfileDetail>
								<ProfileImgWrapper>
									<ProfileImg src={userDetails?.profile_picture || UserPlaceholder} />
								</ProfileImgWrapper>
								<ProfileTitle>
									{userDetails?.first_name + ' ' + userDetails?.last_name}
								</ProfileTitle>
								<ProfileRole>
									{userDetails?.user_role}
								</ProfileRole>
								<ProfileDescription>
									<Detail>
										{t('GENERAL.EMAIL') + ': ' + userDetails?.email}
									</Detail>
									<Detail>
										{t('GENERAL.PHONE') + ': ' + userDetails?.mobile_number}
									</Detail>
								</ProfileDescription>
							</ProfileDetail>
						</CardBody>
					</Card>
				</Wrapper>
			</Container>
		</>

	);
};

export default Profile;
