import styled from '@emotion/styled';

export const Wrapper = styled.div`
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    padding: 16px;
    background-color:${(props) => props.theme.colors.primaryLight};
    margin-bottom:10px;
    border:1px solid ${(props) => props.theme.colors.lightgrey};
    border-radius:10px;
`;
export const Container = styled.div`
    position: relative;
	margin-bottom:20px;
`;
export const Label = styled.label`
	margin-bottom: 0.5rem;
	display: inline-block;
`;

export const Dragable = styled.div`
	position: absolute;
	right: -15px;
	top: -15px;
	background: #099FAF;
	padding: 5px;
	border-radius: 100px;
	width: 40px;
	height: 40px;
	display: flex;
	align-item: center;
	justify-content: center;
	svg {
        width: 32px;
        height: 32px;
        fill: ${(props) => props.theme.colors.light};
    }
`;