import React, { useEffect, useState } from 'react';
import { t } from 'i18next';
import { isEmpty } from 'lodash';
import Typography from '../../components/Typography';
import Button from '../../components/Button';
import Input from '../../components/Input';
import { Formik, Form } from 'formik';
import { hospitalValidationSchema } from '../../utils/validation';
import {
	Wrapper,
	Header,
	Container,
	InputField,
	Forms
} from './styled';
import { addHospital } from '../../redux/hospital';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Loader from '../../components/Loader';
import { slugify } from '../../utils/helper';
import SelectDropdown from '../../components/SelectDropdown';
import { getPlanList } from '../../redux/plan';

const AddHospital = () => {
	const { isHospitalAddedSuccess, addHospitalErrorMessage, loading } = useSelector(state => state.hospital);
	const { planList } = useSelector(state => state.plan);
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [postFixError, setPostFixError] = useState('');
	const [resErrors, setResErrors] = useState({});
	const [options, setOptions] = useState([]);
	const [plan, setPlan] = useState([]);
	const intiVal ={
		name: '',
		address: '',
		zip: '',
		city: '',
		state: '',
		mobile: '',
		icuBeds: '',
		generalWardBeds: '',
		postfix: '',
		selectplan:''
	};
	useEffect(() => {
		dispatch(getPlanList());
	}, []);


	useEffect(() => {
		if (resErrors) {
			const message = resErrors.payload && resErrors.payload.message;
			setPostFixError(message);
		}
	}, [resErrors]);
	useEffect(() => {
		if (isHospitalAddedSuccess) {
			navigate('/hospital-management/list');
		}
		else if (addHospitalErrorMessage) {
			setResErrors(addHospitalErrorMessage);
		}
	}, [isHospitalAddedSuccess, addHospitalErrorMessage]);
	useEffect(() => {
		if (!isEmpty(planList) && planList.length > 0) {
			const getOption = planList.map((item) => {
				return { value:item.id, label: item.plan_name };
			});
			setOptions(getOption);
		}
		else {
			setOptions([]);
		}
	}, [planList]);

	function newStatusValue(event, setFieldValue) {
		setFieldValue('selectplan', event.value);
		setPlan(event.value);
	}
	const handleAddUser = ({ name, address, zip, city, state, mobile, icuBeds, generalWardBeds, postfix }) => {
		const body = { name: name, address: address, zip: zip, city: city, state: state, telephone: mobile, icu_beds: icuBeds, ward_beds: generalWardBeds, postfix : postfix, plan_id : plan };
		dispatch(addHospital({ body }));
	};
	return (
		<Wrapper>
			<Forms>
				<Header>
					<Typography
						variant="h4"
						color="black"
						weight="500"
					>
						{t('ADDHOSPITAL.HEADING')}
					</Typography>
				</Header>
				{loading && <Loader />}
				<Formik
					initialValues={intiVal}
					validationSchema={hospitalValidationSchema(t)}
					onSubmit={handleAddUser}
				>
					{({ errors, touched, values, setFieldValue, handleChange, handleBlur }) => (
						<Form>
							<Container>
								<InputField>
									<Input
										placeholder={t('ADDHOSPITAL.PLACEHOLDER.HOSPITAL_NAME')}
										name='name'
										type='text'
										onChange={handleChange}
										onBlur={handleBlur}
										error={touched.name && 1}
										errormessage={errors.name}
									/>
								</InputField>
								<InputField>
									<Input
										placeholder={t('ADDHOSPITAL.PLACEHOLDER.ADDRESS')}
										name='address'
										type='text'
										onChange={handleChange}
										onBlur={handleBlur}
										error={touched.address && 1}
										errormessage={errors.address}
									/>
								</InputField>
								<InputField>
									<Input
										placeholder={t('ADDHOSPITAL.PLACEHOLDER.ZIP')}
										type='text'
										pattern="[0-9]*"
										name='zip'
										onChange={handleChange}
										onBlur={handleBlur}
										error={touched.zip && 1}
										errormessage={errors.zip}
									/>
								</InputField>
								<InputField>
									<Input
										placeholder={t('ADDHOSPITAL.PLACEHOLDER.CITY')}
										type='text'
										name='city'
										onChange={handleChange}
										onBlur={handleBlur}
										error={touched.city && 1}
										errormessage={errors.city}
									/>
								</InputField>
								<InputField>
									<Input
										placeholder={t('ADDHOSPITAL.PLACEHOLDER.STATE')}
										type='text'
										name='state'
										onChange={handleChange}
										onBlur={handleBlur}
										error={touched.state && 1}
										errormessage={errors.state}
									/>
								</InputField>
								<InputField>
									<Input
										placeholder={t('ADDHOSPITAL.PLACEHOLDER.TELEPHONE')}
										type='tel'
										name='mobile'
										onChange={handleChange}
										onBlur={handleBlur}
										error={touched.mobile && 1}
										errormessage={errors.mobile}
									/>
								</InputField>
								<InputField>
									<Input
										placeholder={t('ADDHOSPITAL.PLACEHOLDER.ICU_BEDS')}
										type='number'
										name='icuBeds'
										onChange={handleChange}
										onBlur={handleBlur}
										error={touched.icuBeds && 1}
										errormessage={errors.icuBeds}
									/>
								</InputField>
								<InputField>
									<Input
										placeholder={t('ADDHOSPITAL.PLACEHOLDER.WARD_BEDS')}
										type='number'
										name='generalWardBeds'
										onChange={handleChange}
										onBlur={handleBlur}
										error={touched.generalWardBeds && 1}
										errormessage={errors.generalWardBeds}
									/>
								</InputField>
								<InputField>
									<Input
										placeholder={t('ADDHOSPITAL.PLACEHOLDER.USERNAME')}
										type='text'
										name='postfix'
										value={values.postfix}
										onChange={(e) => {
											setFieldValue('postfix', slugify(e.target.value));
										}}
										onBlur={handleBlur}
										error={touched.postfix && 1}
										errormessage={postFixError && postFixError.length ? postFixError : errors.postfix}
									/>
								</InputField>
								<InputField>
									<SelectDropdown
										name="selectplan"
										options={options}
										placeholder={t('ADDHOSPITAL.PLACEHOLDER.SELECTPLAN')}
										onBlur={handleBlur}
										onChange={(e) => newStatusValue(e, setFieldValue)}
										error={touched.selectplan && 1}
										errormessage={errors.selectplan}
									/>
								</InputField>
							</Container>
							<Button title={t('ADDHOSPITAL.BTNTEXT')} type='submit' size={'sm'}>{t('ADDHOSPITAL.BTNTEXT')}</Button>
						</Form>
					)}
				</Formik>
			</Forms>
		</Wrapper>
	);
};

export default AddHospital;