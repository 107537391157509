import React, { useEffect, useState } from 'react';
import { t } from 'i18next';
import Typography from '../../components/Typography';
import Button from '../../components/Button';
import Input from '../../components/Input';
import { useParams, useNavigate } from 'react-router-dom';
import { Formik, Form } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { editHospitalValidationSchema } from '../../utils/validation';
import { updateHospital, getHospital } from '../../redux/hospital';
import {
	Wrapper,
	Header,
	Container,
	InputField,
	Forms,
} from './styled';

const EditHospital = () => {
	const { hospitalList, isHospitalUpdateSuccess } = useSelector(state => state.hospital);
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const params = useParams();
	const [name, setName] = useState('');
	const [address, setAddress] = useState('');
	const [mobile, setMobile] = useState('');
	const [zip, setZip] = useState('');
	const [city, setCity] = useState('');
	const [state, setState] = useState('');
	const [icuBeds, setIcuBeds] = useState('');
	const [generalWardBeds, setGeneralWardBeds] = useState('');
	const [postfix, setPostfix] = useState('');
	const [list, setList] = useState(hospitalList);
	const { id, page } = params;
	const filteredData = list?.find((todo) => todo.id == id);

	useEffect(() => {
		if (hospitalList.length < 1) {
			dispatch(getHospital({ page: page }));
		}
		if (hospitalList.length > 0) {
			setList(hospitalList);
		}
	}, [hospitalList]);

	useEffect(() => {
		if (filteredData) {
			setName(filteredData.name);
			setAddress(filteredData.address);
			setMobile(filteredData.telephone);
			setZip(filteredData.zip);
			setCity(filteredData.city);
			setState(filteredData.state);
			setIcuBeds(filteredData.icu_beds);
			setGeneralWardBeds(filteredData.ward_beds);
			setPostfix(filteredData.postfix);
		}
	}, [list]);

	useEffect(() => {
		if (isHospitalUpdateSuccess) {
			navigate('/hospital-management/list');
		}
	}, [isHospitalUpdateSuccess]);

	const handleUserEdit = ({ name, address, zip, city, state, mobile,  icuBeds, generalWardBeds, postfix }) => {
		const body = {
			hospital_id: filteredData.id, name: name, address: address, zip: zip, city: city, state: state,
			telephone: mobile,  icu_beds: icuBeds, ward_beds: generalWardBeds, postfix
		};
		dispatch(updateHospital({ body }));
	};
	return (
		<Wrapper>
			<Forms>
				<Header>
					<Typography
						variant="h4"
						color="black"
						weight="500"
					>
						{t('EDITHOSPITAL.HEADING')}
					</Typography>
					<Button
						title={t('EDITHOSPITAL.BACKBUTTON')}
						onClick={() => navigate('/hospital-management/list')}
						size={'xs'}
					>
						{t('EDITHOSPITAL.BACKBUTTON')}
					</Button>
				</Header>
				<Formik
					initialValues={{
						name,
						address,
						zip,
						city,
						state,
						mobile,
						icuBeds,
						generalWardBeds,
						postfix
					}}
					validationSchema={editHospitalValidationSchema(t)}
					onSubmit={handleUserEdit}
					enableReinitialize={true}
				>
					{({ errors, values, touched, handleChange, handleBlur }) => (
						<Form>
							<Container>
								<InputField>
									<Input
										placeholder={t('EDITHOSPITAL.PLACEHOLDER.HOSPITAL_NAME')}
										name='name'
										type='text'
										onChange={handleChange}
										onBlur={handleBlur}
										error={touched.name && 1}
										errormessage={errors.name}
										value={values.name}
									/>
								</InputField>
								<InputField>
									<Input
										placeholder={t('EDITHOSPITAL.PLACEHOLDER.ADDRESS')}
										name='address'
										type='text'
										onChange={handleChange}
										onBlur={handleBlur}
										error={touched.address && 1}
										errormessage={errors.address}
										value={values.address}

									/>
								</InputField>
								<InputField>
									<Input
										placeholder={t('EDITHOSPITAL.PLACEHOLDER.ZIP')}
										type='tel'
										name='zip'
										onChange={handleChange}
										onBlur={handleBlur}
										error={touched.zip && 1}
										errormessage={errors.zip}
										value={values.zip}

									/>
								</InputField>
								<InputField>
									<Input
										placeholder={t('EDITHOSPITAL.PLACEHOLDER.CITY')}
										type='text'
										name='city'
										onChange={handleChange}
										onBlur={handleBlur}
										error={touched.city && 1}
										errormessage={errors.city}
										value={values.city}

									/>
								</InputField>
								<InputField>
									<Input
										placeholder={t('EDITHOSPITAL.PLACEHOLDER.STATE')}
										type='text'
										name='state'
										onChange={handleChange}
										onBlur={handleBlur}
										error={touched.state && 1}
										errormessage={errors.state}
										value={values.state}

									/>
								</InputField>
								<InputField>
									<Input
										placeholder={t('EDITHOSPITAL.PLACEHOLDER.TELEPHONE')}
										type='tel'
										name='mobile'
										onChange={handleChange}
										onBlur={handleBlur}
										error={touched.mobile && 1}
										errormessage={errors.mobile}
										value={values.mobile}

									/>
								</InputField>
								<InputField>
									<Input
										disabled={values.icuBeds > 0 ? true : false}
										placeholder={t('EDITHOSPITAL.PLACEHOLDER.ICU_BEDS')}
										type='number'
										name='icuBeds'
										onChange={handleChange}
										onBlur={handleBlur}
										error={touched.icuBeds && 1}
										errormessage={errors.icuBeds}
										value={values.icuBeds}
									/>
								</InputField>
								<InputField>
									<Input
										disabled={values.generalWardBeds > 0 ? true : false}
										placeholder={t('EDITHOSPITAL.PLACEHOLDER.WARD_BEDS')}
										type='number'
										name='generalWardBeds'
										onChange={handleChange}
										onBlur={handleBlur}
										error={touched.generalWardBeds && 1}
										errormessage={errors.generalWardBeds}
										value={values.generalWardBeds}
									/>
								</InputField>
								<InputField>
									<Input
										disabled
										placeholder={t('EDITHOSPITAL.PLACEHOLDER.USERNAME')}
										type='text'
										name='postfix'
										onChange={handleChange}
										onBlur={handleBlur}
										error={touched.postfix && 1}
										errormessage={errors.postfix}
										value={values.postfix}
									/>
								</InputField>
							</Container>
							<Button title={t('EDITHOSPITAL.BTNTEXT')} type='submit' size={'sm'}>{t('EDITHOSPITAL.BTNTEXT')}</Button>
						</Form>
					)}
				</Formik>
			</Forms>
		</Wrapper>
	);
};

export default EditHospital;