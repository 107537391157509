import { createAsyncThunk } from '@reduxjs/toolkit';
import { callPostAPI } from '../../services/axios';

export const handleLogin = createAsyncThunk(
	'handleLogin',
	async(params, { rejectWithValue }) => {
		try {
			const response = await callPostAPI({ route: 'login', body: params.body, isAuthenticated: false });
			return { response };
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	}
);

export const forgotPassword = createAsyncThunk(
	'forgotPassword',
	async(params, { rejectWithValue }) => {
		try {
			const response = await callPostAPI({ route: 'forgetpassword', body: params.body, isAuthenticated: false });
			return { response };
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	},
);

export const resetPassword = createAsyncThunk(
	'resetPassword',
	async(params, { rejectWithValue }) => {
		try {
			const response = await callPostAPI({ route: 'password/reset', body: params.body, isAuthenticated: false });
			return { response };
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	},
);

export const verifyOtp = createAsyncThunk(
	'verifyOtp',
	async(params, { rejectWithValue }) => {
		try {
			const response = await callPostAPI({ route: 'verifyotp', body: params.body, isAuthenticated: false });
			if (response?.data && !response.data.error) {
				params.useAuthContext.onLogin();
			}
			return { response };
		}
		catch (err) {
			return rejectWithValue(err.response.data);
		}
	},
);

export const handleLogOut = createAsyncThunk(
	'handleLogOut',
	async(params, { rejectWithValue }) => {
		try {
			const response = await callPostAPI({ route: 'logout', body: params });
			if (response?.data && !response.data.error) {
				params.useAuthContext.onLogout();
			}
			return { response };
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	},
);

export const resendOtp = createAsyncThunk(
	'resendOtp',
	async(params, { rejectWithValue }) => {
		try {
			const response = await callPostAPI({ route: 'resendotp', body: params.body, isAuthenticated: false });
			return { response };
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	},
);

export const refreshToken = async() => {
	const authToken = localStorage.getItem('authToken');
	if (!authToken) return false;
	const response = await callPostAPI({ route: 'refresh/accesstoken' });
	return response;
};
