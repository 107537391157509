import styled from '@emotion/styled';

export const Container = styled.div`
    width: 100%;
    background-color: ${(props) => props.theme.colors.light};
    box-shadow: 0px 4px 5px 1px ${(props) =>  props .theme.colors.shadow}10;
    border-radius: 15px;
    padding: 16px;
    ${(props) =>  props .theme.breakPoint.mobile} {
        border-radius: 15px;
    }
`;

export const Content = styled.div``;
