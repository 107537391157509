import { createAsyncThunk } from '@reduxjs/toolkit';
import { callGetAPI, callPostAPI } from '../../services/axios';

export const getHospital = createAsyncThunk(
	'getHospital',
	async(params, { rejectWithValue }) => {
		try {
			const response = await callGetAPI({ route: 'superadmin/hospital/list', params });
			return { response };
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	},
);

export const updateStatusHospital = createAsyncThunk(
	'updateStatusHospital',
	async(params, { rejectWithValue }) => {
		try {
			const response = await callPostAPI({ route: 'superadmin/update/hospital/status', body: params.body, });
			return { response };
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	},
);

export const updateHospital = createAsyncThunk(
	'updateHospital',
	async(params, { rejectWithValue }) => {
		try {
			const response = await callPostAPI({ route: 'superadmin/update/hospital', body: params.body, });
			return { response };
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	},
);

export const detailHospital = createAsyncThunk(
	'detailHospital',
	async(params, { rejectWithValue }) => {
		try {
			const response = await callGetAPI({ route: 'superadmin/get/hospital', params });
			return { response };
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	},
);

export const addHospital = createAsyncThunk(
	'addHospital',
	async(params, { rejectWithValue }) => {
		try {
			const response = await callPostAPI({ route: 'superadmin/add/hospital', body: params.body });
			return { response };
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	},
);

export const deleteHospital = createAsyncThunk(
	'deleteHospital',
	async(params, { rejectWithValue }) => {
		try {
			const response = await callPostAPI({ route: 'superadmin/delete/hospital', body: params.body });
			return { response };
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	},
);

export const getPatientDetails = createAsyncThunk(
	'getPatientDetails',
	async(params, { rejectWithValue }) => {
		try {
			const response = await callGetAPI({ route: 'superadmin/hospital/admitted/patients/list', params });
			return { response };
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	},
);