import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Form } from 'formik';
import { HiOutlineEye, HiOutlineEyeOff } from 'react-icons/hi';
import { useLocation, useNavigate } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import Input from '../../../components/Input';
import Button from '../../../components/Button';
import Typography from '../../../components/Typography';
import { resetPasswordValidationSchema } from '../../../utils/validation';
import { resetPassword } from '../../../redux/auth';
import {
	Container,
	Wrapper,
	Forms,
	PasswordWrapper
} from './styled.js';

const ResetPassword = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const location = useLocation();
	const searchParams = new URLSearchParams(location.search);
	const [show1, setShow1] = useState(false);
	const [show2, setShow2] = useState(false);
	const { resetPasswordSuccess, resetPasswordError } = useSelector(state => state.auth);
	const handleResetPassword = ({ password, confirmPassword }) => {
		const body = { password, password_confirmation: confirmPassword, token: searchParams.get('token'), email: searchParams.get('email') };
		dispatch(resetPassword({ body }));
	};
	useEffect(() => {
		if (!isEmpty(resetPasswordSuccess)) {
			navigate('/login');
		}
	}, [resetPasswordSuccess]);

	return (
		<Container>
			<Wrapper>
				<Typography
					variant="h2"
					color="black"
					align="center"
					display="block"
				>
					{t('RESETPASSWORD.TITLE')}
				</Typography>
				<Typography
					variant="caption"
					color="gray"
					align="center"
					display="block"
				>
					{t('RESETPASSWORD.SUB_TITLE')}
				</Typography>
				<Formik
					initialValues={{
						password: '',
						confirmPassword: '',
					}}
					validationSchema={resetPasswordValidationSchema(t)}
					onSubmit={handleResetPassword}
				>
					{({ errors, touched, values, handleChange, handleBlur }) => (
						<Forms>
							<Form>
								<PasswordWrapper>
									<Input
										type={show1 ? 'text' : 'password'}
										name="password"
										placeholder={t('RESETPASSWORD.PLACEHOLDER.NEW_PASSWORD')}
										onBlur={handleBlur}
										error={touched.password && 1}
										onChange={handleChange}
										value={values.password}
										errormessage={errors.password}
									/>
									<Button title={t('Show / Hide')} variant="secondary" onClick={() => setShow1(!show1)} >
										{show1 ? <HiOutlineEye /> : <HiOutlineEyeOff />}
									</Button>
								</PasswordWrapper>
								<PasswordWrapper>
									<Input
										type={show2 ? 'text' : 'password'}
										name="confirmPassword"
										placeholder={t('RESETPASSWORD.PLACEHOLDER.CONFIRM_NEW_PASSWORD')}
										onBlur={handleBlur}
										error={touched.confirmPassword && 1}
										onChange={handleChange}
										value={values.confirmPassword}
										errormessage={errors.confirmPassword}
									/>
									<Button title={t('Show / Hide')} variant="secondary" onClick={() => setShow2(!show2)} >
										{show2 ? <HiOutlineEye /> : <HiOutlineEyeOff />}
									</Button>
								</PasswordWrapper>
								{resetPasswordError && resetPasswordError.split('|').map((error, index) => (
									<Typography color="red" key={index}>
										{error}
									</Typography>
								))}
								<Button title={t('RESETPASSWORD.RESETPASSWORD_BUTTON')}
									variant="primary"
									size="block"
									type="submit"
								>
									{t('RESETPASSWORD.RESETPASSWORD_BUTTON')}
								</Button>
							</Form>
						</Forms>
					)}
				</Formik>
			</Wrapper>
		</Container>
	);
};

export default ResetPassword;
