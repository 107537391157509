import styled from '@emotion/styled';
import { errorColor } from '../../style/variables';

export const Wrapper = styled.div`
    height: auto;
    margin-bottom: 15px;
`;

export const Alert = styled.p`
    color: ${errorColor};
    margin: 5px 0 0 0;
`;
