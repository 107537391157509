import React, { useEffect, useState } from 'react';
import { t } from 'i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Formik, Form } from 'formik';
import { addPlan } from '../../redux/plan';
import { addPlanValidationSchema } from '../../utils/validation';
import Typography from '../../components/Typography';
import Button from '../../components/Button';
import Input from '../../components/Input';
import SelectDropdown from '../../components/SelectDropdown';
import {
	Wrapper,
	Header,
	Container,
	InputField,
	Forms
} from './styled';

const AddPlan = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [res, setRes] = useState({});
	const { isPlanAddedSuccess, planAddErrorMsg } = useSelector(state => state.plan);

	useEffect(() => {
		if (isPlanAddedSuccess){
			setRes(res);
			navigate('/plan/list');
		}
		else if (planAddErrorMsg){
			// setResErrors(planAddErrorMsg);
		}
	}, [isPlanAddedSuccess, planAddErrorMsg]);

	const handleAddPlan = async({ name, cost, days, carryforward, howmuchcf, status, connect, autorecharge }) => {
		const body = {
			plan_name:name,
			cost:cost,
			days:days,
			carry_forward:carryforward.value,
			how_much_carry_forward:howmuchcf,
			status:status.value,
			connect:connect,
			auto_recharge:autorecharge
		};
		dispatch(addPlan({ body }));
	};

	return (
		<Wrapper>
			<Forms>
				<Header>
					<Typography
						variant="h4"
						color="black"
						weight="500"
					>
						{t('ADDPLAN.HEADING')}
					</Typography>
				</Header>
				<Formik
					initialValues={{
						name: '',
						cost: '',
						days: '',
						carryforward: '',
						howmuchcf:'',
						status: '',
						type: '',
						connect: '',
						autorecharge:''
					}}
					validationSchema={addPlanValidationSchema(t)}
					onSubmit={handleAddPlan}
				>
					{({ errors, touched, values, handleChange, handleBlur, setFieldValue  }) => (
						<Form>
							<Container>
								<InputField>
									<Input
										name='name'
										placeholder={t('ADDPLAN.PLACEHOLDER.NAME')}
										type='text'
										onChange={handleChange}
										onBlur={handleBlur}
										error={touched.name && 1}
										errormessage={errors.name}  />
								</InputField>
								<InputField>
									<Input
										name='cost'
										placeholder={t('ADDPLAN.PLACEHOLDER.COST')}
										type='text'
										onChange={handleChange}
										onBlur={handleBlur}
										error={touched.cost && 1}
										errormessage={errors.cost} />
								</InputField>
								<InputField>
									<Input
										name='days'
										placeholder={t('ADDPLAN.PLACEHOLDER.DAYS')}
										type='number'
										onChange={handleChange}
										onBlur={handleBlur}
										error={touched.days && 1}
										errormessage={errors.days} />
								</InputField>
								<InputField>
									<Input
										name='connect'
										placeholder={t('ADDPLAN.PLACEHOLDER.CONNECT')}
										type='number'
										onChange={handleChange}
										onBlur={handleBlur}
										error={touched.connect && 1}
										errormessage={errors.connect} />
								</InputField>
								<InputField>
									<SelectDropdown
										name="carryforward"
										options={[{ value: '1', label: 'Yes' }, { value: '0', label: 'No' }]}
										placeholder={t('ADDPLAN.PLACEHOLDER.CARRYFORWARD')}
										onBlur={handleBlur}
										onChange={(e) => setFieldValue('carryforward', e)}
										error={touched.carryforward && 1}
										errormessage={errors.carryforward}
									/>
								</InputField>
								{values.carryforward?.value === '1' && <InputField>
									<Input
										name="howmuchcf"
										placeholder={t('ADDPLAN.PLACEHOLDER.HOWMUCHCF')}
										type='number'
										onChange={handleChange}
										onBlur={handleBlur}
										error={touched.howmuchcf && 1}
										errormessage={errors.howmuchcf}
									/>
								</InputField>}
								<InputField>
									<SelectDropdown
										name="autorecharge"
										options={[{ value: '1', label: 'Yes' }, { value: '0', label: 'No' }]}
										placeholder={t('ADDPLAN.PLACEHOLDER.AUTORECHARGE')}
										onBlur={handleBlur}
										onChange={(e) => setFieldValue('autorecharge', e)}
										error={touched.autorecharge && 1}
										errormessage={errors.autorecharge}
									/>
								</InputField>
								<InputField>
									<SelectDropdown
										name="status"
										options={[{ value: 'active', label: 'Active' }, { value: 'inactive', label: 'Inactive' }]}
										placeholder={t('ADDPLAN.PLACEHOLDER.STATUS')}
										onBlur={handleBlur}
										onChange={(e) => setFieldValue('status', e)}
										error={touched.status && 1}
										errormessage={errors.status}
									/>
								</InputField>
							</Container>
							<Button title={t('ADDPLAN.BTNTEXT')} type='submit' size={'sm'} >{t('ADDPLAN.BTNTEXT')}</Button>
						</Form>
					)}
				</Formik>
			</Forms>
		</Wrapper>
	);
};

export default AddPlan;