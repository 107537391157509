import React from 'react';
import { oneOf } from 'prop-types';
import theme from '../../themes/base.json';
import { Card, CardBody, IconContainer, IconWrapper, Stat, TextWrapper, Title } from './styled.js';

const { statCards } = theme;
export const StatsHorizontal = ({
	icon,
	stats,
	statTitle,
	...rest
}) => {
	return (
		<Card {...rest}>
			<CardBody>
				<IconContainer {...rest}>
					<IconWrapper>
						{icon}
					</IconWrapper>
				</IconContainer>
				<TextWrapper {...rest}>
					<Stat>{stats}</Stat>
					<Title>{statTitle}</Title>
				</TextWrapper>
			</CardBody>
		</Card>
	);
};

StatsHorizontal.propTypes = {
	variant: oneOf(Object.keys(statCards)),
};

StatsHorizontal.defaultProps = {
	variant: 'primary',
};