import styled from '@emotion/styled';
import { Link } from 'react-router-dom';

export const Container = styled.div`
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const Wrapper = styled.div`
    padding: 40px 60px;
    border-radius: 10px;
    background: ${(props) => props.theme.colors.light};
    max-width: 570px;
    width: 100%;
    ${(props) => props.theme.breakPoint.mobile} {
        margin: 15px;
        padding: 40px 20px;
    }
`;

export const Forms = styled.div`
    margin-top: 30px;
`;

export const OtpFormInputs = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 240px;
    margin: auto;
    > div {
        max-width: 45px;
        input {
            text-align: center;
        }
    }
`;

export const ForgotPassLink = styled(Link)`
    font-size: 16px;
    line-height: 1.5;
    text-decoration: none;
    display: inline-block;
    margin-bottom: 15px;
    text-align: right;
    color: ${(props) => props.theme.colors.primary};
`;

export const ResendOTP = styled.div`
    margin-top: 10px;
    text-align: center;
    div {
        cursor: pointer;
        display: inline;
    }
`;

export const PasswordWrapper = styled.div`
	display: flex;
	align-items: center;
	position: relative;
	.input {
		height: 48px;
		border-radius: 10px 0 0px 10px;
	}
	> div {
		margin-bottom: 0;
	}
	button.button {
		border-radius: 0 10px 10px 0;
		width: 60px;
		min-width: unset;
		display: flex;
		align-items: center;
	}
	p {
		position: absolute;
		bottom: -22px;
	}
`;