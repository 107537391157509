import styled from '@emotion/styled';
import { errorColor } from '../../style/variables';

export const Wrapper = styled.div`
    width: 100%;
    margin-bottom: 15px;
    .input {
        width: 100%;
        font-weight: 400;
        font-size: 16px;
        line-height: 45px;
        color: ${(props) => props.theme.colors.text.black};
        border: 1px solid ${(props) => props.theme.colors.lightgrey};
        border-radius: 10px;
        padding: 0 12px;
        height: 45px;
        font-family: ${(props) => props.theme.font};
        &:focus {
            border: 1px solid ${(props) => props.theme.colors.primary};
            outline: none;
        }
        &::-webkit-input-placeholder {
            color: ${(props) => props.theme.colors.text.lightgrey};
        }
        &::-moz-placeholder {
            color: ${(props) => props.theme.colors.text.lightgrey};
        }
        &:-ms-input-placeholder {
            color: ${(props) => props.theme.colors.text.lightgrey};
        }
        &:-moz-placeholder {
            color: ${(props) => props.theme.colors.text.lightgrey};
        }
        &.error {
            border: 1px solid ${(props) => props.theme.colors.red};
        }
    }
`;
export const Alert = styled.p`
    color: ${errorColor};
    margin: 5px 0 0 0;
`;
