import React, { useEffect, useState } from 'react';
import { t } from 'i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Formik, Form } from 'formik';
import { getHospitalList, addDoctor } from '../../redux/doctors';
import { addDoctorValidationSchema } from '../../utils/validation';
import Typography from '../../components/Typography';
import Button from '../../components/Button';
import Input from '../../components/Input';
import SelectDropdown from '../../components/SelectDropdown';
import {
	Wrapper,
	Header,
	Container,
	InputField,
	Forms
} from './styled';

const AddDoctor = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [options, setOptions] = useState([]);
	const [status, setStatus] = useState([]);
	const [emailError, setEmailError] = useState('');
	const [usernameError, setUsernameError] = useState('');
	const [mobileError, setMobileError] = useState('');
	const [assignError, setAssignError] = useState('');
	const [res, setRes] = useState({});
	const [resErrors, setResErrors] = useState({});
	const { hospitalList, isDoctorAddedSuccess, doctorAddErrorMsg } = useSelector(state => state.doctors);

	useEffect(() => {
		dispatch(getHospitalList());
	}, []);

	useEffect(() => {
		if (hospitalList.length > 0) {
			const getOption = hospitalList.map((item) => {
				return { value:item.id, label: item.name };
			});
			setOptions(getOption);
		}
		else {
			setOptions([]);
		}
	}, [hospitalList]);
	useEffect(() => {
		if (resErrors) {
			const message = resErrors.payload && resErrors.payload.message;
			const errArray = message && message.split('|');
			let emailError = errArray && errArray.indexOf(t('ERRORS.EMAIL_ALREADY_TAKEN'));
			(emailError !== -1 || undefined) && setEmailError(errArray && errArray[emailError]);
			let usernameError = errArray && errArray.indexOf(t('ERRORS.USERNAME_ALREADY_TAKEN'));
			(usernameError !== -1 || undefined) && setUsernameError(errArray && errArray[usernameError]);
			let mobileError = errArray && errArray.indexOf(t('ERRORS.MOBILE_ALREADY_TAKEN'));
			(mobileError !== -1 || undefined) && setMobileError(errArray && errArray[mobileError]);
			let assignerror = errArray && errArray.indexOf(t('ERRORS.HOSPITAL_MISSING'));
			(assignerror !== -1 || undefined) && setAssignError(errArray && errArray[assignerror]);
			/* let roleError = errArray && errArray.indexOf(t('ERRORS.EMPTY_USER_ROLE'));
			(roleError !== -1 || undefined) && setRoleError(errArray && errArray[roleError]); */
		}
	}, [resErrors]);
	useEffect(() => {
		if (isDoctorAddedSuccess){
			setRes(res);
			navigate('/dr-management/list');
		}
		else if (doctorAddErrorMsg){
			setResErrors(doctorAddErrorMsg);
		}
	}, [isDoctorAddedSuccess, doctorAddErrorMsg]);

	const handleAddDoctor = async({ fname, lname, mobile, email, username }) => {
		const body = { first_name: fname,
			last_name:lname,
			username: username,
			email: email,
			mobile_number: mobile,
			hospital_id:status };
		dispatch(addDoctor({ body }));
	};

	function newStatusValue(event, setFieldValue) {
		const value = event.map((item) => item.value);
		setFieldValue('selecthospital', value);
		setStatus(value);
	}
	return (
		<Wrapper>
			<Forms>
				<Header>
					<Typography
						variant="h4"
						color="black"
						weight="500"
					>
						{t('ADDDOCTOR.HEADING')}
					</Typography>
				</Header>
				<Formik
					initialValues={{
						fname: '',
						lname: '',
						mobile: '',
						email: '',
						username:'',
						selecthospital: ''
					}}
					validationSchema={addDoctorValidationSchema(t)}
					onSubmit={handleAddDoctor}
				>
					{({ errors, touched, handleChange, handleBlur, setFieldValue }) => (
						<Form>
							<Container>
								<InputField>
									<Input
										name='fname'
										placeholder={t('ADDDOCTOR.PLACEHOLDER.FIRST_NAME')}
										type='text'
										onChange={handleChange}
										onBlur={handleBlur}
										error={touched.fname && 1}
										errormessage={errors.fname}  />
								</InputField>
								<InputField>
									<Input
										name='lname'
										placeholder={t('ADDDOCTOR.PLACEHOLDER.LAST_NAME')}
										type='text'
										onChange={handleChange}
										onBlur={handleBlur}
										error={touched.lname && 1}
										errormessage={errors.lname} />
								</InputField>
								<InputField>
									<Input
										name='mobile'
										placeholder={t('ADDDOCTOR.PLACEHOLDER.MOBILE_NO')}
										type='number'
										onChange={handleChange}
										onBlur={handleBlur}
										error={touched.mobile && 1}
										errormessage={mobileError && mobileError.length ? mobileError : errors.mobile} />
								</InputField>
								<InputField>
									<Input
										name='email'
										placeholder={t('ADDDOCTOR.PLACEHOLDER.EMAIL')}
										type='email'
										onChange={handleChange}
										onBlur={handleBlur}
										error={touched.email && 1}
										errormessage={emailError && emailError.length ? emailError : errors.email} />
								</InputField>
								<InputField>
									<Input
										name="username"
										placeholder={t('ADDDOCTOR.PLACEHOLDER.USER_NAME')}
										type='text'
										onChange={handleChange}
										onBlur={handleBlur}
										error={touched.username && 1}
										errormessage={usernameError && usernameError.length ? usernameError : errors.username}
									/>
								</InputField>
								<InputField>
									<SelectDropdown
										name="selecthospital"
										options={options}
										isMulti={true}
										placeholder={t('ADDDOCTOR.PLACEHOLDER.SELECTHOSPITAL')}
										onBlur={handleBlur}
										onChange={(e) => newStatusValue(e, setFieldValue)}
										error={touched.selecthospital && 1}
										errormessage={assignError && assignError.length ? assignError : errors.selecthospital}
									/>
								</InputField>
							</Container>
							<Button title={t('ADDDOCTOR.BTNTEXT')} type='submit' size={'sm'} >{t('ADDDOCTOR.BTNTEXT')}</Button>
						</Form>
					)}
				</Formik>
			</Forms>
		</Wrapper>
	);
};

export default AddDoctor;