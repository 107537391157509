import React from 'react';
import { t } from 'i18next';
import { useNavigate } from 'react-router';
import Button from '../../components/Button';
import Typography from '../../components/Typography';
import { ButtonContainer, Container, Model, Text } from './styled';

const ErrorPage = () => {
	const navigate = useNavigate();

	const navigateToHome = () => {
		navigate('/');
	};

	return (
		<Container>
			<Model>
				<Text>
					<Typography
						variant="h1"
						color="primary"
						align="center"
					>
						{t('ERRORPAGE.404PAGE')}
					</Typography>
				</Text>
				<Text>
					<Typography
						variant="h2"
						color="black"
						align="center"
						weight="400"
					>
						{t('ERRORPAGE.NOTFOUND')}
					</Typography>
				</Text>
				<Text>
					<Typography
						variant="body"
						color="gray"
						align="center"
						weight="400"
					>
						{t('ERRORPAGE.TEXT')}
					</Typography>
				</Text>
				<ButtonContainer>
					<Button title={t('ERRORPAGE.BACKTOHOME')} size={'sm'} onClick={navigateToHome}>{t('ERRORPAGE.BACKTOHOME')}</Button>
				</ButtonContainer>
			</Model>
		</Container>
	);
};

export default ErrorPage;