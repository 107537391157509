import React from 'react';
import { any } from 'prop-types';
import Typography from '../Typography';
import { PageTitleWrapper, Title, Header } from './styled';

const PageTitle = ({
	children
}) => {
	return (
		<PageTitleWrapper>
			<Header>
				<Title>
					<Typography variant="h4" color="primary" >
						{children}
					</Typography>
				</Title>
			</Header>
		</PageTitleWrapper>
	);
};

PageTitle.propTypes = {
	children: any,
};
export default PageTitle;