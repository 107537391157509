/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Formik, Form } from 'formik';
import { HiOutlineEye, HiOutlineEyeOff } from 'react-icons/hi';
import Input from '../../../components/Input';
import Button from '../../../components/Button';
import Typography from '../../../components/Typography';
import { signInValidationSchema } from '../../../utils/validation';
import { useDispatch, useSelector } from 'react-redux';
import { handleLogin, verifyOtp, resendOtp } from '../../../redux/auth';
import { useAuth } from '../../../utils/helper';
import {
	Container,
	Wrapper,
	Forms,
	OtpFormInputs,
	ForgotPassLink,
	ResendOTP,
	PasswordWrapper
} from './styled.js';

const Login = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const [show, setShow] = useState(false);
	const { isOTPSent, isResendOTPSent, loginSuccessMessage, otpMessage, loginErrorMessage, loginResponse, loading } = useSelector(state => state.auth);
	const [otp1, setOtp1] = useState('');
	const [otp2, setOtp2] = useState('');
	const [otp3, setOtp3] = useState('');
	const [otp4, setOtp4] = useState('');
	const [otpErrors, setOtpErrors] = useState(otpMessage);
	const [count, setCount] = useState(30);
	const useAuthContext = useAuth();

	useEffect(() => {
		setOtpErrors(otpMessage);
	}, [otpMessage]);

	useEffect(() => {
		if (isResendOTPSent) {
			setCount(30);
		}
	}, [isResendOTPSent]);

	useEffect(() => {
		if (isOTPSent || isResendOTPSent) {
			const interval = setInterval(() => {
				setCount( (count) => {
					if (count>0){
						return (--count);
					}
					else {
						setCount(0);
						return clearInterval(interval);
					}
				});
			}, 1000);
			return () => {
				clearInterval(interval);
			};
		}
	}, [isOTPSent, isResendOTPSent]);

	const handleLoginSubmit = ({ email, password }) => {
		const body = { email, password };
		dispatch(handleLogin({ body }));
	};

	const handleFocus = (e) => {
		const { maxLength, value, name } = e.target;
		const [fieldIndex] = name.split('-')[1];
		const num = name.split('-')[1];
		num == 1 && setOtp1(value);
		num == 2 && setOtp2(value);
		num == 3 && setOtp3(value);
		num == 4 && setOtp4(value);
		let fieldIntIndex = parseInt(fieldIndex, 10);
		if (value.length >= maxLength) {
			if (fieldIntIndex < 4) {
				const nextfield = document.querySelector(
					`input[name=field-${fieldIntIndex + 1}]`
				);
				if (nextfield !== null) {
					nextfield.focus();
				}
			}
		}
	};

	const sendOtp = () => {
		const mobileno = loginResponse?.data?.data?.mobile_number;
		const user_id = loginResponse?.data?.data?.id;
		const body = { mobileno, user_id };
		dispatch(resendOtp({ body }));
	};

	const handleKeyDown = (e) => {
		const { name, value, maxLength } = e.target;
		const [fieldIndex] = name.split('-')[1];
		let fieldIntIndex = parseInt(fieldIndex, 10);
		if (e.key === 'Enter') {
			handleOtpSubmit();
		}
		if (e.key === 'Backspace') {
			if (value.length == 0) {
				if (fieldIntIndex >= maxLength - 1) {
					const nextfield = document.querySelector(
						`input[name=field-${fieldIntIndex - 1}]`
					);
					if (nextfield !== null) {
						nextfield.focus();
					}
				}
			}
		}
	};

	const handleOtpSubmit = () => {
		const otp_number = otp1.concat(otp2, otp3, otp4);
		const user_id = loginResponse?.data?.data?.id;
		const body = { otp_number, user_id };
		dispatch(verifyOtp({ body, useAuthContext }));
	};

	return (
		<Container>
			{
				!isOTPSent ?
					<Wrapper>
						<Typography
							variant="h2"
							color="black"
							align="center"
							display="block"
						>
							{t('LOGIN.TITLE')}
						</Typography>
						<Typography
							variant="caption"
							color="gray"
							align="center"
							display="block"
						>
							{t('LOGIN.SUB_TITLE')}
						</Typography>
						<Formik
							initialValues={{
								email: '',
								password: '',
							}}
							validationSchema={signInValidationSchema(t)}
							onSubmit={handleLoginSubmit}
						>
							{({ errors, touched, values, handleChange, handleBlur }) => (
								<Forms>
									<Form>
										<Input
											type="email"
											name="email"
											placeholder="Email"
											onBlur={handleBlur}
											error={touched.email && 1}
											onChange={handleChange}
											value={values.email}
											errormessage={errors.email}
										/>
										<PasswordWrapper>
											<Input
												type={show ? 'text' : 'password'}
												name="password"
												placeholder="Password"
												onChange={handleChange}
												onBlur={handleBlur}
												error={touched.password && 1}
												value={values.password}
												errormessage={errors.password}
											/>
											<Button title={t('Show / Hide')} variant="secondary" onClick={() => setShow(!show)} >
												{show ? <HiOutlineEye /> : <HiOutlineEyeOff />}
											</Button>
										</PasswordWrapper>
										<br />
										<Typography
											color="red"
										>
											{loginErrorMessage}</Typography>
										<Typography
											color="primary"
											align="right"
											display="block"
										>
											<ForgotPassLink to={'/forgot-password'}>{t('FOTGOTPASSWORD.LINKTEXT')}</ForgotPassLink></Typography>
										<Button title={t('LOGIN.BUTTON')}
											variant="primary"
											size="block"
											type="submit"
											disabled={loading}
										>
											{t('LOGIN.BUTTON')}
										</Button>
									</Form>
								</Forms>
							)}
						</Formik>
					</Wrapper>
					:
					<Wrapper>
						<Typography
							variant="h2"
							color="black"
							align="center"
							display="block"
						>
							{t('OTP.TITLE')}
						</Typography>
						<Typography
							variant="caption"
							color="gray"
							align="center"
						>
							{loginSuccessMessage}
						</Typography>
						<Forms>
							<OtpFormInputs>
								<Input
									type="text"
									placeholder="0"
									name="field-1"
									maxLength={1}
									onChange={handleFocus}
									onKeyDown={handleKeyDown}
								/>
								<Input
									type="text"
									placeholder="0"
									name="field-2"
									maxLength={1}
									onChange={handleFocus}
									onKeyDown={handleKeyDown}
								/>
								<Input
									type="text"
									placeholder="0"
									name="field-3"
									maxLength={1}
									onChange={handleFocus}
									onKeyDown={handleKeyDown}
								/>
								<Input
									type="text"
									placeholder="0"
									name="field-4"
									maxLength={1}
									onChange={handleFocus}
									onKeyDown={handleKeyDown}
								/>
							</OtpFormInputs>
							<Typography
								color="red"
								align="center"
							>{otpErrors}</Typography>
							<Button
								title={t('OTP.BUTTON')}
								variant="primary"
								size="block"
								onClick={handleOtpSubmit}
								type='submit'
							>
								{t('OTP.BUTTON')}
							</Button>
							<ResendOTP
								onClick={count > 0 ? () => false : sendOtp}

							>
								{count < 1 ?
									<Typography
										variant="caption"
										align="center"
										color="primary"
									>{t('OTP.RESEND')}</Typography>
									:
									<Typography
										variant="caption"
										align="center"
										color="primary"
									>{t('OTP.RESEND')}&nbsp;in&nbsp;({count})&nbsp;sec</Typography>
								}
							</ResendOTP>
						</Forms >
					</Wrapper >
			}
		</Container >
	);
};

export default Login;