import React, { useEffect, useState } from 'react';
import { t } from 'i18next';
import { useParams, useNavigate } from 'react-router-dom';
import { Formik, Form, isEmptyArray } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import Input from '../../components/Input';
import Typography from '../../components/Typography';
import SelectDropdown from '../../components/SelectDropdown';
import Button from '../../components/Button';
import { editUserValidationSchema } from '../../utils/validation';
import { userProfile, updateUser } from '../../redux/users';
import { UserPlaceholder } from '../../images';
import theme from '../../themes/base.json';
import { customStylesBgColor } from '../../style/variables';
import {
	Wrapper,
	Header,
	Container,
	InputField,
	Forms,
	ProfileImg,
	SpanText
} from './styled';
import { clearAddUserErrorMessage } from '../../redux/users/slice';

const options = [
	{ value: 'superadmin', label: 'Super Admin' },
	{ value: 'user', label: 'User' }
];

const customStyles = {
	control: (base) => ({
		...base,
		border: 'none',
		borderRadius: '10px',
		backgroundColor: { customStylesBgColor },
		boxShadow: 'none',
		width: '100%',
		'&:hover': {
			border: 'none'
		}
	}),
	option: (styles, { isFocused, isSelected }) => ({
		...styles,
		background: isFocused
			? 'rgba(8, 155, 171, 0.2)'
			: isSelected
				? theme.colors.primary
				: undefined,
		zIndex: 1,
		margin: 0,
		cursor: 'pointer',
		'&:active': {
			background: theme.colors.primary
		}
	}),
	dropdownIndicator: (base) => ({
		...base,
		color: theme.colors.primary
	}),
	indicatorSeparator: (base) => ({
		...base,
		display: 'none'
	}),
	singleValue: (base) => ({
		...base,
		color: theme.colors.primary
	})
};

const EditUser = () => {
	const { updateUserErrorMessage, userProfileDetails, isUserUpdateSuccess } = useSelector(state => state.users);
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const params = useParams();
	const { id } = params;
	const [file, setFile] = useState();
	const [img, setImage] = useState();
	const [fname, setFname] = useState('');
	const [lname, setLname] = useState('');
	const [mobile, setMobile] = useState('');
	const [email, setEmail] = useState('');
	const [status, setStatus] = useState(options[0]);
	const [profile, setProfile] = useState('');
	const [emailError, setEmailError] = useState('');
	const [roleError, setRoleError] = useState('');

	useEffect(() => {
		dispatch(userProfile({ user_id: id }));
	}, [id]);
	useEffect(() => {
		if (!isEmptyArray(userProfileDetails)) {
			setFname(userProfileDetails.first_name);
			setLname(userProfileDetails.last_name);
			setMobile(userProfileDetails.mobile_number);
			setEmail(userProfileDetails.email);
			setStatus(userProfileDetails.user_role == 'superadmin' ? options[0] : options[1]);
			setProfile(userProfileDetails.profile_picture || UserPlaceholder);
		}
	}, [userProfileDetails]);

	useEffect(() => {
		if (updateUserErrorMessage) {
			const message = updateUserErrorMessage?.payload.message;
			const errArray = message?.split('|');
			let emailError = errArray?.indexOf(t('ERRORS.EMAIL_ALREADY_TAKEN'));
			(emailError !== -1 || undefined) && setEmailError(errArray && errArray[emailError]);
			let roleError = errArray?.indexOf(t('ERRORS.EMPTY_USER_ROLE'));
			(roleError !== -1 || undefined) && setRoleError(errArray && errArray[roleError]);
		}
	}, [updateUserErrorMessage]);

	useEffect(() => {
		if (isUserUpdateSuccess) {
			navigate('/user-management/list');
		}
	}, [isUserUpdateSuccess]);

	useEffect(() => {
		return () => {
			dispatch(clearAddUserErrorMessage());
			setEmailError('');
			setRoleError('');
		};
	}, []);

	const handleImage = (e) => {
		setImage(e.target.files[0]);
		setFile(URL.createObjectURL(e.target.files[0]));
	};

	const handleUserEdit = async({ fname, lname, mobile, email }) => {
		const body = {
			user_id: id, first_name: fname, last_name: lname,
			mobile_number: mobile, email: email, user_role: status.value, profile_picture: img,
		};
		const response = await dispatch(updateUser({ body }));
		if (response.meta.requestStatus === 'fulfilled') {
			setEmailError('');
			setRoleError('');
		}
	};

	function newStatusValue(event) {
		setStatus(event);
	}

	return (
		<Wrapper>
			<Forms>
				<Header>
					<Typography
						variant="h4"
						color="black"
						weight="500"
					>
						{t('EDITUSER.HEADING')}
					</Typography>
					<Button
						title={t('EDITUSER.BACKBUTTON')}
						onClick={() => navigate('/user-management/list')}
						size={'sm'}
					>
						{t('EDITUSER.BACKBUTTON')}
					</Button>
				</Header>
				<ProfileImg>
					<img src={file ? file : profile} />
					<SpanText>
						<Typography
							variant="body"
							color="primary"
						>
							{t('EDITUSER.IMGSELECT')}
						</Typography>
					</SpanText>
					<input type="file" accept='image/*' onChange={handleImage} />
				</ProfileImg>
				<Formik
					initialValues={{
						fname,
						lname,
						mobile,
						email,
					}}
					validationSchema={editUserValidationSchema(t)}
					onSubmit={handleUserEdit}
					enableReinitialize={true}
				>
					{({ errors, values, touched, handleChange, handleBlur }) => (
						<Form>
							<Container>
								<InputField>
									<Input
										placeholder='First Name'
										name='fname'
										type='text'
										onChange={handleChange}
										onBlur={handleBlur}
										error={touched.fname && 1}
										errormessage={errors.fname}
										value={values.fname}
									/>
								</InputField>
								<InputField>
									<Input
										placeholder='Last Name'
										name='lname'
										type='text'
										onChange={handleChange}
										onBlur={handleBlur}
										error={touched.lname && 1}
										errormessage={errors.lname}
										value={values.lname}
									/>
								</InputField>
								<InputField>
									<Input
										placeholder='Mobile Number'
										type='tel'
										name='mobile'
										onChange={handleChange}
										onBlur={handleBlur}
										error={touched.mobile && 1}
										errormessage={errors.mobile}
										value={values.mobile}
									/>
								</InputField>
								<InputField>
									<Input
										placeholder='Email Address'
										type='email'
										name='email'
										onChange={handleChange}
										onBlur={handleBlur}
										error={touched.email && 1}
										errormessage={emailError?.length ? emailError : errors.email}
										value={values.email}
									/>
								</InputField>
								<InputField>
									<SelectDropdown
										options={options}
										customStyles={customStyles}
										placeholder={'User Role'}
										name='status'
										value={status}
										onChange={newStatusValue}
										error={touched.status && 1}
										errormessage={roleError?.length ? roleError : errors.status}
									/>
								</InputField>
							</Container>
							<Button title={t('EDITUSER.BTNTEXT')} type='submit' size={'sm'}>{t('EDITUSER.BTNTEXT')}</Button>
						</Form>
					)}
				</Formik>
			</Forms>
		</Wrapper >
	);
};

export default EditUser;