/* eslint-disable camelcase */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { forgotPasswordValidationSchema } from '../../../utils/validation';
import { useDispatch, useSelector } from 'react-redux';
import { forgotPassword } from '../../../redux/auth';
import { Formik, Form } from 'formik';
import Input from '../../../components/Input';
import Button from '../../../components/Button';
import Typography from '../../../components/Typography';
import {
	Container,
	Wrapper,
	Forms
} from './styled.js';

const ForgotPassword = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const { isForgotSuccess, forgotSuccessMessage, isForgotReject, forgotErrorMessage } = useSelector(state => state.auth);

	const handleForgotPassword = ({ email, user_role = 'superadmin' }) => {
		const body = { email, user_role };
		dispatch(forgotPassword({ body }));
	};

	return (
		<Container>
			{!isForgotSuccess ?
				<Wrapper>
					<Typography
						variant="h2"
						color="black"
						align="center"
						display="block"
					>
						{t('FOTGOTPASSWORD.TITLE')}
					</Typography>
					<Typography
						variant="caption"
						color="gray"
						align="center"
						display="block"
					>
						{t('FOTGOTPASSWORD.SUB_TITLE')}
					</Typography>
					<Formik
						initialValues={{
							email: '',
						}}
						validationSchema={forgotPasswordValidationSchema(t)}
						onSubmit={handleForgotPassword}
					>
						{({ errors, touched, values, handleChange, handleBlur }) => (
							<Forms>
								<Form>
									<Input
										type="email"
										name="email"
										placeholder={t('PLACEHOLDER.EMAIL')}
										onBlur={handleBlur}
										error={touched.email && 1}
										onChange={handleChange}
										value={values.email}
										errormessage={isForgotReject ? forgotErrorMessage: errors.email}
									/>
									<Button title={t('FOTGOTPASSWORD.BUTTON')}
										variant="primary"
										size="block"
										type="submit"
									>
										{t('FOTGOTPASSWORD.BUTTON')}
									</Button>
								</Form>
							</Forms>
						)}
					</Formik>
				</Wrapper>
				: <div>{forgotSuccessMessage.data.message}</div>
			}

		</Container>
	);
};

export default ForgotPassword;