import styled from '@emotion/styled';

export const Container = styled.div`
    padding: 10px 10px;
    margin-top: 0px;
    min-height: 100%;
`;
export const Wrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    width: 100%;
    padding: 16px 0px;
`;

export const Card = styled.div`
    display: flex;
    flex-direction: column;
    width: calc(100%/3);
    padding: 0px 16px;
    ${(props) => props.theme.breakPoint.tablet} {
        width: 50%;
    }
    ${(props) => props.theme.breakPoint.mobile} {
        width: 100%;
    }
`;

export const CardBody = styled.div`
    width: 100%;
    margin: 10px 0px;
    background-color: rgb(255, 255, 255);
    position: relative;
    border-radius: 25px;
    overflow: hidden;
    box-shadow: rgb(175 175 175 / 10%) 0px 4px 5px 1px;
`;

export const ProfileHeading = styled.div`
    background: linear-gradient(to right, rgb(8, 155, 171) 0%, rgb(13, 181, 200) 100%);
    height: 150px;
    width: 100%;
    top: 0px;
    left: 0px;
    z-index: 0;
`;

export const ProfileDetail = styled.div`
    z-index: 2;
    position: relative;
    margin-top: -70px;
`;

export const ProfileImgWrapper = styled.div`
    width: 130px;
    height: 130px;
    border-radius: 50%;
    border: 2px solid ${(props) => props.theme.colors.light};
    overflow: hidden;
    margin: auto;
    z-index: 15;
`;

export const ProfileImg = styled.img`
    max-width: 100%;
    object-fit: contain;
    object-position: center top;
`;

export const ProfileTitle = styled.h3`
    font-size: 24px;
    line-height: 28px;
    margin-top: 16px;
    color: rgb(83, 83, 95);
    margin: 0px;
    padding: 0px;
    text-align: center;
`;
export const ProfileRole = styled.p`
    color: rgb(160, 158, 158);
    text-transform: capitalize;
    font-size: 14px;
    line-height: 1.4;
    font-weight: 400;
    margin: 0px;
    padding-bottom: 10px;
    text-align: center;
`;
export const ProfileDescription = styled.div`
    border-top: 1px solid ${(props) => props.theme.colors.lightgrey};
    padding: 20px;
    display: block;
`;
export const Detail = styled.p`
    font-size: 16px;
    line-height: 1.5;
    color: rgb(83, 83, 95);
    display: flex;
    align-items: flex-start;
    flex-flow: row wrap;
    margin: 5px 0px;
`;
export const Label = styled.span`
    box-sizing: border-box;
`;
export const Value = styled.span`
    font-weight: 600;
    box-sizing: border-box;
`;