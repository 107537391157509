import { createAsyncThunk } from '@reduxjs/toolkit';
import { callGetAPI, callPostAPI } from '../../services/axios';

export const getForm = createAsyncThunk(
	'getForm',
	async(params, { rejectWithValue }) => {
		try {
			const response = await callGetAPI({ route: 'superadmin/form/list', params });
			return { response };
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	},
);

export const getDefaultForms = createAsyncThunk(
	'getDefaultForms',
	async(params, { rejectWithValue }) => {
		try {
			const response = await callGetAPI({ route: 'superadmin/defaultform/list', params });
			return { response };
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	},
);

export const updateForm = createAsyncThunk(
	'updateForm',
	async(params, { rejectWithValue }) => {
		try {
			const response = await callPostAPI({ route: 'superadmin/update/form', body: params.body, headersProps: { 'Content-Type': 'multipart/form-data' }, });
			return { response };
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	},
);

export const addForm = createAsyncThunk(
	'addForm',
	async(params, { rejectWithValue }) => {
		try {
			const response = await callPostAPI({ route: 'superadmin/add/form', body: params.body, headersProps: { 'Content-Type': 'multipart/form-data' }, });
			return { response };
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	},
);

export const updateFormFieldOrdering = createAsyncThunk(
	'updateFormFieldOrdering',
	async(params, { rejectWithValue }) => {
		try {
			const response = await callPostAPI({ route: 'superadmin/form-fields/sorting', body: params });
			return { response };
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	},
);

export const deleteForm = createAsyncThunk(
	'deleteForm',
	async(params, { rejectWithValue }) => {
		try {
			const response = await callPostAPI({ route: 'superadmin/delete/form', body: params.body });
			return { response };
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	},
);

export const getFormList = createAsyncThunk(
	'getFormList',
	async(params, { rejectWithValue }) => {
		try {
			const response = await callGetAPI({ route: 'superadmin/edit/get/form/list', params });
			return { response };
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	},
);

export const getEditForm = createAsyncThunk(
	'getEditForm',
	async(params, { rejectWithValue }) => {
		try {
			const response = await callGetAPI({ route: 'superadmin/edit/form', params });
			return { response };
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	},
);

export const importForm = createAsyncThunk(
	'importForm',
	async(params, { rejectWithValue }) => {
		try {
			const response = await callPostAPI({ route: 'superadmin/import/form', body: params.body, headersProps: { 'Content-Type': 'multipart/form-data' }, });
			return { response };
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	},
);

export const exportForm = createAsyncThunk(
	'exportForm',
	async(params, { rejectWithValue }) => {
		try {
			const response = await callGetAPI({ route: 'superadmin/export/form', params : params });
			const { data } = response.data.data;
			const { fileName } = response.data;
			return { response: {
				file_name: fileName,
				file: data,
			}};
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	},
);

export const importFormSample = createAsyncThunk(
	'importFormSample',
	async(params, { rejectWithValue }) => {
		try {
			const response = await callGetAPI({ route: 'superadmin/import/form/samplefile', params : params });
			const { data } = response.data.data;
			const { fileName } = response.data;
			return { response: {
				file_name: fileName,
				file: data,
			}};
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	},
);

export const getHospitals = createAsyncThunk(
	'getHospitals',
	async(params, { rejectWithValue }) => {
		try {
			const response = await callGetAPI({ route: 'superadmin/hospital/full/list', params });
			return { response };
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	},
);

export const accessForm = createAsyncThunk(
	'accessForm',
	async(params, { rejectWithValue }) => {
		try {
			const response = await callPostAPI({ route: 'superadmin/form/access/status', body: params.body });
			return { response };
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	},
);