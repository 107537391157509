import { css } from '@emotion/react';
import buildTheme from './buildTheme';

export const GlobalStyle = (host) => {
	const theme = buildTheme(host);
	return css`
		@import url('https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700;900&display=swap');
		* {
			box-sizing: border-box;
		}
		html,
		body {
			width: 100%;
			height: 100%;
			margin: 0;
			padding: 0;
			font-family: ${theme.font};
			scroll-behavior: smooth;
			user-select: auto;
			overflow: auto;
			background: ${theme.colors.primaryLight};
		}
		#root {
			height: 100%;
		}
		*::-webkit-scrollbar-track {
            border-radius: 10px;
            background-color: transparent;
        }
        *::-webkit-scrollbar {
            width: 5px;
            height: 5px;
            background-color: transparent;
            @media (max-width: 992px) {
                width: 1px;
            }
        }
        *::-webkit-scrollbar-thumb {
            border-radius: 10px;
            background-color: ${theme.colors.primary};
            @media (max-width: 992px) {
                background-color: transparent;
            }
        }
        .content {
            @media (max-width: 992px) {
                *::-webkit-scrollbar {
                    width: 0;
                    height: 0;
                }
            }
        }
        @keyframes shadow-pulse-dots-notification {
            0% { box-shadow: 0 0 0 0px ${theme.colors.red}20; }
            100% { box-shadow: 0 0 0 15px  ${theme.colors.red}00; }
        }
        @keyframes shadow-pulse-dots-chat {
            0% { box-shadow: 0 0 0 0px  ${theme.colors.primary}20; }
            100% { box-shadow: 0 0 0 15px ${theme.colors.primary}00; }
        }

        @keyframes rounded {
            from {
                transform: rotate(0);
            }
            to {
                transform: rotate(360deg);
            }
        }
	`;
};
