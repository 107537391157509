import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Formik, Form } from 'formik';
import { t } from 'i18next';
import { useDispatch, useSelector } from 'react-redux';
import Typography from '../../components/Typography';
import Button from '../../components/Button';
import Input from '../../components/Input';
import { importFormSample, importForm } from '../../redux/form';
// import { importFormValidationSchema } from '../../utils/validation';
import { importSampleFormComplete } from '../../redux/form/slice';
import { Container, Forms, Header, InputField, Wrapper, Seperator, UL, LI } from './styled';
import { downloadFile } from '../../utils/helper';

const ImportForm = () => {
	const { isFormImportedSuccess, importFormErrorMessage, isFormImportSampleSuccess, importSampleFormPath } = useSelector(state => state.form);
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [file, setFile] = useState();
	const handleFile = (e) => {
		setFile(e.target.files[0]);
	};
	const handleImportFormSample = () => {
		dispatch(importFormSample());
	};
	const handleImportForm = () => {
		const body = {
			file
		};
		dispatch(importForm({ body }));
	};
	useEffect(() => {
		if (isFormImportedSuccess) {
			navigate('/form-management/list');
		}
	}, [isFormImportedSuccess]);

	useEffect(() => {
		if (isFormImportSampleSuccess) {
			dispatch(importSampleFormComplete());
			downloadFile(importSampleFormPath);
		}
	}, [isFormImportSampleSuccess]);

	return (
		<Wrapper>
			<Forms>
				<Header>
					<Typography variant="h4" color="black" weight="500">
						{t('IMPORTFORM.HEADING')}
					</Typography>
					<Button
						title={t('IMPORTFORM.BACKBUTTON')}
						onClick={() => navigate('/form-management/list')}
						size={'xs'}
					>
						{t('IMPORTFORM.BACKBUTTON')}
					</Button>
				</Header>
				<Formik initialValues={{ 'file_upload': '' }} /* validationSchema={importFormValidationSchema(t)} */ onSubmit={handleImportForm} >
					{({ errors, touched }) => (
						<Form>
							<Container>
								<InputField>
									<Input
										placeholder={t('IMPORTFORM.PLACEHOLDER.FILE')}
										name='file_upload'
										type='file'
										accept='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
										onChange={handleFile}
										error={touched.file_upload && 1}
										errormessage={importFormErrorMessage || errors.file_upload}
									/>
								</InputField>
								<InputField>
									<Button title={t('IMPORTFORM.BTNSAMPLEFILE')} onClick={handleImportFormSample} size={'default'}>
										{t('IMPORTFORM.BTNSAMPLEFILE')}
									</Button>
								</InputField>
								<InputField>
									<Typography variant="h4" color="black" weight="500">
										{t('IMPORTFORM.HEADERDO')}
									</Typography>
									<Seperator />
									<UL>
										<LI>{t('IMPORTFORM.DO1')}</LI>
										<LI>{t('IMPORTFORM.DO2')}</LI>
										<UL>
											<LI>{t('IMPORTFORM.DO2_1')}</LI>
											<LI>{t('IMPORTFORM.DO2_2')}</LI>
										</UL>
										<LI>{t('IMPORTFORM.DO3')}</LI>
										<UL>
											<LI>{t('IMPORTFORM.DO3_1')}</LI>
											<LI>{t('IMPORTFORM.DO3_2')}</LI>
											<LI>{t('IMPORTFORM.DO3_3')}</LI>
											<LI>{t('IMPORTFORM.DO3_4')}</LI>
										</UL>
										<LI>{t('IMPORTFORM.DO4')}</LI>
									</UL>
								</InputField>
								<InputField>
									<Typography variant="h4" color="black" weight="500">
										{t('IMPORTFORM.HEADERDONT')}
									</Typography>
									<Seperator />
									<UL>
										<LI>{t('IMPORTFORM.DO1')}</LI>
										<LI>{t('IMPORTFORM.DO2')}</LI>
										<LI>{t('IMPORTFORM.DO3')}</LI>
									</UL>
								</InputField>
							</Container>
							<Container>
								<Button title={t('ADDFORM.BTNTEXT')} type='submit' size={'sm'}>{t('ADDFORM.BTNTEXT')}</Button>
							</Container>
						</Form>
					)}
				</Formik>
			</Forms>
		</Wrapper>
	);
};

export default ImportForm;