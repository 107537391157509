import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Formik, Form } from 'formik';
import { t } from 'i18next';
import { useDispatch, useSelector } from 'react-redux';
import Typography from '../../components/Typography';
import Button from '../../components/Button';
import Input from '../../components/Input';
import { exportFormComplete, importSampleFormComplete } from '../../redux/form/slice';
import { importForm, exportForm, importFormSample } from '../../redux/form';
// import { importFormValidationSchema } from '../../utils/validation';
import { Container, Forms, Header, InputField, LI, MainContainer, Seperator, UL, Wrapper }
	from './styled';
import { downloadFile } from '../../utils/helper';

const ImportExportFormHospital = () => {
	const { isFormImportedSuccess, importFormErrorMessage, isFormExportedSuccess, exportFormPath, isFormImportSampleSuccess, importSampleFormPath } = useSelector(state => state.form);
	const { id, page } = useParams();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [file, setFile] = useState();

	const handleFile = (e) => {
		setFile(e.target.files[0]);
	};
	const handleImportFormSample = () => {
		dispatch(importFormSample());
	};
	const handleImportForm = () => {
		const body = {
			file,
			'hospital_id': id
		};
		dispatch(importForm({ body }));
	};
	useEffect(() => {
		if (isFormImportSampleSuccess) {
			dispatch(importSampleFormComplete());
			downloadFile(importSampleFormPath);
		}
	}, [isFormImportSampleSuccess]);

	useEffect(() => {
		if (isFormImportedSuccess) {
			navigate('/form-management/list');
		}
	}, [isFormImportedSuccess]);

	useEffect(() => {
		if (isFormExportedSuccess) {
			dispatch(exportFormComplete());
			downloadFile(exportFormPath);
		}
	}, [isFormExportedSuccess]);
	const handleImportExportForm = () => {
		dispatch(exportForm({ 'hospital_id': id }));
	};
	return (
		<MainContainer>
			<Forms>
				<Header>
					<Typography variant="h4" color="black" weight="500">
						{t('IMPORTEXPORTFORMHOSPITAL.HEADING')}
					</Typography>
					<Button title={t('IMPORTEXPORTFORMHOSPITAL.BACKBUTTON')} onClick={() => navigate('/hospital-management/list', { page: page })} size={'xs'}>
						{t('IMPORTEXPORTFORMHOSPITAL.BACKBUTTON')}
					</Button>
				</Header>
			</Forms>
			<Wrapper>
				<Forms>
					<Header>
						<Typography variant="h4" color="black" weight="500">
							{t('IMPORTEXPORTFORMHOSPITAL.HEADINGIMPORT')}
						</Typography>
					</Header>
					<Formik initialValues={{ 'file_upload': '' }} /* validationSchema={importFormValidationSchema(t)} */ onSubmit={handleImportForm} >
						{({ errors, touched }) => (
							<Form>
								<Container>
									<InputField>
										<Input
											placeholder={t('IMPORTEXPORTFORMHOSPITAL.PLACEHOLDER.FILE')}
											name='file_upload'
											type='file'
											accept='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
											onChange={handleFile}
											error={touched.file_upload && 1}
											errormessage={importFormErrorMessage != '' ? importFormErrorMessage : errors.file_upload}
										/>
									</InputField>
									<InputField>
										<Button title={t('IMPORTEXPORTFORMHOSPITAL.UPLOADBUTTON')} type='submit' size={'sm'}>{t('IMPORTEXPORTFORMHOSPITAL.UPLOADBUTTON')}</Button>
										<Button title={t('IMPORTFORM.BTNSAMPLEFILE')} onClick={handleImportFormSample} size={'sm'}>
											{t('IMPORTFORM.BTNSAMPLEFILE')}
										</Button>
									</InputField>
								</Container>
							</Form>
						)}
					</Formik>
				</Forms>
			</Wrapper>
			<Wrapper>
				<Forms>
					<Header>
						<Typography variant="h4" color="black" weight="500">
							{t('IMPORTEXPORTFORMHOSPITAL.HEADINGEXPORT')}
						</Typography>
					</Header>
					<Container>
						<Button title={t('IMPORTEXPORTFORMHOSPITAL.DOWNLOADBUTTON')} onClick={handleImportExportForm} size={'sm'}>{t('IMPORTEXPORTFORMHOSPITAL.DOWNLOADBUTTON')}</Button>
					</Container>
				</Forms>
			</Wrapper>
			<InputField>
				<Typography variant="h4" color="black" weight="500">
					{t('IMPORTFORM.HEADERDO')}
				</Typography>
				<Seperator />
				<UL>
					<LI>{t('IMPORTFORM.DO1')}</LI>
					<LI>{t('IMPORTFORM.DO2')}</LI>
					<UL>
						<LI>{t('IMPORTFORM.DO2_1')}</LI>
						<LI>{t('IMPORTFORM.DO2_2')}</LI>
					</UL>
					<LI>{t('IMPORTFORM.DO3')}</LI>
					<UL>
						<LI>{t('IMPORTFORM.DO3_1')}</LI>
						<LI>{t('IMPORTFORM.DO3_2')}</LI>
						<LI>{t('IMPORTFORM.DO3_3')}</LI>
						<LI>{t('IMPORTFORM.DO3_4')}</LI>
					</UL>
					<LI>{t('IMPORTFORM.DO4')}</LI>
				</UL>
			</InputField>
			<InputField>
				<Typography variant="h4" color="black" weight="500">
					{t('IMPORTFORM.HEADERDONT')}
				</Typography>
				<Seperator />
				<UL>
					<LI>{t('IMPORTFORM.DO1')}</LI>
					<LI>{t('IMPORTFORM.DO2')}</LI>
					<LI>{t('IMPORTFORM.DO3')}</LI>
				</UL>
			</InputField>
		</MainContainer>
	);
};

export default ImportExportFormHospital;