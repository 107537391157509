import styled from '@emotion/styled';

export const Container = styled.div`
    padding: 10px 10px;
    margin-top: 0px;
    min-height: 100%;
`;
export const Wrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    width: 100%;
    padding: 16px 0px;
`;

export const Card = styled.div`
    display: flex;
    flex-direction: column;
    width: calc(100%/3);
    padding: 0px 16px;
    ${(props) => props.theme.breakPoint.tablet} {
        width: 50%;
    }
    ${(props) => props.theme.breakPoint.mobile} {
        width: 100%;
    }
`;

export const CardBody = styled.div`
    width: 100%;
    background-color: ${(props) => props.theme.colors.light};
    position: relative;
    border-radius: 25px;
    overflow: hidden;
    box-shadow: 0px 4px 5px 1px ${(props) => props.theme.colors.shadow}10;
`;

export const ProfileHeading = styled.div`
    background: linear-gradient(to right, ${(props) => props.theme.colors.primary} 0%, ${(props) => props.theme.colors.lightgrey} 100%);
    height: 150px;
    width: 100%;
    top: 0px;
    left: 0px;
    z-index: 0;
`;

export const ProfileDetail = styled.div`
    z-index: 2;
    position: relative;
    margin-top: -70px;
`;

export const ProfileImgWrapper = styled.div`
    width: 130px;
    height: 130px;
    border-radius: 50%;
    border: 2px solid ${(props) => props.theme.colors.light};
    overflow: hidden;
    margin: auto;
    z-index: 15;
`;

export const ProfileImg = styled.img`
    max-width: 100%;
    object-fit: contain;
    object-position: center top;
`;

export const ProfileTitle = styled.h3`
    font-size: 24px;
    line-height: 28px;
    margin-top: 16px;
    color: ${(props) => props.theme.colors.primary};
    margin: 0px;
    padding: 0px;
    text-align: center;
`;
export const ProfileSubTitle = styled.p`
    color: ${(props) => props.theme.colors.lightgrey};
    text-transform: capitalize;
    font-size: 14px;
    line-height: 1.4;
    font-weight: 400;
    margin: 0px;
    padding-bottom: 10px;
    text-align: center;
`;
export const ProfileDescription = styled.div`
    border-top: 1px solid ${(props) => props.theme.colors.lightgrey};
    padding: 20px;
    display: block;
`;
export const Detail = styled.p`
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
`;
export const Label = styled.span`
	flex:0 0 25%;
	max-width:25%;
    font-weight: 600;
`;
export const Value = styled.span`
    flex:0 0 75%;
	max-width:75%;
	color:${(props) => props.theme.colors.shadow};
`;
export const Stats = styled.div`
   	display: flex;
    flex-direction: column;
    width: calc(calc(100%/3)*2);
    padding: 0px 16px;
    ${(props) => props.theme.breakPoint.tablet} {
        width: 50%;
    }
    ${(props) => props.theme.breakPoint.mobile} {
        width: 100%;
    }
`;