/* eslint-disable no-undef */
import Axios from 'axios';

export function unAuthenticatedInstance() {
	return Axios.create({
		baseURL: process.env.REACT_APP_API_URL
	});
}
console.log(process.env.REACT_APP_API_URL);
export function authenticatedInstance({ headersProps, params }) {
	const headers = {
		'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
		...headersProps,
	};

	const axios = Axios.create({
		baseURL: process.env.REACT_APP_API_URL,
		headers: headers,
		params,
	});
	axios.interceptors.response.use(
		function(response) {
			// Do something with response data
			if (response.data && response.data.status_code === 401) {
				localStorage.removeItem('authToken');
				location.href = '/login';
			}
			return response;
		},
		function(error) {
			// Do something with response error
			if (error.response && error.response.status === 401) {
				if (error.config.headers['Authorization']) {
					localStorage.removeItem('authToken');
					location.href = '/login';
				}
				return Promise.reject(error);
			} else {
				return Promise.reject(error);
			}
		}
	);
	return axios;
}

export function callPostAPI({ route, body, isAuthenticated = true, headersProps = {}, params }) {
	if (isAuthenticated) {
		const requestInstance = authenticatedInstance({ headersProps, params });
		return requestInstance.post(route, body);
	}
	const requestInstance = unAuthenticatedInstance();
	return requestInstance.post(route, body);
}

export function callGetAPI({ route, params, isAuthenticated = true }) {
	if (isAuthenticated) {
		const requestInstance = authenticatedInstance({ params });
		return requestInstance.get(route);
	}
	const requestInstance = unAuthenticatedInstance();
	return requestInstance.get(route);
}