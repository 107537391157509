import React, { useEffect, useState } from 'react';
import { t } from 'i18next';
import ReactPaginate from 'react-paginate';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { HiChevronDoubleLeft, HiChevronDoubleRight } from 'react-icons/hi';
import { IoCheckmarkSharp } from 'react-icons/io5';
import { AiOutlineEdit, AiOutlineDelete } from 'react-icons/ai';
import Button from '../../components/Button';
import Typography from '../../components/Typography';
import Input from '../../components/Input';
import Table from '../../components/Table';
import Loader from '../../components/Loader';
import useDebounce from '../../utils/useDebounceHook';
import { getPlanList, deletePlan, setDefaultPlan } from '../../redux/plan';
import {
	Buttons,
	Footer,
	TableContent,
	Header,
	Modal,
	Overlay,
	Container,
	Content,
	SearchInput,
	InputContainer,
} from './styled';

const PlanList = () => {
	const TableHeadings = ['ID', 'Plan ID', 'Plan Name', 'Days', 'Connects', 'Cost', 'Default', 'Auto Recharge?', 'Status', ''];
	const TableFields = ['id', 'unique_id', 'plan_name', 'days', 'connect', 'cost', 'default', 'auto_recharge', 'status'];
	const TableActions = [
		{
			'name': 'default',
			'handleClick': (item) => handleSetDefault(item.id),
			'size': 'xs',
			'variant': 'success',
			'title': 'Mark As Default',
			'text': <IoCheckmarkSharp />
		},
		{
			'name': 'edit',
			'handleClick': (item) => handleEdit(item.id),
			'size': 'xs',
			'variant': 'primary',
			'title': 'Edit',
			'text': <AiOutlineEdit />
		},
		{
			'name': 'delete',
			'handleClick': (item) => handleDelete(item.id),
			'size': 'xs',
			'variant': 'danger',
			'title': 'Delete',
			'text': <AiOutlineDelete />
		}
	];
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { planList, planData, loading, isPlanDefaultSuccess } = useSelector(state => state.plan);
	const [currentItems, setCurrentItems] = useState([]);
	const [pageCount, setPageCount] = useState(0);
	const [searchUserName, setSearchUserName] = useState('');
	const [deleteMsg, setDeleteMsg] = useState({ show: false, id: null });
	const debouncedSearchTerm = useDebounce(searchUserName, 500);
	const [page, setPage] = useState(1);

	useEffect(() => {
		if (planList?.length) {
			setCurrentItems(planList.map((plan) => ({
				...plan,
				default: plan.default ? 'Yes' : 'No',
				auto_recharge: plan.auto_recharge ? 'Yes' : 'No',
			})));
			planList.length ? setPageCount(planData.last_page) : setPageCount(0);
		}
	}, [planList]);

	useEffect(() => {
		if (isPlanDefaultSuccess) {
			dispatch(getPlanList({ page: page, status: 'all' }));
		}
	}, [isPlanDefaultSuccess]);

	useEffect(() => {
		if (debouncedSearchTerm == '') {
			dispatch(getPlanList({ page: page, status: 'all' }));
		}
		else if (debouncedSearchTerm !== '') {
			dispatch(getPlanList({ page: page, search: debouncedSearchTerm, status: 'all' }));
		}
	}, [page, debouncedSearchTerm]);


	const handleSetDefault = (id) => {
		const body = { plan_id: id };
		dispatch(setDefaultPlan({ body }));
	};

	const handleEdit = (id) => {
		navigate(`/plan/editplan/${id}/${page}`);
	};

	const handleDelete = (id) => {
		setDeleteMsg({
			show: true,
			id,
		});
	};

	const hendleDeleteYes = async() => {
		if (deleteMsg.show && deleteMsg.id) {
			const body = { plan_id: deleteMsg.id };
			const response = await dispatch(deletePlan({ body }));
			if (response.meta.requestStatus === 'fulfilled') {
				setDeleteMsg({
					show: false,
					id: null,
				});
				dispatch(getPlanList({ page: page, status: 'all' }));
			}
		}
	};

	const handlePageClick = (event) => {
		const pageNum = event.selected + 1;
		setPage(pageNum);
	};

	const handleSearchByName = (event) => {
		const inputVal = event.target.value;
		setSearchUserName(inputVal);
		setPage(1);
	};
	return (
		<Container>
			<Content>
				<Header>
					<Typography
						variant="h4"
						color="black"
						weight="500"
					>
						{t('PLANLIST.HEADING')}
					</Typography>
				</Header>
				<SearchInput>
					<InputContainer>
						<Input placeholder={t('PLANLIST.PLACEHOLDER.INPUT')} onChange={handleSearchByName} value={searchUserName} />
					</InputContainer>
				</SearchInput>
				<TableContent>
					{loading ? <Loader /> : <Table tableHeading={TableHeadings} tableFields={TableFields} tableData={currentItems} tableActions={TableActions} />}
					<Footer>
						<ReactPaginate
							breakLabel="..."
							nextLabel={<HiChevronDoubleRight />}
							onPageChange={handlePageClick}
							pageRangeDisplayed={5}
							pageCount={pageCount}
							previousLabel={<HiChevronDoubleLeft />}
							forcePage={pageCount === 0 ? -1 : page - 1}
						/>
					</Footer>
				</TableContent>
			</Content>
			{deleteMsg.show ?
				<Overlay>
					<Modal>
						<Typography
							variant="h4"
							color="black"
						>
							{t('PLANLIST.DELETECONFIRM')}
						</Typography>
						<Buttons>
							<Button title={t('ADDDOCTOR.BTNTEXT')} onClick={() => setDeleteMsg(false)} size={'sm'}>{t('PLANLIST.BTNNO')}</Button>
							<Button title={t('ADDDOCTOR.BTNTEXT')} onClick={hendleDeleteYes} size={'sm'} variant={'danger'}>{t('PLANLIST.BTNYES')}</Button>
						</Buttons>
					</Modal>
				</Overlay>
				: null}
		</Container>
	);
};

export default PlanList;