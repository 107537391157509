import { createSlice } from '@reduxjs/toolkit';
import { getPlanList, addPlan, deletePlan, updatePlan, getEditPlan, getHospitalPlanList, addHospitalPlan, setDefaultPlan, getBilling, addPayment } from '../plan';

const initialState = {
	loading:false,
	planList: [],
	planData: [],
	isFetchPlanListSuccess: false,
	planListErrorMsg: '',

	isPlanAddedSuccess: false,
	planAddErrorMsg: '',
	planAddResponse: {},

	isPlanUpdateSuccess : false,
	updateSuccessMessage : '',
	updatePlanErrrorMsg : '',

	hospitalPlanList : [],
	hospitalPlanData : [],
	isFetchHospitalPlanListSuccess : false,
	hospitalPlanListErrorMsg : '',

	isHospitalPlanAddedSuccess : false,
	hospitalPlanAddErrorMsg : '',
	hospitalPlanAddResponse : {},

	isPlanDefaultSuccess	: false,
	planDefaultErrorMsg		: '',

	billingList : [],
	billingData: [],
	billingListErrorMsg : '',

	addPayment: {},
	addPaymentErrorMsg: '',
	isPaymentPaidSuccess: false,
};

const authSlice = createSlice({
	name: 'plan',
	initialState,
	reducers: {},
	extraReducers: {
		[getPlanList.pending]: (state) => {
			state.loading = true;
			state.planList = {};
			state.planData = [];
			state.isFetchPlanListSuccess = false;
			state.planListErrorMsg = '';
			state.isPlanUpdateSuccess = false;
			state.isPlanAddedSuccess = false;
			state.isPlanDefaultSuccess =false;
			state.planDefaultErrorMsg = '';
		},
		[getPlanList.fulfilled]: (state, action) => {
			state.loading = false;
			state.isFetchPlanListSuccess = true;
			state.planData = action.payload && action.payload.response.data.data;
			state.planList = action.payload && action.payload.response.data.data.data;
		},
		[getPlanList.rejected]: (state, err) => {
			state.loading = false;
			state.isFetchPlanListSuccess = false;
			state.planListErrorMsg = err.payload;
		},

		[addPlan.pending]: (state) => {
			state.loading = true;
			state.isPlanAddedSuccess = false;
			state.planAddErrorMsg = '';
			state.planAddResponse = {};
		},
		[addPlan.fulfilled]: (state, action) => {
			state.loading = false;
			state.planAddResponse = action.payload && action.payload.response.data;
			state.isPlanAddedSuccess = true;
		},
		[addPlan.rejected]: (state, err) => {
			state.loading = false;
			state.planAddErrorMsg = err.payload;
		},

		[deletePlan.fulfilled]: (state, { payload }) => {
			state.res = payload.response;
			state.isPlanDeletedSuccess = true;
		},
		[deletePlan.rejected]: (state, err) => {
			state.isPlanDeletedSuccess = false;
			state.planDeleteErrorMsg = err;
		},

		[updatePlan.fulfilled]: (state, { payload }) => {
			state.res = payload && payload.response;
			state.isPlanUpdateSuccess = true;
			state.updateSuccessMessage = payload.response.data.message;
			state.updatePlanErrrorMsg = '';
		},
		[updatePlan.rejected]: (state, err) => {
			state.isPlanUpdateSuccess = false;
			state.updatePlanErrrorMsg = err && err.payload && err.payload.message;
		},


		[getEditPlan.fulfilled]: (state, action) => {
			state.editPlanData = action.payload && action.payload.response.data.data;
			state.isFetchEditPlanSuccess = true;
		},
		[getEditPlan.rejected]: (state) => {
			state.isFetchPlanListSuccess = false;
			state.editPlanErrorMsg = '';
		},

		[getHospitalPlanList.pending]: (state) => {
			state.loading = true;
			state.hospitalPlanList = {};
			state.hospitalPlanData = [];
			state.isFetchHospitalPlanListSuccess = false;
			state.hospitalPlanListErrorMsg = '';
		},
		[getHospitalPlanList.fulfilled]: (state, action) => {
			state.loading = false;
			state.isFetchHospitalPlanListSuccess = true;
			state.hospitalPlanData = action.payload && action.payload.response.data.data;
			state.hospitalPlanList = action.payload && action.payload.response.data.data.data;
		},
		[getHospitalPlanList.rejected]: (state) => {
			state.loading = false;
			state.isFetchHospitalPlanListSuccess = false;
			state.hospitalPlanListErrorMsg = '';
		},

		[addHospitalPlan.pending]: (state) => {
			state.loading = true;
			state.isHospitalPlanAddedSuccess = false;
			state.hospitalPlanAddErrorMsg = '';
			state.hospitalPlanAddResponse = {};
		},
		[addHospitalPlan.fulfilled]: (state, action) => {
			state.loading = false;
			state.hospitalPlanAddResponse = action.payload && action.payload.response.data;
			state.isHospitalPlanAddedSuccess = true;
		},
		[addHospitalPlan.rejected]: (state, err) => {
			state.loading = false;
			state.isHospitalPlanAddedSuccess = false;
			state.hospitalPlanAddErrorMsg = err.payload;
		},

		[setDefaultPlan.fulfilled]: (state, { payload }) => {
			state.res = payload.response;
			state.isPlanDefaultSuccess = true;
		},
		[setDefaultPlan.rejected]: (state, err) => {
			state.isPlanDefaultSuccess = false;
			state.planDefaultErrorMsg = err;
		},

		[getBilling.pending]: (state) => {
			state.loading = true;
			state.billingList = [];
			state.billingData = [];
			state.billingListErrorMsg = '';
		},
		[getBilling.fulfilled]: (state, action) => {
			state.loading = false;
			state.billingData = action.payload && action.payload.response.data.data;
			state.billingList = action.payload && action.payload.response.data.data.data;
		},
		[getBilling.rejected]: (state) => {
			state.loading = false;
			state.isFetchHospitalPlanListSuccess = false;
			state.billingListErrorMsg = '';
		},

		[addPayment.pending]: (state) => {
			state.loading = true;
			state.isPaymentPaidSuccess = false;
			state.addPayment = {};
			state.addPaymentErrorMsg = '';
		},
		[addPayment.fulfilled]: (state, action) => {
			state.loading = false;
			state.isPaymentPaidSuccess = true;
			state.addPayment = action.payload && action.payload.response.data;
		},
		[addPayment.rejected]: (state, err) => {
			state.loading = false;
			state.isPaymentPaidSuccess = false;
			state.addPaymentErrorMsg = err.payload?.message;
		},
	},
});

export default authSlice.reducer;