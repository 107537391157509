import React from 'react';
import { t } from 'i18next';
import Typography from '../../components/Typography/index.js';
import { Container } from './styled.js';

const Dashboard = () => {

	return (
		<Container>
			<Typography
				variant="h4"
				weight="500"
				color="black"
			>
				{t('DASHBOARD.WLCOMETEXT')}
			</Typography>
		</Container>
	);
};

export default Dashboard;
