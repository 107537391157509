import styled from '@emotion/styled';

export const Wrapper = styled.div`
    width: 100%;
    background-color: ${(props) => props.theme.colors.light};
    border-radius: 25px;
    box-shadow: 0px 4px 5px 1px ${(props) =>  props.theme.colors.shadow}10;
    ${(props) => props.theme.breakPoint.mobile} {
        border-radius: 15px;
    }
`;

export const Forms = styled.div`
    padding: 16px;
    ${(props) =>  props .theme.breakPoint.mobile} {
        padding: 10px;
    }
`;

export const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding-bottom: 16px;
    border-bottom: 1px solid ${(props) => props.theme.colors.lightgrey};
    ${( props ) => props.theme.breakPoint.mobile} {
        padding-bottom: 10px;
    }
`;

export const Container = styled.div`
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    padding: 16px 0;
`;

export const InputField = styled.div`
    width: calc(100% / 2);
    margin: 6px 0 0 0px;
    position: relative;
    :nth-of-type(odd) {
        padding: 0px 12px 0 0;
    }
    ${( props ) => props.theme.breakPoint.mobile} {
        width: 100%;
        padding: 0px;
        :nth-of-type(odd) {
            padding: 0px 0px 0 0;
        }
    }
`;
