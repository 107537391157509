import styled from '@emotion/styled';

export const Layout = styled.div`
    background: ${(props) => props.theme.colors.primary};
    height: 100%;
    width: 100%;
    position: relative;
`;

export const SidebarConatiner = styled.div`
    position: fixed;
    z-index: 3;
    top: 0;
    left: 0;
    height: 100%;
    transition: all 0.3s ease-out 0s;
    width: ${({ isOpen }) => isOpen ? '80px' : '290px'};
    background: ${(props) => props.theme.colors.primary};
    svg {
        margin-right: ${({ isOpen }) => isOpen ? '0px' : '10px'};
    }
    span {
        white-space: nowrap;
        width: ${({ isOpen }) => isOpen ? '0' : 'auto'};
        text-align: ${({ isOpen }) => isOpen ? 'center' : 'start'};
        opacity: ${({ isOpen }) => isOpen ? '0' : '1'};
    }
    + .content {
        margin-left: ${({ isOpen }) => isOpen ? '80px' : '290px'};
    }
    ${(props) => props.theme.breakPoint.tabletLandscape} {
        width: ${({ isOpen }) => isOpen ? '290px' : '0px'};
        margin-left: ${({ isOpen }) => isOpen && '0px'};
        transition: all 0.3s;
        overflow: hidden;
        + .content {
            margin-left: ${({ isOpen }) => isOpen ? '290px' : '0px'};
        }
        svg {
            margin-right: ${({ isOpen }) => isOpen && '10px'};
        }
        span {
            width: ${({ isOpen }) => isOpen && 'calc(100% - 30px)'};
            opacity: ${({ isOpen }) => isOpen && '1'};
            white-space: ${({ isOpen }) => isOpen && 'break-spaces'};
            text-align: ${({ isOpen }) => isOpen && 'start'};
        }
        svg {
            margin-right: 10px;
        }
        span {
            width: calc(100% - 30px);
            opacity: 1;
        }
    }
    ${(props) => props.theme.breakPoint.mobileAndTablet} {
        width: ${({ isOpen }) => isOpen ? '290px' : '0px'};
        margin-left: ${({ isOpen }) => isOpen && '0px'};
        transition: all 0.3s;
        overflow: hidden;
        + .content {
            margin-left: ${({ isOpen }) => isOpen ? '290px' : '0px'};
        }
        svg {
            margin-right: ${({ isOpen }) => isOpen && '10px'};
        }
        span {
            width: ${({ isOpen }) => isOpen && 'calc(100% - 30px)'};
            opacity: ${({ isOpen }) => isOpen && '1'};
            white-space: ${({ isOpen }) => isOpen && 'break-spaces'};
            text-align: ${({ isOpen }) => isOpen && 'start'};
        }
        svg {
            margin-right: 10px;
        }
        span {
            width: calc(100% - 30px);
            opacity: 1;
        }
    }
    ${(props) => props.theme.breakPoint.mobile} {
        width: ${({ isOpen }) => isOpen ? '100%' : '0px'};
        position: ${({ isOpen }) => isOpen && 'fixed'};
        margin-left: ${({ isOpen }) => isOpen && '0px'};
        transition: all 0.3s ease-out;
        overflow: hidden;
        + .content {
            margin-left: ${({ isOpen }) => isOpen ? '100%': '0'};
        }
    }
`;

export const Conatiner = styled.div`
    transition: all 0.3s ease-out 0s;
    background-color: ${(props) => props.theme.colors.primaryLight};
    border-radius: ${(active) => active ? '25px 0 0 25px' : '25px'};
    height: 100%;
    overflow: auto;
    &.content {
        margin-left: ${({ isOpen }) => isOpen ? '80px' : '290px'};
    }
    ${(props) => props.theme.breakPoint.tabletLandscape}{
        border-radius: ${({ isOpen }) => isOpen ? '25px' : '25px 0 0 25px'};
        border-radius: ${(active) => active ? '25px' : '0px 0px 25px 25px'};
        &.content {
            margin-left: ${({ isOpen }) => isOpen ? '290px' : '0px'};
        }
    }
    ${(props) => props.theme.breakPoint.mobileAndTablet}{
        border-radius: ${({ isOpen }) => isOpen ? '25px' : '25px 0 0 25px'};
        border-radius: ${(active) => active ? '25px' : '25px 0 0 25px'};
        &.content {
            margin-left: ${({ isOpen }) => isOpen ? '290px' : '0px'};
        }
    }
    ${(props) => props.theme.breakPoint.mobile}{
        &.content {
            margin-left: ${({ isOpen }) => isOpen ? '100%' : '0px'};
        }
    }
`;

export const Content = styled.div`
    padding: 20px 30px;
    margin-top: ${({ changeStyle }) => changeStyle ? '74px' : '0px'};
    min-height: 100%;
    ${(props) => props.theme.breakPoint.mobile} {
        padding: 20px 16px;
        margin-top: ${({ changeStyle }) => changeStyle ? '74px' : '0px'};
    }
`;
