import React, { useState, useEffect } from 'react';
import { t } from 'i18next';
import { Formik, Form } from 'formik';
import { useParams, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Typography from '../../components/Typography';
import SelectDropdown from '../../components/SelectDropdown';
import Button from '../../components/Button';
import Input from '../../components/Input';
import { getEditDoctor, updateDoctor, getEditAssignedHospitalList, getHospitalList } from '../../redux/doctors';
import { editDoctorValidationSchema } from '../../utils/validation';
import {
	Wrapper,
	Header,
	Container,
	InputField,
	Forms
} from './styled';

const EditDoctor = () => {
	const { editDoctorData, doctorAssignedHospitalList, hospitalList, isDoctorUpdateSuccess, isFetchdoctorAssignHospitalListSuccess, isFetchhospitalListSuccess, isFetchEditDoctorSuccess, updateDoctorErrrorMsg } = useSelector(state => state.doctors);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const params = useParams();
	const editDoctorId = params.id;
	const [list, setList] = useState(['']);
	const [fname, setFname] = useState('');
	const [lname, setLname] = useState('');
	const [mobile, setMobile] = useState('');
	const [email, setEmail] = useState('');
	const [hospital, setHospital] = useState(['']);
	const [username, setUsername] = useState('');
	const [options, setOptions]= useState(['']);
	const [unAssignHospital, setUnAssignHospital] = useState(['']);
	const [assignHospital, setAssignHospital] = useState(['']);
	const [emailError, setEmailError] = useState('');
	const [usernameError, setUsernameError] = useState('');
	const [mobileError, setMobileError] = useState('');
	// const [roleError, setRoleError] = useState('');
	const [resErrors, setResErrors] = useState({});
	useEffect(() => {
		dispatch(getEditDoctor({ doctor_id: editDoctorId }));
	}, [editDoctorId]);

	useEffect(() => {
		if (isFetchEditDoctorSuccess) {
			setList(editDoctorData);
		}
	}, [isFetchEditDoctorSuccess]);

	useEffect(() => {
		if (list) {
			setFname(list.first_name);
			setLname(list.last_name);
			setMobile(list.mobile_number);
			setEmail(list.email);
			setUsername(list.username);
		}
	}, [list]);

	useEffect(() => {
		if (isFetchhospitalListSuccess) {
			let getOption =	hospitalList.map((item) => {
				return { value:item.id, label: item.name };
			});
			setUnAssignHospital(getOption);
		}
		dispatch(getHospitalList());

	}, [isFetchhospitalListSuccess]);

	useEffect(() => {
		dispatch(getEditAssignedHospitalList({ doctor_id:editDoctorId }));
	}, [editDoctorId]);

	useEffect(() => {
		if (isFetchdoctorAssignHospitalListSuccess){
			let getOption =  doctorAssignedHospitalList.map((item) => {
				return { value:item.id, label: item.name, isSelected: true };
			});
			setAssignHospital(getOption);
		}
	}, [isFetchdoctorAssignHospitalListSuccess, doctorAssignedHospitalList]);

	useEffect(() => {
		let totalOption = [...assignHospital, ...unAssignHospital];
		setOptions(totalOption.filter((item) => item != ''));
	}, [assignHospital, unAssignHospital]);

	useEffect(() => {
		let selectedHospital = options.filter((item) => item.isSelected);
		setHospital(selectedHospital);
	}, [options]);

	useEffect(() => {
		if (resErrors) {
			const message = resErrors.payload && resErrors.payload.message;
			const errArray = message && message.split('|');
			let emailError = errArray && errArray.indexOf(t('ERRORS.EMAIL_ALREADY_TAKEN'));
			(emailError !== -1 || undefined) && setEmailError(errArray && errArray[emailError]);
			let usernameError = errArray && errArray.indexOf(t('ERRORS.USERNAME_ALREADY_TAKEN'));
			(usernameError !== -1 || undefined) && setUsernameError(errArray && errArray[usernameError]);
			let mobileError = errArray && errArray.indexOf(t('ERRORS.MOBILE_ALREADY_TAKEN'));
			(mobileError !== -1 || undefined) && setMobileError(errArray && errArray[mobileError]);
			/* let roleError = errArray && errArray.indexOf(t('ERRORS.EMPTY_USER_ROLE'));
			(roleError !== -1 || undefined) && setRoleError(errArray && errArray[roleError]); */
		}
	}, [resErrors]);

	useEffect(() => {
		if (isDoctorUpdateSuccess){
			navigate('/dr-management/list');
		}
		else if (updateDoctorErrrorMsg){
			setResErrors(updateDoctorErrrorMsg);
		}
	}, [isDoctorUpdateSuccess, updateDoctorErrrorMsg]);


	const handleDoctorEdit = async({ fname, lname, mobile, email, username, hospital }) => {
		const body = {
			user_id: list.id,
			first_name: fname,
			last_name: lname,
			mobile_number: mobile,
			email: email,
			username: username,
			hospital_id:hospital.map((item => item.value))
		};
		dispatch(updateDoctor({ body }));
	};
	return (
		<Wrapper>
			<Forms>
				<Header>
					<Typography
						variant="h4"
						color="black"
						weight="500"
					>
						{t('EDITDOCTOR.HEADING')}
					</Typography>
					<Button
						title={t('EDITDOCTOR.BACKBUTTON')}
						onClick={() => navigate('/dr-management/list')}
						size={'xs'}
					>
						{t('EDITDOCTOR.BACKBUTTON')}
					</Button>
				</Header>
				<Formik
					initialValues={{
						fname,
						lname,
						mobile,
						email,
						username,
						hospital
					}}
					validationSchema={editDoctorValidationSchema(t)}
					onSubmit={handleDoctorEdit}
					enableReinitialize={true}
				>
					{({ errors, values, touched, handleChange, handleBlur }) => {
						return (
							<Form>
								<Container>
									<InputField>
										<Input
											name='fname'
											placeholder={t('ADDDOCTOR.PLACEHOLDER.FIRST_NAME')}
											type='text'
											onChange={handleChange}
											onBlur={handleBlur}
											error={touched.fname && 1}
											errormessage={errors.fname}
											value={values.fname} />
									</InputField>
									<InputField>
										<Input
											name='lname'
											placeholder={t('ADDDOCTOR.PLACEHOLDER.LAST_NAME')}
											type='text'
											onChange={handleChange}
											onBlur={handleBlur}
											error={touched.lname && 1}
											errormessage={errors.lname}
											value={values.lname} />
									</InputField>
									<InputField>
										<Input
											name='mobile'
											placeholder={t('ADDDOCTOR.PLACEHOLDER.MOBILE_NO')}
											type='number'
											onChange={handleChange}
											onBlur={handleBlur}
											error={touched.mobile && 1}
											errormessage={mobileError && mobileError.length ? mobileError : errors.mobile}
											value={values.mobile} />
									</InputField>
									<InputField>
										<Input
											name='email'
											placeholder={t('ADDDOCTOR.PLACEHOLDER.EMAIL')}
											type='email'
											onChange={handleChange}
											onBlur={handleBlur}
											error={touched.email && 1}
											errormessage={emailError && emailError.length ? emailError : errors.email}
											value={values.email} />
									</InputField>
									<InputField>
										<Input
											disabled
											name="username"
											placeholder={t('ADDDOCTOR.PLACEHOLDER.USER_NAME')}
											type='text'
											onChange={handleChange}
											onBlur={handleBlur}
											error={touched.username && 1}
											errormessage={usernameError && usernameError.length ? usernameError : errors.username}
											value={values.username} />
									</InputField>
									<InputField>
										<SelectDropdown
											name='hospital'
											options={options}
											value={values.hospital}
											isMulti={true}
											placeholder={t('ADDDOCTOR.PLACEHOLDER.SELECTHOSPITAL')}
											onBlur={handleBlur}
											onChange={(event) => setHospital(event)}
											error={values.hospital.length < 1 ? true : false}
											errormessage={/* roleError && roleError.length ? roleError :  */errors.hospital} />
									</InputField>
								</Container>
								<Button title={t('EDITDOCTOR.BTNTEXT')} type='submit' size={'sm'}>{t('EDITDOCTOR.BTNTEXT')}</Button>
							</Form>
						);}
					}
				</Formik>
			</Forms>
		</Wrapper>
	);
};

export default EditDoctor;