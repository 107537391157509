import React, { useEffect, useState } from 'react';
import { t } from 'i18next';
import ReactPaginate from 'react-paginate';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { HiChevronDoubleLeft, HiChevronDoubleRight } from 'react-icons/hi';
import { AiOutlineEye } from 'react-icons/ai';
import { Formik, Form } from 'formik';
import find from 'lodash/find';
import Button from '../../components/Button';
import Typography from '../../components/Typography';
import Input from '../../components/Input';
import Table from '../../components/Table';
import SimpleTable from '../../components/SimpleTable';
import PageTitle from '../../components/PageTitle';
import useDebounce from '../../utils/useDebounceHook';
import { addHospitalPlan, getHospitalPlanList, getPlanList } from '../../redux/plan';
import {
	Buttons,
	Footer,
	TableContent,
	Header,
	Modal,
	Overlay,
	Container,
	Content,
	SearchInput,
	InputContainer,
	InputField,
	Modal2,
	ButtonsAction,
} from './styled';
import SelectDropdown from '../../components/SelectDropdown';
import { subscribePlanValidationSchema } from '../../utils/validation';


const HospitalPlanList = () => {
	const TableHeadings = ['ID', 'Plan ID', 'Plan Name', 'Status', 'Expire At', ''];
	const TableFields = ['id', 'unique_id', 'plan_name', 'status', 'expire_at'];
	const TableActions = [
		{
			'name': 'preview',
			'handleClick': (item) => handleView(item.id),
			'size': 'xs',
			'variant': 'warning',
			'title': 'Preview',
			'text': <AiOutlineEye />
		}
	];
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const params = useParams();
	const [plans, setPlans] = useState([]);
	const { hospitalPlanList, hospitalPlanData, planData, isHospitalPlanAddedSuccess, hospitalPlanAddErrorMsg } = useSelector(state => state.plan);
	const [subscribeError, setSubscribeError] = useState('');
	const [currentItems, setCurrentItems] = useState([]);
	const [pageCount, setPageCount] = useState(0);
	const [searchUserName, setSearchUserName] = useState('');
	const [planDetail, setPlanDetail] = useState({ show: false, id: null });
	const [addPlan, setAddPlan] = useState({ show: false });
	const debouncedSearchTerm = useDebounce(searchUserName, 500);
	const [page, setPage] = useState(1);

	useEffect(() => {
		dispatch(getPlanList({ all: 1, status: 'all' }));
	}, []);

	useEffect(() => {
		if (planData?.length) {
			setPlans(planData.map((plan) => ({
				value: plan.id,
				label: plan.plan_name
			})));
		}
	}, [planData]);

	useEffect(() => {
		if (hospitalPlanList.length) {
			let newFormList = hospitalPlanList.map((element) => {
				return ({ ...element });
			});
			setCurrentItems(newFormList);
			hospitalPlanList.length ? setPageCount(hospitalPlanData.last_page) : setPageCount(0);
		}
		else {
			setCurrentItems([]);
			setPageCount(0);
		}
	}, [hospitalPlanList]);

	useEffect(() => {
		if (debouncedSearchTerm !== '') {
			dispatch(getHospitalPlanList({ page: page, hospital_id: params.id, search: debouncedSearchTerm }));
		}
		else {
			dispatch(getHospitalPlanList({ page: page, hospital_id: params.id }));
		}
	}, [page, debouncedSearchTerm]);

	useEffect(() => {
		if (isHospitalPlanAddedSuccess) {
			setAddPlan(false);
			dispatch(getHospitalPlanList({ page: page, hospital_id: params.id, search: debouncedSearchTerm }));
		}
		if (hospitalPlanAddErrorMsg && hospitalPlanAddErrorMsg.error) {
			setSubscribeError(hospitalPlanAddErrorMsg.message);
		}
	}, [isHospitalPlanAddedSuccess, hospitalPlanAddErrorMsg]);

	const handleView = (id) => {
		const plan = find(currentItems, { id: id });
		setPlanDetail({
			show: true,
			id: id,
			unique_id: plan.unique_id,
			plan: plan.plan_name,
			cost: plan.cost + ' ' + plan.currency,
			days: plan.days,
			beds: plan.beds,
			type: plan.type,
			status: plan.status,
			start_at: plan.start_at,
			expire_at: plan.expire_at,
		});
	};

	const handlePageClick = (event) => {
		const pageNum = event.selected + 1;
		setPage(pageNum);
	};

	const handleSearchByName = (event) => {
		const inputVal = event.target.value;
		setSearchUserName(inputVal);
		setPage(1);
	};
	const handleSubscribePlan = async({ planId }) => {
		dispatch(addHospitalPlan({ body: { plan_id: planId.value, hospital_id: params.id }}));
	};
	return (
		<Container>
			<Content>
				<Header>
					<Typography
						variant="h4"
						color="black"
						weight="500"
					>
						{t('HOSPITALPLANLIST.HEADING')}
					</Typography>
				</Header>
				<SearchInput>
					<InputContainer>
						<Input placeholder={t('HOSPITALPLANLIST.PLACEHOLDER.INPUT')} onChange={handleSearchByName} value={searchUserName} />
					</InputContainer>
					<Button title={t('HOSPITALPLANLIST.BTNADD')} onClick={() => {
						setAddPlan({ show: true });
						setSubscribeError('');
					}
					} size={'xs'}>
						{t('HOSPITALPLANLIST.BTNADD')}
					</Button>
					<Button title={t('EDITHOSPITAL.BACKBUTTON')}
						onClick={() => navigate('/hospital-management/list')}
						size={'xs'}
					>
						{t('EDITHOSPITAL.BACKBUTTON')}
					</Button>
				</SearchInput>
				<TableContent>
					<Table tableHeading={TableHeadings} tableFields={TableFields} tableData={currentItems} tableActions={TableActions} />
					<Footer>
						<ReactPaginate
							breakLabel="..."
							nextLabel={<HiChevronDoubleRight />}
							onPageChange={handlePageClick}
							pageRangeDisplayed={5}
							pageCount={pageCount}
							previousLabel={<HiChevronDoubleLeft />}
							forcePage={pageCount === 0 ? -1 : page - 1}
						/>
					</Footer>
				</TableContent>
			</Content>
			{
				addPlan.show ?
					<Overlay>
						<Modal2>
							<Typography variant="h4" color="black">
								{t('HOSPITALPLANLIST.SUBSCRIBEPLAN')}
							</Typography>
							<Formik initialValues={{ planId: '' }} validationSchema={subscribePlanValidationSchema(t)} onSubmit={handleSubscribePlan}>
								{({ errors, touched, handleBlur, setFieldValue }) => (
									<Form>
										<Container>
											<InputField>
												<SelectDropdown
													name="planId"
													options={plans}
													placeholder={t('HOSPITALPLANLIST.PLACEHOLDER.PLAN')}
													onBlur={handleBlur}
													onChange={(e) => setFieldValue('planId', e)}
													error={touched.planId || subscribeError}
													errormessage={errors.planId || subscribeError}
												/>
											</InputField>
										</Container>
										<ButtonsAction>
											<Button title={t('HOSPITALPLANLIST.BTNSUBSCRIBE')} type='submit' size={'sm'}>{t('HOSPITALPLANLIST.BTNSUBSCRIBE')}</Button>
											<Button title={t('HOSPITALPLANLIST.BTNNO')} variant={'danger'} onClick={() => setAddPlan(false)} size={'sm'}>{t('HOSPITALPLANLIST.BTNNO')}</Button>
										</ButtonsAction>
									</Form>
								)}
							</Formik>
						</Modal2>
					</Overlay>
					: null
			}
			{
				planDetail.show ?
					<Overlay>
						<Modal>
							<PageTitle variant="h4" color="black">
								{t('HOSPITALPLANLIST.DETAIL')}
							</PageTitle>
							<SimpleTable tableFields={['name', 'value']} tableData={[
								{ 'name': 'Unique ID', 'value': planDetail.unique_id },
								{ 'name': 'Plan Name', 'value': planDetail.plan },
								{ 'name': 'Cost', 'value': planDetail.cost },
								{ 'name': 'Days', 'value': planDetail.days },
								{ 'name': 'Connect', 'value': planDetail.beds },
								{ 'name': 'Type', 'value': planDetail.type },
								{ 'name': 'Status', 'value': planDetail.status },
								{ 'name': 'Start At', 'value': planDetail.start_at },
								{ 'name': 'Expire At', 'value': planDetail.expire_at },
							]} ></SimpleTable>
							<Buttons>
								<Button style={{ marginTop: '10px' }} variant={'danger'} title={t('HOSPITALPLANLIST.BTNNO')} onClick={() => setPlanDetail(false)} size={'sm'}>{t('HOSPITALPLANLIST.BTNNO')}</Button>
							</Buttons>
						</Modal>
					</Overlay>
					: null
			}
		</Container >
	);
};

export default HospitalPlanList;