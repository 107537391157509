import { createSlice } from '@reduxjs/toolkit';
import { getHospitalList, addDoctor, getDoctors, getAssignHospitalList, updateDoctor, deleteDoctor, getEditAssignedHospitalList, getEditDoctor, forgetDoctorPassword } from '../doctors';

const initialState = {
	doctorList: [],
	doctorData: [],
	isFetchDoctorListSuccess: false,
	doctorListErrorMsg: '',
	isDoctorAddedSuccess: false,
	doctorAddErrorMsg: '',
	isDoctorUpdateSuccess: false,
	updateDoctorErrrorMsg: '',
	updateSuccessMessage:'',
	isDoctorDeletedSuccess: false,
	doctorDeleteErrorMsg: '',
	assignHospitalList: [],
	isFectchAssignHospitalListSuccess: false,
	assignHospitalListErrorMsg: '',
	hospitalList: [],
	isFetchhospitalListSuccess: false,
	hospitalListErrorMsg: '',
	res: [],
	doctorAssignedHospitalList:[],
	isFetchdoctorAssignHospitalListSuccess: false,
	doctorAssignHospitalErrorMsg: '',
	editDoctorData: [],
	isFetchEditDoctorSuccess: false,
	editDoctorErrorMsg:'',
	loading:false,
	resetPasswordResponse: {},
	resetPasswordErrorMsg: '',
};

const authSlice = createSlice({
	name: 'doctors',
	initialState,
	reducers: {},
	extraReducers: {
		[getDoctors.fulfilled]: (state, action) => {
			state.doctorData = action.payload && action.payload.response.data.data;
			state.doctorList = action.payload && action.payload.response.data.data.data;
			state.isFetchDoctorListSuccess = true;
			state.isDoctorUpdateSuccess = false;
			state.isFetchdoctorAssignHospitalListSuccess = false;
			state.isDoctorAddedSuccess = false;
			state.isFetchEditDoctorSuccess = false;
		},
		[getDoctors.rejected]: (state) => {
			state.isFetchDoctorListSuccess = false;
			state.doctorListErrorMsg = '';
		},
		[addDoctor.fulfilled]: (state, { payload }) => {
			state.res = payload.response;
			state.isDoctorAddedSuccess = true;
		},
		[addDoctor.rejected]: (state, err) => {
			state.isDoctorAddedSuccess = false;
			state.doctorAddErrorMsg = err;
		},
		[updateDoctor.fulfilled]: (state, { payload }) => {
			state.res = payload && payload.response;
			state.isDoctorUpdateSuccess = true;
			state.updateSuccessMessage = payload.response.data.message;
			state.updateDoctorErrrorMsg = '';
		},
		[updateDoctor.rejected]: (state, err) => {
			state.isDoctorUpdateSuccess = false;
			state.updateDoctorErrrorMsg = err /* && err.payload && err.payload.message */;
		},
		[deleteDoctor.fulfilled]: (state, { payload }) => {
			state.res = payload.response;
			state.isDoctorDeletedSuccess = true;
		},
		[deleteDoctor.rejected]: (state, err) => {
			state.isDoctorDeletedSuccess = false;
			state.doctorDeleteErrorMsg = err;
		},
		/* [filterDoctor.fulfilled]: (state, action) => {
			state.isFetchSearchDoctoListSuccess = true;
			state.searchDoctorList = action.payload && action.payload.response.data.data.data;
		},
		[filterDoctor.rejected]: (state) => {
			state.isFetchSearchDoctoListSuccess = false;
			state.searchDoctorListErrorMsg = '';
		}, */
		[getHospitalList.fulfilled]: (state, action) => {
			state.hospitalList = action.payload && action.payload.response.data.data;
			state.isFetchhospitalListSuccess = true;
		},
		[getHospitalList.rejected]: (state) => {
			state.isFetchhospitalListSuccess = false;
			state.hospitalListErrorMsg = '';
		},
		[getAssignHospitalList.fulfilled]: (state, action) => {
			state.assignHospitalList = action.payload && action.payload.response.data.data;
			state.isFectchAssignHospitalListSuccess = true;
		},
		[getAssignHospitalList.rejected]: (state) => {
			state.isFectchAssignHospitalListSuccess = false;
			state.assignHospitalListErrorMsg = '';
		},
		[getEditAssignedHospitalList.fulfilled]: (state, action) => {
			state.doctorAssignedHospitalList = action.payload && action.payload.response.data.data;
			state.isFetchdoctorAssignHospitalListSuccess = true;
		},
		[getEditAssignedHospitalList.rejected]: (state) => {
			state.isFetchdoctorAssignHospitalListSuccess = false;
			state.doctorAssignHospitalErrorMsg = '';
		},
		[getEditDoctor.fulfilled]: (state, action) => {
			state.editDoctorData = action.payload && action.payload.response.data.data;
			state.isFetchEditDoctorSuccess = true;
		},
		[getEditDoctor.rejected]: (state) => {
			state.isFetchDoctorListSuccess = false;
			state.editDoctorErrorMsg = '';
		},
		[forgetDoctorPassword.pending]: (state) => {
			state.loading = true;
			state.resetPasswordResponse = {};
			state.resetPasswordErrorMsg = '';
		},
		[forgetDoctorPassword.fulfilled]: (state, action) => {
			state.loading = false;
			state.resetPasswordResponse = action.payload && action.payload.response.data;
		},
		[forgetDoctorPassword.rejected]: (state, err) => {
			state.loading = false;
			state.resetPasswordErrorMsg = err.payload;
		},
	},
});

export default authSlice.reducer;