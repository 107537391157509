import styled from '@emotion/styled';

export const Container = styled.div`
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    ${(props) => props.theme.breakPoint.mobile} {
        padding: 16px;
    }
`;

export const Wrapper = styled.div`
    padding: 40px 60px;
    border-radius: 10px;
    background: ${(props) => props.theme.colors.light};
    max-width: 570px;
    width: 100%;
    ${(props) => props.theme.breakPoint.mobile} {
        margin: 15px;
        padding: 40px 20px;
    }
`;

export const Forms = styled.div`
    margin-top: 30px;
`;

export const PasswordWrapper = styled.div`
	display: flex;
	align-items: center;
	position: relative;
	margin-bottom:30px;
	.input {
		height: 48px !important;
		border-radius: 10px 0 0px 10px !important;
	}
	> div {
		margin-bottom: 0;
	}
	button.button {
		border-radius: 0 10px 10px 0;
		width: 60px;
		min-width: unset;
		display: flex;
		align-items: center;
	}
	p {
		position: absolute;
		bottom: -22px;
	}
`;