import styled from '@emotion/styled';

export const Card = styled.div({}, (props) => {
	const typeColor = getButtonTypeColor(props);
	return {
		width: props.size,
		height: 'auto',
		boxShadow: 'rgba(175, 175, 175, 0.1) 0px 4px 5px 1px',
		borderRadius: '25px',
		background: typeColor.default,
		[props.theme.breakPoint.mobile]: {
			width: props.mobileSize
		},
	};
});

export const CardBody = styled.div`
	padding: 20px;
	display: flex;
	align-items: center;
	justify-content: space-between;
`;

export const IconContainer = styled.div({}, (props) => {
	const typeColor = getButtonTypeColor(props);
	return {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		borderRadius: '50%',
		width: '56px',
		height: '56px',
		marginRight: '20px',
		background: typeColor.icon,
		color: typeColor.iconColor,
	};
});

export const IconWrapper = styled.div`
	display: flex;
	width: 28px;
	align-items: center;
	justify-content: center;
	height: 28px;
	border-radius: 50%;
`;

export const TextWrapper = styled.div({}, (props) => {
	const typeColor = getButtonTypeColor(props);
	return {
		color: typeColor.textColor,
	};
});

export const Stat = styled.div`
	font-size: 2.300em;
	font-weight: 500;
	letter-spacing: 1px;
	margin-bottom: 8px;
	text-align: end;
`;

export const Title = styled.p`
	font-size: 1.200em;
`;

const getButtonTypeColor = (props) => {
	return props.partner
		? props.theme.partner.statCards[props.variant]
		: props.theme.statCards[props.variant];
};
