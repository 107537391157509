import { createSlice } from '@reduxjs/toolkit';
import { getHospital, updateHospital, addHospital, deleteHospital, detailHospital, updateStatusHospital, getPatientDetails } from '../hospital';

const initialState = {
	loading : false,
	hospitalList: [],
	hospitalData: [],
	hospitalErrorMessage: '',

	isHospitalUpdateSuccess: false,
	updateHospitalErrorMessage: '',

	isHospitalAddedSuccess: false,
	addHospitalErrorMessage: '',

	isHospitalDeletedSuccess: false,
	isHospitalDeleteErrorMessage: '',

	isHospitalStatusChangeSuccess: false,
	hospitalStatusChangeErrorMessage : '',

	hospitalDetail: [],

	patientList : {},
	patientListErrorMessage : '',
};

const hospitalSlice = createSlice({
	name: 'hospital',
	initialState,
	reducers: {},
	extraReducers: {
		[getHospital.fulfilled]: (state, action) => {
			state.isHospitalUpdateSuccess = false;
			state.isHospitalDeletedSuccess = false;
			state.isHospitalAddedSuccess = false;
			state.isHospitalStatusChangeSuccess = false;
			state.hospitalData = action.payload?.response.data.data;
			state.hospitalList = action.payload?.response.data.data.data;
			state.hospitalDetail = [];
		},
		[getHospital.rejected]: (state) => {
			state.hospitalErrorMessage = '';
		},

		[detailHospital.fulfilled]: (state, action) => {
			state.hospitalDetail = action.payload?.response.data.data;
		},
		[detailHospital.rejected]: (state, err) => {
			state.hospitalDetailErrorMessage = err?.payload?.message;
		},

		[updateHospital.fulfilled]: (state) => {
			state.isHospitalUpdateSuccess = true;
		},
		[updateHospital.rejected]: (state, err) => {
			state.updateHospitalErrorMessage = err?.payload?.message;
		},

		[addHospital.pending]: (state) => {
			state.loading = true;
		},
		[addHospital.fulfilled]: (state) => {
			state.loading = false;
			state.isHospitalAddedSuccess = true;
		},
		[addHospital.rejected]: (state, err) => {
			state.loading = false;
			state.addHospitalErrorMessage = err;
		},

		[deleteHospital.fulfilled]: (state, { payload }) => {
			state.isHospitalDeletedSuccess = true;
			state.res = payload.response;
		},
		[deleteHospital.rejected]: (state, err) => {
			state.isHospitalDeleteErrorMessage = err;
		},

		[updateStatusHospital.fulfilled]: (state) => {
			state.isHospitalStatusChangeSuccess = true;
		},
		[updateStatusHospital.rejected]: (state, err) => {
			state.isHospitalStatusChangeSuccess = false;
			state.hospitalStatusChangeErrorMessage = err?.payload?.message;
		},

		[getPatientDetails.fulfilled]: (state, action) => {
			state.patientList = action.payload?.response.data.data;
		},
		[getPatientDetails.rejected]: (state, err) => {
			state.patientListErrorMessage = err?.payload?.message;
		},
	},
});

export default hospitalSlice.reducer;