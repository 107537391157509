/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { array } from 'prop-types';
import { useSelector } from 'react-redux';
import { t } from 'i18next';
import SelectDropdown from '../SelectDropdown';
import Input from '../Input';
import Button from '../Button';
import { getFormList } from '../../redux/form';
import { InputField } from '../../pages/create-form/styled.js';
import { Container, Label, Wrapper } from './styled.js';

const FormFieldRow = ({ rowsData, deleteTableRows, handleRowChange, dispatch, setRowsData, hospitalId }) => {
	const defaultRow = {
		'showFetchOldata': false,
		'showFormData': false,
		'showThemeType': false,
		'showTimerType': false,
		'showSubTitle': false,
		'showFetchField': false,
		// 'allow_edit': true
	};
	const options = [
		{
			label: 'Input Types',
			options: [
				{ value: 'text', label: 'Text' },
				{ value: 'textarea', label: 'Textarea' },
				{ value: 'radio', label: 'Radio' },
				{ value: 'select', label: 'Select' },
				{ value: 'checkbox', label: 'Checkbox' },
				{ value: 'date', label: 'Date' },
				{ value: 'datetime', label: 'Datetime' },
				{ value: 'time', label: 'Time' },
				{ value: 'number', label: 'Number' },
				{ value: 'file', label: 'File' },
				{ value: 'lab-report-values', label: 'Lab Report Value' }
			]
		},
		{
			label: 'Display Types',
			options: [
				{ value: 'label', label: 'Label' },
				{ value: 'note', label: 'Note' },
				{ value: 'separator', label: 'Separator' },
				{ value: 'formdata', label: 'Form Data' },
				{ value: 'subform', label: 'Sub Form' },
				{ value: 'regno', label: 'Reg No' }
			]
		},

	];
	const options1 = [
		{ value: 'text', label: 'Text' },
		{ value: 'textarea', label: 'Textarea' },
		{ value: 'radio', label: 'Radio' },
		{ value: 'select', label: 'Select' },
		{ value: 'checkbox', label: 'Checkbox' },
		{ value: 'date', label: 'Date' },
		{ value: 'datetime', label: 'Datetime' },
		{ value: 'time', label: 'Time' },
		{ value: 'number', label: 'Number' },
		{ value: 'file', label: 'File' },
		{ value: 'lab-report-values', label: 'Lab Report Value' },
		{ value: 'label', label: 'Label' },
		{ value: 'note', label: 'Note' },
		{ value: 'separator', label: 'Separator' },
		{ value: 'formdata', label: 'Form Data' },
		{ value: 'subform', label: 'Sub Form' },
		{ value: 'regno', label: 'Reg No' }
	];
	const fetchOldDataOptions = [
		{ value: 0, label: 'No' },
		{ value: 1, label: 'Yes' },
	];
	const themeTypeOptions = [
		{ value: 0, label: 'Simple' },
		{ value: 1, label: 'Editor (WYSIWYG)' },
	];
	const requiredOptions = [
		{ value: 0, label: 'No' },
		{ value: 1, label: 'Yes' },
	];
	const autoCompleteOptions = [
		{ value: 0, label: 'No' },
		{ value: 1, label: 'Yes' },
	];
	const oneTimeFieldOptions = [
		{ value: 0, label: 'No' },
		{ value: 1, label: 'Yes' },
	];
	const timerTypeOptions = [
		{ value: 0, label: 'None' },
		{ value: 1, label: 'Timer' },
		{ value: 2, label: 'Time Submission' },
	];
	const { formListAll } = useSelector(state => state.form);
	const [formDataOptions, setFormDataOptions] = useState([]);
	const addTableRows = (insertAt) => {
		const updatedFields = [...rowsData.slice(0, insertAt), defaultRow, ...rowsData.slice(insertAt)];
		setRowsData(updatedFields);
	};
	useEffect(() => {
		hospitalId?dispatch(getFormList({ form_id: 0, 'hospital_id':hospitalId })):dispatch(getFormList({ form_id: 0 }));
	}, []);
	useEffect(() => {
		if (formListAll.length > 0) {
			const formDataOptions = formListAll.map((item) => {
				return { value: item.id, label: item.form_name };
			});
			setFormDataOptions(formDataOptions);
		}
	}, [formListAll]);
	return (
		<>
			{rowsData.map((data, index) => {
				const {
					showFetchOldata,
					showFormData,
					showThemeType,
					showTimerType,
					showSubTitle,
					showFetchField
				} = data;
				return (
					<Container key={index}>
						<Wrapper key={index}>
							<InputField>
								<Label>
									{t('ADDFORM.PLACEHOLDER.TITLE')}
								</Label>
								<Input
									placeholder={t('ADDFORM.PLACEHOLDER.TITLE')}
									name='title'
									type='text'
									value={data.title || ''}
									onChange={(event) => (handleRowChange(index, 'title', event.target.value))}
								/>
							</InputField>
							<InputField>
								<Label>
									{t('ADDFORM.PLACEHOLDER.TYPE')}
								</Label>
								<SelectDropdown
									options={options}
									placeholder={t('ADDFORM.PLACEHOLDER.TYPE')}
									name='type'
									value={options1.find(option => option.value === data.type)  || ''}
									onChange={(event) => (handleRowChange(index, 'type', event.value))}
								/>
							</InputField>
							{showFetchOldata && <InputField>
								<Label>
									{t('ADDFORM.PLACEHOLDER.FETCH_OLD_DATA')}
								</Label>
								<SelectDropdown
									options={fetchOldDataOptions}
									placeholder={t('ADDFORM.PLACEHOLDER.FETCH_OLD_DATA')}
									name='fetch_old_data'
									value={fetchOldDataOptions.find(option => option.value === data.fetch_old_data) || ''}
									onChange={(event) => (handleRowChange(index, 'fetch_old_data', event.value))}
								/>
							</InputField>}
							{showFormData && <InputField>
								<Label>
									{t('ADDFORM.PLACEHOLDER.FORM_DATA_ID')}
								</Label>
								<SelectDropdown
									options={formDataOptions}
									placeholder={t('ADDFORM.PLACEHOLDER.FORM_DATA_ID')}
									name='formdata_id'
									value={formDataOptions.find(option => option.value === data.formdata_id) || ''}
									onChange={(event) => (handleRowChange(index, 'formdata_id', event.value))}
								/>
							</InputField>}
							{showFetchField && <InputField>
								<Label>
									{t('ADDFORM.PLACEHOLDER.FETCH_FIELD')}
								</Label>
								<Input
									placeholder={t('ADDFORM.PLACEHOLDER.FETCH_FIELD')}
									name='fetch_field'
									type='text'
									value={data.fetch_field || ''}
									onChange={(event) => (handleRowChange(index, 'fetch_field', event.target.value))}
								/>
							</InputField>}
							{showThemeType && <InputField>
								<Label>
									{t('ADDFORM.PLACEHOLDER.THEME_TYPE')}
								</Label>
								<SelectDropdown
									options={themeTypeOptions}
									placeholder={t('ADDFORM.PLACEHOLDER.THEME_TYPE')}
									name='theme_type'
									value={themeTypeOptions.find(option => option.value === data.theme_type) || ''}
									onChange={(event) => (handleRowChange(index, 'theme_type', event.value))}
								/>
							</InputField>}
							{showTimerType && <InputField>
								<Label>
									{t('ADDFORM.PLACEHOLDER.TIMER_TYPE')}
								</Label>
								<SelectDropdown
									options={timerTypeOptions}
									placeholder={t('ADDFORM.PLACEHOLDER.TIMER_TYPE')}
									name='timer_type'
									value={timerTypeOptions.find(option => option.value === data.timer_type) || ''}
									onChange={(event) => (handleRowChange(index, 'timer_type', event.value))}
								/>
							</InputField>}
							{showSubTitle && <InputField>
								<Label>
									{t('ADDFORM.PLACEHOLDER.SUB_TITLE')}
								</Label>
								<Input
									placeholder={t('ADDFORM.PLACEHOLDER.SUB_TITLE')}
									name='sub_title'
									type='text'
									value={data.sub_title || ''}
									onChange={(event) => (handleRowChange(index, 'sub_title', event.target.value))}
								/>
							</InputField>}
							<InputField>
								<Label>
									{t('ADDFORM.PLACEHOLDER.DEFAULT_VALUE')}
								</Label>
								<Input
									placeholder={t('ADDFORM.PLACEHOLDER.DEFAULT_VALUE')}
									name='default_values'
									type='text'
									value={data.default_values || ''}
									onChange={(event) => (handleRowChange(index, 'default_values', event.target.value))}
								/>
							</InputField>
							<InputField>
								<Label>
									{t('ADDFORM.PLACEHOLDER.POSSIBLE_VALUES')}
								</Label>
								<Input
									placeholder={t('ADDFORM.PLACEHOLDER.POSSIBLE_VALUES')}
									name='possible_values'
									type='text'
									value={data.possible_values || ''}
									onChange={(event) => (handleRowChange(index, 'possible_values', event.target.value))}
								/>
							</InputField>
							<InputField>
								<Label>
									{t('ADDFORM.PLACEHOLDER.PLACEHOLDER')}
								</Label>
								<Input
									placeholder={t('ADDFORM.PLACEHOLDER.PLACEHOLDER')}
									name='placeholder'
									type='text'
									value={data.placeholder || ''}
									onChange={(event) => (handleRowChange(index, 'placeholder', event.target.value))}
								/>
							</InputField>
							<InputField>
								<Label>
									{t('ADDFORM.PLACEHOLDER.REQUIRED')}
								</Label>
								<SelectDropdown
									options={requiredOptions}
									placeholder={t('ADDFORM.PLACEHOLDER.REQUIRED')}
									name='required'
									value={requiredOptions.find(option => option.value === data.required) || ''}
									onChange={(event) => (handleRowChange(index, 'required', event.value))}
								/>
							</InputField>
							<InputField>
								<Label>
									{t('ADDFORM.PLACEHOLDER.AUTOCOMPLETE')}
								</Label>
								<SelectDropdown
									options={autoCompleteOptions}
									placeholder={t('ADDFORM.PLACEHOLDER.AUTOCOMPLETE')}
									name='autocomplete'
									value={autoCompleteOptions.find(option => option.value === data.autocomplete) || ''}
									onChange={(event) => (handleRowChange(index, 'autocomplete', event.value))}
								/>
							</InputField>
							<InputField>
								<Label>
									{t('ADDFORM.PLACEHOLDER.ONETIME')}
								</Label>
								<SelectDropdown
									options={oneTimeFieldOptions}
									placeholder={t('ADDFORM.PLACEHOLDER.ONETIME')}
									name='one_time_field'
									value={oneTimeFieldOptions.find(option => option.value === data.one_time_field) || ''}
									onChange={(event) => (handleRowChange(index, 'one_time_field', event.value))}
								/>
							</InputField>
							<InputField>
								<Label>
									{t('ADDFORM.PLACEHOLDER.FILED_RULE')}
								</Label>
								<Input
									placeholder={t('ADDFORM.PLACEHOLDER.FILED_RULE')}
									name='field_rule'
									type='text'
									value={data.field_rule  || ''}
									onChange={(event) => (handleRowChange(index, 'field_rule', event.target.value))}
								/>
							</InputField>

							<InputField>
								<Label>
									{t('ADDFORM.PLACEHOLDER.FILES')}
								</Label>
								<Input
									placeholder={t('ADDFORM.PLACEHOLDER.FILES')}
									name='files'
									type='file'
									onChange={(event) => {
										handleRowChange(index, 'files', event.target.files[0]);
									}}
								// value={data.files}
								//onBlur={handleBlur}
								// error={touched.files && 1}
								//errormessage={errors.files}
								/>
								{data?.autocompletedata_file && <a href={data?.autocompletedata_file_url}>{data?.autocompletedata_file}</a>}
							</InputField>
							{data?.field_name && <InputField>
								<Label>
									{t('ADDFORM.PLACEHOLDER.FIED_ID')}
								</Label>
								<Input
									readOnly
									disabled
									name='field_name'
									type='text'
									value={data.field_name}
								/>
							</InputField>}
							{index !== 0 && <InputField>
								<Label>&nbsp;</Label>
								<Button
									title={t('ADDFORM.BTNDELETEROW')}
									onClick={() => (deleteTableRows(index))}
									type='button'
									size={'sm'}
									mobileSize={'block'}
									variant={'danger'}
								>
									{t('ADDFORM.BTNDELETEROW')}
								</Button>
							</InputField>}
						</Wrapper>
						<Button title={t('ADDFORM.BTNADDROW')} variant={'secondary'} type='button' size={'block'} mobileSize={'block'} onClick={() => (addTableRows(index+1))}>{t('ADDFORM.BTNADDROW')}</Button>
					</Container>
				);
			})}
		</>
	);
};

FormFieldRow.propTypes = {
	rowsData: array,
};

export default FormFieldRow;