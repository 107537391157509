import React from 'react';
import { t } from 'i18next';
import { SplashScreenWrapper, Text,  } from './styled';
import { logo } from '../../images/index';
import Typography from '../Typography';

const SplashScreen = () => {
	return (
		<SplashScreenWrapper>
			<img src={logo} />
			<Text>
				<Typography
					variant="h2"
					color="primary"
				>
					{t('SIDEBAR.LOGOTEXT')}
				</Typography>
			</Text>
		</SplashScreenWrapper>
	);
};

export default SplashScreen;