import { array, mixed, object, string } from 'yup';

export const signInValidationSchema = (t) => object().shape({
	email: string().required(t('ERRORS.EMPTY_EMAIL')).email(t('ERRORS.INVALID_EMAIL')),
	password: string().required(t('ERRORS.EMPTY_PASSWORD'))
});

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export const forgotPasswordValidationSchema = (t) => object().shape({
	email: string().required(t('ERRORS.EMPTY_EMAIL')).email(t('ERRORS.INVALID_EMAIL')),
});

export const resetPasswordValidationSchema = (t) => object().shape({
	password: string().required(t('ERRORS.EMPTY_PASSWORD')),
	confirmPassword: string().required(t('ERRORS.EMPTY_CONFIRM_PASSWORD')),
});

export const addUserValidationSchema = (t) => object().shape({
	fname: string().required(t('ERRORS.ENTER_FIRST_NAME')).max(20),
	lname: string().required(t('ERRORS.ENTER_LAST_NAME')).max(20),
	mobile: string()
		.required(t('ERRORS.EMPTY_MOBILE_NUMBER'))
		.matches(phoneRegExp, t('ERRORS.INVALID_NUMBER'))
		.min(10, 'to short')
		.max(10, 'to long'),
	email: string().required(t('ERRORS.EMPTY_EMAIL')).email(t('ERRORS.INVALID_EMAIL')),

});

export const addPlanValidationSchema = (t) => object().shape({
	name: string().required(t('ERRORS.ENTER_PLAN_NAME')).max(20),
	cost: string().required(t('ERRORS.ENTER_COST')).max(10),
	days: string().required(t('ERRORS.ENTER_DAYS')).max(10),
	connect: string().required(t('ERRORS.ENTER_CONNECTS')),
	status: object().required(t('ERRORS.SELECT_STATUS')),
	carryforward: object().required(t('ERRORS.SELECT_CARRYFORWARD')),
	howmuchcf: string()
		.when('carryforward.value', {
			is: '1',
			then: string()
				.required(t('ERRORS.ENTER_HOWMUCH_CARRYFORWARD'))
				.max(10),
			otherwise: string().nullable(),
		}),
	autorecharge: object().required(t('ERRORS.SELECT_AUTORECHARGE')),
});
export const subscribePlanValidationSchema = (t) => object().shape({
	planId: object().required(t('ERRORS.SELECT_PLAN')),
});
export const addPaymentValidationSchema = (t) => object().shape({
	payDate: string().required(t('ERRORS.SELECT_PAYMENT_DATE')),
	payMode: object().required(t('ERRORS.SELECT_PAYMENT_MODE')),
});
export const editPlanValidationSchema = (t) => object().shape({
	name: string().required(t('ERRORS.ENTER_PLAN_NAME')).max(20),
	cost: string().required(t('ERRORS.ENTER_COST')).max(10),
	days: string().required(t('ERRORS.ENTER_DAYS')).max(10),
	connect: string().required(t('ERRORS.ENTER_CONNECTS')),
	status: object().required(t('ERRORS.SELECT_STATUS')),
	autorecharge: object().required(t('ERRORS.SELECT_AUTORECHARGE')),
	carryforward: object().required(t('ERRORS.SELECT_CARRYFORWARD')),
	howmuchcf: string()
		.when('carryforward.value', {
			is: '1',
			then: string()
				.required(t('ERRORS.ENTER_HOWMUCH_CARRYFORWARD'))
				.max(10),
			otherwise: string().nullable(),
		}),
});

export const addDoctorValidationSchema = (t) => object().shape({
	fname: string().required(t('ERRORS.ENTER_FIRST_NAME')).max(20),
	lname: string().required(t('ERRORS.ENTER_LAST_NAME')).max(20),
	username: string().required(t('ERRORS.ENTER_USER_NAME')).max(20),
	mobile: string()
		.required(t('ERRORS.EMPTY_MOBILE_NUMBER'))
		.matches(phoneRegExp, t('ERRORS.INVALID_NUMBER'))
		.min(10, 'to short')
		.max(10, 'to long'),
	email: string().required(t('ERRORS.EMPTY_EMAIL')).email(t('ERRORS.INVALID_EMAIL')),
	selecthospital: array().required(t('ERRORS.SELECT_HOSPITAL')),
});

export const editUserValidationSchema = (t) => object().shape({
	fname: string().required(t('ERRORS.ENTER_FIRST_NAME')).max(20),
	lname: string().required(t('ERRORS.ENTER_LAST_NAME')).max(20),
	mobile: string()
		.required(t('ERRORS.EMPTY_MOBILE_NUMBER'))
		.matches(phoneRegExp, t('ERRORS.INVALID_NUMBER'))
		.min(10, 'to short')
		.max(10, 'to long'),
	email: string().required(t('ERRORS.EMPTY_EMAIL')).email(t('ERRORS.INVALID_EMAIL')),
});
export const hospitalValidationSchema = (t) => object().shape({
	name: string().required(t('ERRORS.ENTER_NAME')),
	address: string().required(t('ERRORS.ENTER_ADDRESS')),
	postfix: string().required(t('ERRORS.ENTER_POSTFIX')),
	zip: string().required(t('ERRORS.ENTER_ZIP'))
		.matches(/^[0-9]+$/, t('ERRORS.INVALID_ZIP')),
	city: string().required(t('ERRORS.ENTER_CITY')),
	state: string().required(t('ERRORS.ENTER_STATE')),
	selectplan: string().required(t('ERRORS.SELECT_PLAN')),
	mobile: string()
		.required(t('ERRORS.EMPTY_MOBILE_NUMBER'))
		.matches(phoneRegExp, t('ERRORS.INVALID_NUMBER')),
	icuBeds: string().required(t('ERRORS.ENTER_ICU_BEDS'))
		.matches(/^[0-9]+$/, t('ERRORS.INVALID_BEDS')),
	generalWardBeds: string().required(t('ERRORS.ENTER_GENERALWARD_BEDS'))
		.matches(/^[0-9]+$/, t('ERRORS.INVALID_BEDS'))

});
export const editHospitalValidationSchema = (t) => object().shape({
	name: string().required(t('ERRORS.ENTER_NAME')),
	address: string().required(t('ERRORS.ENTER_ADDRESS')),
	postfix: string().required(t('ERRORS.ENTER_POSTFIX')),
	zip: string().required(t('ERRORS.ENTER_ZIP'))
		.matches(/^[0-9]+$/, t('ERRORS.INVALID_ZIP')),
	city: string().required(t('ERRORS.ENTER_CITY')),
	state: string().required(t('ERRORS.ENTER_STATE')),
	mobile: string()
		.required(t('ERRORS.EMPTY_MOBILE_NUMBER'))
		.matches(phoneRegExp, t('ERRORS.INVALID_NUMBER')),
	icuBeds: string().required(t('ERRORS.ENTER_ICU_BEDS'))
		.matches(/^[0-9]+$/, t('ERRORS.INVALID_BEDS')),
	generalWardBeds: string().required(t('ERRORS.ENTER_GENERALWARD_BEDS'))
		.matches(/^[0-9]+$/, t('ERRORS.INVALID_BEDS'))

});
export const editDoctorValidationSchema = (t) => object().shape({
	fname: string().required(t('ERRORS.ENTER_FIRST_NAME')).max(20),
	lname: string().required(t('ERRORS.ENTER_LAST_NAME')).max(20),
	username: string().required(t('ERRORS.ENTER_USER_NAME')).max(20),
	mobile: string()
		.required(t('ERRORS.EMPTY_MOBILE_NUMBER'))
		.matches(phoneRegExp, t('ERRORS.INVALID_NUMBER'))
		.min(10, 'to short')
		.max(10, 'to long'),
	email: string().required(t('ERRORS.EMPTY_EMAIL')).email(t('ERRORS.INVALID_EMAIL')),
	hospital: array().min(1).required(t('ERRORS.STATUS'))

});
export const addFormValidationSchema = (t) => object().shape({
	formName: string().required(t('ERRORS.ENTER_FORM_NAME'))
});
export const editFormValidationSchema = (t) => object().shape({
	formName: string().required(t('ERRORS.ENTER_FORM_NAME'))
});
export const importFormValidationSchema = (t) => object().shape({
	file_upload: mixed().required(t('ERRORS.FILE'))
});