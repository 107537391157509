import React, { useEffect, useState } from 'react';
import { t } from 'i18next';
import ReactPaginate from 'react-paginate';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { HiChevronDoubleLeft, HiChevronDoubleRight } from 'react-icons/hi';
import { AiOutlineEdit, AiOutlineDelete, AiOutlineEye } from 'react-icons/ai';
import { TbLock } from 'react-icons/tb';
import { BiSortDown } from 'react-icons/bi';
import { ImCopy } from 'react-icons/im';
import Button from '../../components/Button';
import Typography from '../../components/Typography';
import Input from '../../components/Input';
import Table from '../../components/Table';
import useDebounce from '../../utils/useDebounceHook';
import { getForm, deleteForm, exportForm, accessForm } from '../../redux/form';
import { exportFormComplete } from '../../redux/form/slice';
import {
	Buttons,
	Footer,
	TableContent,
	Header,
	Modal,
	Overlay,
	Container,
	Content,
	SearchInput,
	InputContainer,
} from './styled';
import { downloadFile } from '../../utils/helper';


const HospitalFormList = () => {
	const TableHeadings = ['ID', 'Form Name', 'Access', ''];
	const TableFields = ['id', 'form_name', 'status'];
	const TableActions = [
		{
			'name': 'preview',
			'handleClick': (item) => handlePreview(item.id),
			'size': 'xs',
			'variant': 'warning',
			'title': 'Preview',
			'text': <AiOutlineEye />
		},
		{
			'name': 'sorting',
			'handleClick': (item) => handleSorting(item.id),
			'size': 'xs',
			'variant': 'primary',
			'title': 'Sorting',
			'text': <BiSortDown />
		},
		{
			'name': 'access',
			'handleClick': (item) => handleAccess(item.id, (item.access ? 0 : 1)),
			'size': 'xs',
			'variant': 'success',
			'title': 'Status - Unlock',
			'visible' : (item) => (item.access ? false : true),
			'text': <TbLock />,
		},
		{
			'name': 'access2',
			'handleClick': (item) => handleAccess(item.id, (item.access ? 0 : 1)),
			'size': 'xs',
			'variant': 'danger',
			'visible' : (item) => (item.access ? false : true),
			'title': 'Status - Lock',
			'text': <TbLock />,
		},
		{
			'name': 'edit',
			'handleClick': (item) => handleEdit(item.id),
			'size': 'xs',
			'variant': 'primary',
			'title': 'Edit',
			'text': <AiOutlineEdit />
		},
		{
			'name': 'clone',
			'handleClick': (item) => handleClone(item.id),
			'size': 'xs',
			'variant': 'primary',
			'title': 'Clone Form',
			'text': <ImCopy />
		},
		{
			'name': 'form-delete',
			'handleClick': (item) => handleDelete(item.id),
			'size': 'xs',
			'variant': 'danger',
			'title': 'Delete',
			'text': <AiOutlineDelete />
		}
	];
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const params = useParams();
	const { formList, isFormDeletedSuccess, formData, isFormExportedSuccess, exportFormPath, isFormAccessChangeSuccess } = useSelector(state => state.form);
	const [currentItems, setCurrentItems] = useState([]);
	const [pageCount, setPageCount] = useState(0);
	const [searchUserName, setSearchUserName] = useState('');
	const [deleteMsg, setDeleteMsg] = useState({ show: false, id: null });
	const debouncedSearchTerm = useDebounce(searchUserName, 500);
	const [page, setPage] = useState(1);

	useEffect(() => {
		if (formList.length) {
			let newFormList = formList.map((element) => {
				let status = (element.access) ? 'Active' : 'Inactive';
				return ({ ...element, status: status });
			});
			setCurrentItems(newFormList);
			formList.length ? setPageCount(formData.last_page) : setPageCount(0);
		}
		else {
			setCurrentItems([]);
			setPageCount(0);
		}
	}, [formList]);

	useEffect(() => {
		if (debouncedSearchTerm !== '') {
			dispatch(getForm({ page: page, hospital_id: params.id, search: debouncedSearchTerm }));
		}
		else {
			dispatch(getForm({ page: page, hospital_id: params.id }));
		}
	}, [page, debouncedSearchTerm]);

	useEffect(() => {
		if (isFormDeletedSuccess) {
			dispatch(getForm({ page: page, hospital_id: params.id }));
		}
	}, [isFormDeletedSuccess]);

	useEffect(() => {
		if (isFormExportedSuccess) {
			dispatch(exportFormComplete());
			downloadFile(exportFormPath);
		}
	}, [isFormExportedSuccess]);

	useEffect(() => {
		if (isFormAccessChangeSuccess) {
			dispatch(getForm({ page: page, hospital_id: params.id }));
		}
	}, [isFormAccessChangeSuccess]);

	const handleAccess = (id, newAccess) => {
		const body = { form_id: id, access: newAccess, hospital_id: params.id };
		dispatch(accessForm({ body }));
	};

	const handlePreview = (formid) => {
		navigate(`/hospital-management/${params.id}/previewform/${formid}/${page}`);
	};

	const handleEdit = (formid) => {
		navigate(`/hospital-management/${params.id}/formedit/${formid}/${page}`);
	};

	const handleSorting = (formid) => {
		navigate(`/hospital-management/${params.id}/sortingform/${formid}/${page}`);
	};

	const handleClone = (id) => {
		navigate(`/hospital-management/${params.id}/formcreate/${params.page}?clone_form=${id}`);
	};

	const handleDelete = (id) => {
		setDeleteMsg({
			show: true,
			id,
		});
	};

	const hendleDeleteYes = () => {
		if (deleteMsg.show && deleteMsg.id) {
			const body = { form_id: deleteMsg.id, hospital_id : params.id };
			dispatch(deleteForm({ body }));
			setDeleteMsg({
				show: false,
				id: null,
			});
		}
	};

	const handlePageClick = (event) => {
		const pageNum = event.selected + 1;
		setPage(pageNum);
	};

	const handleExportForm = () => {
		dispatch(exportForm({ hospital_id: params.id }));
	};

	const handleSearchByName = (event) => {
		const inputVal = event.target.value;
		setSearchUserName(inputVal);
		setPage(1);
	};
	return (
		<Container>
			<Content>
				<Header>
					<Typography
						variant="h4"
						color="black"
						weight="500"
					>
						{t('HOSPITALFORMLIST.HEADING')}
					</Typography>
				</Header>
				<SearchInput>
					<InputContainer>
						<Input placeholder={t('HOSPITALFORMLIST.PLACEHOLDER.INPUT')} onChange={handleSearchByName} value={searchUserName} />
					</InputContainer>
					<Button title={t('HOSPITALFORMLIST.BTNADD')} onClick={() => navigate(`/hospital-management/${params.id}/formcreate/${params.page}`)} size={'xs'}>
						{t('HOSPITALFORMLIST.BTNADD')}
					</Button>
					<Button title={t('HOSPITALFORMLIST.BTNEXPORT')} onClick={handleExportForm} size={'xs'}>
						{t('HOSPITALFORMLIST.BTNEXPORT')}
					</Button>
					<Button title={t('EDITHOSPITAL.BACKBUTTON')}
						onClick={() => navigate('/hospital-management/list')}
						size={'xs'}
					>
						{t('EDITHOSPITAL.BACKBUTTON')}
					</Button>
				</SearchInput>
				<TableContent>
					<Table tableHeading={TableHeadings} tableFields={TableFields} tableData={currentItems} tableActions={TableActions} />
					<Footer>
						<ReactPaginate
							breakLabel="..."
							nextLabel={<HiChevronDoubleRight />}
							onPageChange={handlePageClick}
							pageRangeDisplayed={5}
							pageCount={pageCount}
							previousLabel={<HiChevronDoubleLeft />}
							forcePage={pageCount === 0 ? -1 : page - 1}
						/>
					</Footer>
				</TableContent>
			</Content>
			{deleteMsg.show ?
				<Overlay>
					<Modal>
						<Typography
							variant="h4"
							color="black"
						>
							{t('HOSPITALFORMLIST.DELETECONFIRM')}
						</Typography>
						<Buttons>
							<Button title={t('HOSPITALFORMLIST.BTNNO')} onClick={() => setDeleteMsg(false)} size={'sm'}>{t('HOSPITALFORMLIST.BTNNO')}</Button>
							<Button title={t('HOSPITALFORMLIST.BTNYES')} onClick={hendleDeleteYes} size={'sm'} variant={'danger'}>{t('HOSPITALFORMLIST.BTNYES')}</Button>
						</Buttons>
					</Modal>
				</Overlay>
				: null}
		</Container>
	);
};

export default HospitalFormList;