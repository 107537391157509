import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Formik } from 'formik';
import { t } from 'i18next';
import omit from 'lodash/omit';
import Typography from '../../components/Typography';
import Button from '../../components/Button';
import Input from '../../components/Input';
import FormFieldRow from '../../components/FormFieldRow';
import Loader from '../../components/Loader';
import { addForm, getEditForm } from '../../redux/form';
import { addFormValidationSchema } from '../../utils/validation';
import { Container, Forms, Header, InputFullWidthField, Label, Wrapper } from './styled';
import SelectDropdown from '../../components/SelectDropdown';

const HospitalFormCreate = () => {
	const { editFormData, isFetchEditFormSuccess, isFormAddedSuccess, Loading } = useSelector(state => state.form);
	const formType = [
		{ value: 'main', 'label': 'Main' },
		{ value: 'sub', 'label': 'Sub' }
	];
	const navigate = useNavigate();
	const [formName, setFormName] = useState('');
	const [type, setType] = useState('main');
	const params = useParams();
	const dispatch = useDispatch();
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	const cloneFormId = queryParams.get('clone_form');

	const defaultRow = {
		'showFetchOldata': false,
		'showFormData': false,
		'showThemeType': false,
		'showTimerType': false,
		'showSubTitle': false,
		'showFetchField': false,
	};
	const [rowsData, setRowsData] = useState([defaultRow]);
	const deleteTableRows = (index) => {
		const rows = [...rowsData];
		rows.splice(index, 1);
		setRowsData(rows);
	};
	const handleRowChange = (index, name, value) => {
		const rowsInput = [...rowsData];
		rowsInput[index][name] = value;
		if (name === 'type') {
			rowsInput[index]['showFetchOldata'] = false;
			rowsInput[index]['showSubTitle'] = false;
			rowsInput[index]['showThemeType'] = false;
			rowsInput[index]['showFormData'] = false;
			rowsInput[index]['showTimerType'] = false;
			if (value === 'text') {
				rowsInput[index]['showFetchOldata'] = true;
				rowsInput[index]['showSubTitle'] = true;
			}
			else if (value === 'textarea') {
				rowsInput[index]['showThemeType'] = true;
				rowsInput[index]['showSubTitle'] = true;
			}
			else if (value === 'date') {
				rowsInput[index]['showSubTitle'] = true;
			}
			else if (value === 'datetime') {
				rowsInput[index]['showSubTitle'] = true;
			}
			else if (value === 'time') {
				rowsInput[index]['showSubTitle'] = true;
			}
			else if (value === 'number') {
				rowsInput[index]['showSubTitle'] = true;
			}
			else if (value === 'lab-report-values') {
				rowsInput[index]['showSubTitle'] = true;
			}
			else if (value === 'formdata') {
				rowsInput[index]['showFormData'] = true;
			}
			else if (value === 'subform') {
				rowsInput[index]['showFetchOldata'] = true;
				rowsInput[index]['showFormData'] = true;
				rowsInput[index]['showTimerType'] = true;
			}
		}
		else if (name === 'fetch_old_data') {
			if (value === 1) {
				rowsInput[index]['showFormData'] = true;
			}
			else {
				rowsInput[index]['showFormData'] = false;
			}
		}
		else if (name === 'formdata_id') {
			if (value) {
				rowsInput[index]['showFormData'] = true;
			}
			else {
				rowsInput[index]['showFormData'] = false;
			}
		}
		setRowsData(rowsInput);
	};

	useEffect(() => {
		if (cloneFormId > 0) {
			dispatch(getEditForm({ form_id: cloneFormId, 'hospital_id': params.id }));
		}
	}, [cloneFormId]);

	useEffect(() => {
		if (isFetchEditFormSuccess) {
			// setList(editFormData);
			setFormName('Copy - '+editFormData.form_name);
			setType(editFormData.type);
			let datas = [];
			let defaultFlags = [];
			editFormData.fields.forEach(element => {
				element = omit(element, 'field_name');
				defaultFlags = parseFlag(element);
				datas.push({ ...element, ...defaultFlags });
			});
			setRowsData(datas);
		}
	}, [isFetchEditFormSuccess, editFormData]);

	useEffect(() => {
		if (isFormAddedSuccess) {
			navigate(`/hospital-management/${params.id}/formlist/${params.page}`);
		}
	}, [isFormAddedSuccess]);
	const parseFlag = (element) => {
		let fetchOldData = element.fetch_old_data, formdataId = element.formdata_id, type = element.type;
		let flags = [];
		flags['showFetchOldata'] = false;
		flags['showSubTitle'] = false;
		flags['showThemeType'] = false;
		flags['showFormData'] = false;
		flags['showTimerType'] = false;
		if (type === 'text') {
			flags['showFetchOldata'] = true;
			flags['showSubTitle'] = true;
		}
		else if (type === 'textarea') {
			flags['showThemeType'] = true;
			flags['showSubTitle'] = true;
		}
		else if (type === 'date') {
			flags['showSubTitle'] = true;
		}
		else if (type === 'datetime') {
			flags['showSubTitle'] = true;
		}
		else if (type === 'time') {
			flags['showSubTitle'] = true;
		}
		else if (type === 'number') {
			flags['showSubTitle'] = true;
		}
		else if (type === 'lab-report-values') {
			flags['showSubTitle'] = true;
		}
		else if (type === 'formdata') {
			flags['showFormData'] = true;
		}
		else if (type === 'subform') {
			flags['showFetchOldata'] = true;
			flags['showFormData'] = true;
			flags['showTimerType'] = true;
		}
		if (fetchOldData === 1) {
			flags['showFormData'] = true;
		}
		else if (fetchOldData === 0) {
			flags['showFormData'] = false;
		}
		if (formdataId) {
			flags['showFormData'] = true;
		}
		else {
			flags['showFormData'] = false;
		}
		return flags;
	};
	const handleAddForm = ({ formName, type }) => {
		let formData = new FormData;
		formData.append('form_name', formName);
		formData.append('type', type?.value || type);
		formData.append('hospital_id', params.id);
		for (let i = 0; i < rowsData.length; i++) {
			for (const property in rowsData[i]) {
				formData.append(`field[${i}][${property}]`, rowsData[i][property] || '');
			}
		}
		dispatch(addForm({ body: formData }));
	};
	return (
		<Wrapper>
			<Forms>
				<Header>
					<Typography variant="h4" color="black" weight="500">
						{t('HOSPITALFORMADD.HEADING')}
					</Typography>
					<Button
						title={t('HOSPITALFORMADD.BACKBUTTON')}
						onClick={() => navigate(`/hospital-management/${params.id}/formlist/${params.page}`)}
						size={'xs'}
					>
						{t('HOSPITALFORMADD.BACKBUTTON')}
					</Button>
				</Header>
				{Loading ? <Loader /> :
					<Formik enableReinitialize initialValues={{ formName, type }} validationSchema={addFormValidationSchema(t)} onSubmit={handleAddForm} >
						{({ errors, touched, values, setFieldValue, handleChange, handleBlur }) => (
							<Form>
								<Container>
									<InputFullWidthField>
										<Label>
											{t('ADDFORM.PLACEHOLDER.FORM_NAME')}
										</Label>
										<Input
											placeholder={t('HOSPITALFORMADD.PLACEHOLDER.FORM_NAME')}
											name='formName'
											type='text'
											onChange={handleChange}
											onBlur={handleBlur}
											error={touched.formName && 1}
											errormessage={errors.formName}
											value={values.formName}
										/>
									</InputFullWidthField>
									<InputFullWidthField>
										<Label>
											{t('ADDFORM.PLACEHOLDER.FORM_TYPE')}
										</Label>
										<SelectDropdown
											options={formType}
											placeholder={'Form Type'}
											name='type'
											value={formType.find(option => option.value === values.type)}
											onChange={(event) => setFieldValue('type', event)}
											onBlur={handleBlur}
										/>
									</InputFullWidthField>
								</Container>
								<FormFieldRow dispatch={dispatch} rowsData={rowsData} setRowsData={setRowsData} deleteTableRows={deleteTableRows} handleRowChange={handleRowChange} hospitalId={params.id} />
								<Container>
									<Button title={t('HOSPITALFORMADD.BTNTEXT')} type='submit' size={'sm'}>{t('HOSPITALFORMADD.BTNTEXT')}</Button>
								</Container>
							</Form>
						)}
					</Formik>}
			</Forms>
		</Wrapper>
	);
};

export default HospitalFormCreate;