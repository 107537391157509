import { createSlice } from '@reduxjs/toolkit';
import { getUsers, updateUser, addUser, deleteUser, userProfile } from '../users';

const initialState = {
	userList: [],
	userData: [],
	userErrorMessage: '',
	Loading: false,

	userProfileDetails: [],
	userProfileErrorMessage: '',

	isUserUpdateSuccess: false,
	updateUserErrorMessage: '',

	isUserAddedSuccess: false,
	addUserErrorMessage: '',

	isUserDeletedSuccess: false,
	isUserDeleteErrorMessage: '',
};

const userSlice = createSlice({
	name: 'users',
	initialState,
	reducers: {
		clearAddUserErrorMessage: (state) => {
			state.addUserErrorMessage = '';
			state.updateUserErrorMessage = '';
		},
	},
	extraReducers: {
		[getUsers.pending]: (state) => {
			state.Loading = true;
		},
		[getUsers.fulfilled]: (state, action) => {
			state.Loading = false;
			state.userList = action.payload?.response.data.data.data;
			state.userData = action.payload?.response.data.data;
			state.isUserUpdateSuccess = false;
			state.isUserDeletedSuccess = false;
			state.isUserAddedSuccess = false;
			state.userProfileDetails = [];
		},
		[getUsers.rejected]: (state) => {
			state.Loading = false;
			state.userErrorMessage = '';
		},
		[userProfile.fulfilled]: (state, action) => {
			state.userProfileDetails = action.payload?.response.data.data;
		},
		[userProfile.rejected]: (state) => {
			state.userProfileErrorMessage = '';
		},
		[updateUser.fulfilled]: (state) => {
			state.isUserUpdateSuccess = true;
			state.updateUserErrorMessage = '';
		},
		[updateUser.rejected]: (state, err) => {
			state.updateUserErrorMessage = err;
		},
		[addUser.fulfilled]: (state) => {
			state.isUserAddedSuccess = true;
			state.addUserErrorMessage = '';
		},
		[addUser.rejected]: (state, err) => {
			state.addUserErrorMessage = err;
		},
		[deleteUser.fulfilled]: (state) => {
			state.isUserDeletedSuccess = true;
		},
		[deleteUser.rejected]: (state, err) => {
			state.isUserDeleteErrorMessage = err;
		},
	},
});

export const { clearAddUserErrorMessage } = userSlice.actions;

export default userSlice.reducer;