import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { t } from 'i18next';
import { MdLogout, MdPeopleOutline } from 'react-icons/md';
import Button from '../Button';
import Typography from '../Typography';
import { UserPlaceholder } from '../../images';
import { useAuth } from '../../utils/helper';
import { handleLogOut } from '../../redux/auth';
import {
	Container,
	Content,
	Profile,
	OverLay,
	UserImg,
	Hamburger,
	HamburgerIcon,
	ToggleButtons,
	Modal,
	ModalTop,
	ModalBottom,
	ListItem,
	ItemLink,
	ImgContainer,
	MenuItems,
} from './styled';

const ProfileToggler = () => {
	const dispatch = useDispatch();
	const { userDetails } = useSelector(state => state.auth);
	const useAuthContext = useAuth();
	const handleLogOutSubmit = () => {
		dispatch(handleLogOut({ useAuthContext }));
	};
	return (
		<Modal>
			<ModalTop>
				<Typography
					variant="h4"
					align="left"
					display="block">
					{userDetails?.first_name + ' ' + userDetails?.last_name}
				</Typography>
				<Typography
					variant="body"
					align="left"
					display="block">
					{userDetails?.user_role}
				</Typography>
			</ModalTop>
			<ModalBottom>
				<MenuItems>
					<ListItem>
						<ItemLink to={'/profile'} className='flex ac'>
							<ImgContainer>
								<MdPeopleOutline />
							</ImgContainer>
							<Typography
								variant="body"
								align="left"
								display="block"
							>{t('HEADER.PRFILE_TOGGLE.PROFILE_MENUS.FIRST_MENU')}</Typography>
						</ItemLink>
					</ListItem>
				</MenuItems>
				<Button title={t('HEADER.PRFILE_TOGGLE.LOGOUT_BUTTON')} onClick={handleLogOutSubmit} size={'sm'}>{t('HEADER.PRFILE_TOGGLE.LOGOUT_BUTTON')}&nbsp;{<MdLogout />}</Button>
			</ModalBottom>
		</Modal>
	);
};

const Header = ({ onClick, active }) => {
	const [profileModal, setProfileModal] = useState(false);
	const { userDetails } = useSelector(state => state.auth);
	const showProfileModal = () => {
		setProfileModal(!profileModal);
	};

	const removeModal = () => {
		setProfileModal(false);
	};

	return (
		<Container active={active}>
			<Content>
				<Hamburger onClick={onClick}>
					<HamburgerIcon />
					<HamburgerIcon />
					<HamburgerIcon />
				</Hamburger>
				<ToggleButtons>
					{profileModal ? <OverLay onClick={removeModal} /> : ''}
					<Profile onClick={() => showProfileModal()}>
						<UserImg src={userDetails.profile_picture ? userDetails.profile_picture : UserPlaceholder} alt={t('HEADER.PRFILE_TOGGLE.ALTTEXT')} />
						{profileModal ? <ProfileToggler /> : ''}
					</Profile>
				</ToggleButtons>
			</Content>
		</Container>
	);
};

export default Header;