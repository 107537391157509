/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import { t } from 'i18next';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import PageTitle from '../../components/PageTitle/index.js';
import { userProfile } from '../../redux/users/index.js';
import { UserPlaceholder } from '../../images';
import { Container, Wrapper, Card, CardBody, ProfileHeading, ProfileDetail, ProfileImgWrapper, ProfileImg, ProfileTitle, ProfileRole, ProfileDescription, Detail, Label, Value } from './styled.js';

const ViewUser = () => {
	const dispatch = useDispatch();
	const { userProfileDetails } = useSelector(state => state.users);
	const { id } = useParams();
	const [userData, setUserData] = useState([]);

	useEffect(() => {
		if (userProfileDetails.length < 1) {
			dispatch(userProfile({ user_id: id }));
		}
	}, []);

	useEffect(() => {
		if (userProfileDetails) {
			setUserData(userProfileDetails);
		}
	}, [userProfileDetails]);

	return (
		<>
			<PageTitle >{t('DETAILUSER.HEADING')}</PageTitle>
			<Container>
				<Wrapper>
					<Card>
						<CardBody>
							<ProfileHeading />
							<ProfileDetail>
								<ProfileImgWrapper>
									<ProfileImg src={userData?.profile_picture || UserPlaceholder} />
								</ProfileImgWrapper>
								<ProfileTitle>
									{(userData?.first_name || '') + ' ' + (userData?.last_name || '')}
								</ProfileTitle>
								<ProfileRole>
									{userData?.user_role}
								</ProfileRole>
								<ProfileDescription>
									<Detail>
										<Label>{t('GENERAL.EMAIL') + ': '}</Label>
										<Value>{userData?.email}</Value>
									</Detail>
									<Detail>
										<Label>{t('GENERAL.PHONE') + ': '}</Label>
										<Value>{userData?.mobile_number}</Value>
									</Detail>
								</ProfileDescription>
							</ProfileDetail>
						</CardBody>
					</Card>
				</Wrapper>
			</Container>
		</>
	);
};

export default ViewUser;
