import { configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { combineReducers } from 'redux';
import authReducer from './auth/slice';
import userReducer from './users/slice';
import doctorReducer from './doctors/slice';
import hospitalReducer from './hospital/slice';
import formReducer from './form/slice';
import planReducer from './plan/slice';

const reducers = combineReducers({
	auth: authReducer,
	users: userReducer,
	doctors: doctorReducer,
	hospital: hospitalReducer,
	form: formReducer,
	plan: planReducer,
});
const rootReducer = (state, action) => reducers(state, action);
const persistConfig = {
	key: 'root',
	storage,
	whitelist: ['']
};
const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
	reducer: persistedReducer,
	middleware: getDefaultMiddleware =>
		getDefaultMiddleware({
			serializableCheck: false,
		}),
});
export const persistor = persistStore(store);