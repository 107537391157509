import styled from '@emotion/styled';

export const Wrapper = styled.div`
    display: flex;
    align-items: center;
    margin: 5px 15px 0px 0px;
`;

export const RadioInput = styled.input`
    width: 15px;
    height: 15px;
    position: relative;
    overflow: hidden;
    ${(props) => props.theme.breakPoint.mobile} {
        width: 14px;
        height: 14px;
    }
    &:checked~label {    
        &::before {
        content: " ";
        display: block;
        width: 15px;
        height: 15px;
        position: absolute;
        top: 3px;
        left: -19px;
        border-radius: 50%;
        pointer-events: none;
        border: solid 1px;
        color: ${(props) => props.theme.colors.light};
        background-color: ${(props) => props.theme.colors.primary};
        border-color: ${(props) => props.theme.colors.primary};
        transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
            ${(props) => props.theme.breakPoint.mobile} {
                width: 14px;
                height: 14px;
                top: 3px;
                left: -21px;
            }
        }
        &::after {
            content: "";
            display: block;
            width: 5px;
            height: 5px;
            position: absolute;
            top: 8px;
            left: -14px;
            border-radius: 50%;
            border: 1px solid ${(props) => props.theme.colors.light};
            background-color: ${(props) => props.theme.colors.light};
            ${(props) => props.theme.breakPoint.mobile} {
                width: 4px;
                height: 4px;
                top: 8px;
                left: -16px;
            }
        }
    }
`;

export const Label = styled.label`
    transition: all 0.3s;
    position: relative;
    font-size: 16px;
`;