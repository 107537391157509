import styled from '@emotion/styled';

export const Table = styled.div`
	border-collapse: collapse;
	width: 100%;
	border: 1px solid #ddd;
	max-height: 500px;
	overflow: auto;
`;
export const TableDiv = styled.div`
	width: 100%;
	display: table;
`;
export const TRow = styled.div`
	display: table-row;
	&:hover {
		background: ${(props) => props.theme.colors.primaryLight};
        opacity: 1;
    }
`;

export const TCell = styled.div`
	display: table-cell;
	padding: 8px;
	border: 1px solid #ddd;
`;
