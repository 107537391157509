import React, { useState } from 'react';
import Sidebar from '../Sidebar';
import Header from '../Header';
import { Content, Conatiner, Layout, SidebarConatiner } from './styled';

const LayoutComponent = ({ children }) => {
	const [isOpen, setIsOpen] = useState(false);
	const [changeStyle, setChangeStyle] = useState(false);

	const afterScoll = () => {
		if (document.querySelector('.content').scrollTop > 0) setChangeStyle(true);
		else setChangeStyle(false);
	};

	const openSidebar = () => {
		setIsOpen(!isOpen);
	};

	return (
		<Layout>
			<SidebarConatiner isOpen={isOpen}>
				<Sidebar closeMenu={() => setIsOpen(false)} sidebarOpen={isOpen} setSidebarOpen={setIsOpen} />
			</SidebarConatiner>
			<Conatiner className='content' isOpen={isOpen} onScroll={afterScoll} active={changeStyle}>
				<Header active={changeStyle} onClick={openSidebar} />
				<Content isOpen={isOpen}>
					{children}
				</Content>
			</Conatiner>
		</Layout>
	);
};

export default LayoutComponent;