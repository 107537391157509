import styled from '@emotion/styled';

export const PageTitleWrapper = styled.div`
    background: ${(props) => props.theme.colors.light};
    border-radius: 25px; 
    border: none; 
    box-shadow: 0px 4px 5px 1px ${(props) => props.theme.colors.shadow}10;
`;

export const Header = styled.div`
    padding: 0 20px;
    min-height: 60px;
    align-items: center !important;
    border-bottom: 1px solid #eeeeee;
    border-radius: 25px 25px 0 0;
    justify-content: space-between!important;
    display: flex!important;
`;

export const Title = styled.div`
    display: inline-block;
    align-self: center !important;
`;
