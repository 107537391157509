import React, { useState } from 'react';
import { t } from 'i18next';
import { NavLink } from 'react-router-dom';
import Typography from '../Typography';
import theme from '../../themes/base.json';
import { logo } from '../../images/index';
import { RiHospitalLine, RiStethoscopeLine } from 'react-icons/ri';
import { BiRupee } from 'react-icons/bi';
import { AiOutlineForm } from 'react-icons/ai';
import { MdOutlineHome, MdPieChart, MdArrowBack, MdKeyboardArrowDown } from 'react-icons/md';
import {
	Container,
	Logo,
	Navbar,
	Hamburger,
	HamburgerIcon,
	MobileMenu,
	Separator,
	NavItems,
	ListItem,
	Dropdown,
	DropdownItem,
	SubNavItems,
	SubNavListItem,
	SubNavLink,
	Toggle,
	Text,
	VersionText
} from './styled';

const Sidebar = ({
	setSidebarOpen,
	sidebarOpen,
	closeMenu
}) => {
	const [subnav, setSubnav] = useState(false);
	const [hospitalForm, setHospitalForm] = useState(false);
	const [planForm, setPlanForm] = useState(false);
	const [formManagementList, setFormManagementList] = useState(false);
	const [drForm, setDrForm] = useState(false);

	const showSubnav = () => {
		setSubnav(!subnav);
		if (window.innerWidth > 1199) {
			setSidebarOpen(false);
		}
	};

	const showDrForm = () => {
		setDrForm(!drForm);
		if (window.innerWidth > 1199) {
			setSidebarOpen(false);
		}
	};

	const showHospitalForm = () => {
		setHospitalForm(!hospitalForm);
		if (window.innerWidth > 1199) {
			setSidebarOpen(false);
		}
	};
	const showPlanForm = () => {
		setPlanForm(!planForm);
		if (window.innerWidth > 1199) {
			setSidebarOpen(false);
		}
	};

	const showFormManagementList = () => {
		setFormManagementList(!formManagementList);
		if (window.innerWidth > 1199) {
			setSidebarOpen(false);
		}
	};

	const closeSubMenu = () => {
		setSubnav(false); setDrForm(false); setHospitalForm(false); setFormManagementList(false);
		setSidebarOpen(false);
	};

	const closeSidebar = () => {
		setSidebarOpen(!sidebarOpen);
		setSubnav(false); setDrForm(false); setHospitalForm(false); setFormManagementList(false);
	};

	const navStyles = ({ isActive }) => {
		return {
			fontWeight: 'normal',
			fontSize: 14,
			whiteSpace: 'nowrap',
			color: isActive ? theme.colors.primary : theme.colors.light,
			position: 'relative',
			padding: '13px 15px',
			lineHeight: '17px',
			display: 'flex',
			alignItems: 'center',
			overflow: 'hidden',
			borderRadius: '15px',
			textTransform: 'capitalize',
			textDecoration: 'none',
			backgroundColor: isActive ? theme.colors.light : 'transparent',
		};
	};

	return (
		<Container>
			<MobileMenu>
				<Logo to={'/'} onClick={closeSubMenu}>
					<img src={logo} />
					<Text>
						<Typography
							variant="h2"
							color="light"
						>
							{t('SIDEBAR.LOGOTEXT')}
						</Typography>
					</Text>
				</Logo>
				<Hamburger onClick={closeMenu}>
					<HamburgerIcon />
					<HamburgerIcon />
					<HamburgerIcon />
				</Hamburger>
			</MobileMenu>
			<Navbar>
				<Separator />
				<NavItems>
					<ListItem>
						<NavLink style={navStyles} to='/' onClick={closeSubMenu}>
							<MdOutlineHome />
							<Text>
								<Typography
									variant="body"
									align="left"
								>
									{t('SIDEBAR.NAVITEMS.MENU1')}
								</Typography>
							</Text>
						</NavLink>
					</ListItem>
					<ListItem>
						<Dropdown onClick={showSubnav}>
							<MdPieChart />
							<DropdownItem subnav={subnav}>
								<Text>
									<Typography
										variant="body"
										align="left"
									>
										{t('SIDEBAR.NAVITEMS.MENU3')}
									</Typography>
								</Text>
								<MdKeyboardArrowDown />
							</DropdownItem>
						</Dropdown>
						<SubNavItems subnav={subnav}>
							<SubNavListItem>
								<SubNavLink to={'/user-management/add'} onClick={() => setSidebarOpen(false)}>
									<Text>
										<Typography
											variant="body"
											align="left"
										>
											{t('SIDEBAR.NAVITEMS.MENU3_SUBMENU.SUBMENU1')}
										</Typography>
									</Text>
								</SubNavLink>
							</SubNavListItem>
							<SubNavListItem>
								<SubNavLink to={'/user-management/list'} onClick={() => setSidebarOpen(false)}>
									<Text>
										<Typography
											variant="body"
											align="left"
										>
											{t('SIDEBAR.NAVITEMS.MENU3_SUBMENU.SUBMENU2')}
										</Typography>
									</Text>
								</SubNavLink>
							</SubNavListItem>
						</SubNavItems>
					</ListItem>
					<ListItem>
						<Dropdown onClick={showDrForm}>
							<RiStethoscopeLine />
							<DropdownItem subnav={drForm}>
								<Text>
									<Typography
										variant="body"
										align="left"
									>
										{t('SIDEBAR.NAVITEMS.MENU4')}
									</Typography>
								</Text>
								<MdKeyboardArrowDown />
							</DropdownItem>
						</Dropdown>
						<SubNavItems subnav={drForm}>
							<SubNavListItem>
								<SubNavLink to={'/dr-management/add'} onClick={() => setSidebarOpen(false)}>
									<Text>
										<Typography
											variant="body"
											align="left"
										>
											{t('SIDEBAR.NAVITEMS.MENU4_SUBMENU.SUBMENU1')}
										</Typography>
									</Text>
								</SubNavLink>
							</SubNavListItem>
							<SubNavListItem>
								<SubNavLink to={'/dr-management/list'} onClick={() => setSidebarOpen(false)}>
									<Text>
										<Typography
											variant="body"
											align="left"
										>
											{t('SIDEBAR.NAVITEMS.MENU4_SUBMENU.SUBMENU2')}
										</Typography>
									</Text>
								</SubNavLink>
							</SubNavListItem>
						</SubNavItems>
					</ListItem>
					<ListItem>
						<Dropdown onClick={showHospitalForm}>
							<RiHospitalLine />
							<DropdownItem subnav={hospitalForm}>
								<Text>
									<Typography
										variant="body"
										align="left"
									>
										{t('SIDEBAR.NAVITEMS.MENU5')}
									</Typography>
								</Text>
								<MdKeyboardArrowDown />
							</DropdownItem>
						</Dropdown>
						<SubNavItems subnav={hospitalForm}>
							<SubNavListItem>
								<SubNavLink to={'/hospital-management/add'} onClick={() => setSidebarOpen(false)}>
									<Text>
										<Typography
											variant="body"
											align="left"
										>
											{t('SIDEBAR.NAVITEMS.MENU5_SUBMENU.SUBMENU1')}
										</Typography>
									</Text>
								</SubNavLink>
							</SubNavListItem>
							<SubNavListItem>
								<SubNavLink to={'/hospital-management/list'} onClick={() => setSidebarOpen(false)}>
									<Text>
										<Typography
											variant="body"
											align="left"
										>
											{t('SIDEBAR.NAVITEMS.MENU5_SUBMENU.SUBMENU2')}
										</Typography>
									</Text>
								</SubNavLink>
							</SubNavListItem>
						</SubNavItems>
					</ListItem>
					<ListItem>
						<Dropdown onClick={showPlanForm}>
							<BiRupee />
							<DropdownItem subnav={planForm}>
								<Text>
									<Typography
										variant="body"
										align="left"
									>
										{t('SIDEBAR.NAVITEMS.MENU7')}
									</Typography>
								</Text>
								<MdKeyboardArrowDown />
							</DropdownItem>
						</Dropdown>
						<SubNavItems subnav={planForm}>
							<SubNavListItem>
								<SubNavLink to={'/plan/add'} onClick={() => setSidebarOpen(false)}>
									<Text>
										<Typography
											variant="body"
											align="left"
										>
											{t('SIDEBAR.NAVITEMS.MENU7_SUBMENU.SUBMENU1')}
										</Typography>
									</Text>
								</SubNavLink>
							</SubNavListItem>
							<SubNavListItem>
								<SubNavLink to={'/plan/list'} onClick={() => setSidebarOpen(false)}>
									<Text>
										<Typography
											variant="body"
											align="left"
										>
											{t('SIDEBAR.NAVITEMS.MENU7_SUBMENU.SUBMENU2')}
										</Typography>
									</Text>
								</SubNavLink>
							</SubNavListItem>
							<SubNavListItem>
								<SubNavLink to={'/plan/billing'} onClick={() => setSidebarOpen(false)}>
									<Text>
										<Typography
											variant="body"
											align="left"
										>
											{t('SIDEBAR.NAVITEMS.MENU7_SUBMENU.SUBMENU3')}
										</Typography>
									</Text>
								</SubNavLink>
							</SubNavListItem>
						</SubNavItems>
					</ListItem>
					<ListItem>
						<Dropdown onClick={showFormManagementList}>
							<AiOutlineForm />
							<DropdownItem subnav={formManagementList}>
								<Text>
									<Typography
										variant="body"
										align="left"
									>
										{t('SIDEBAR.NAVITEMS.MENU6')}
									</Typography>
								</Text>
								<MdKeyboardArrowDown />
							</DropdownItem>
						</Dropdown>
						<SubNavItems subnav={formManagementList}>
							<SubNavListItem>
								<SubNavLink to={'/form-management/create'} onClick={() => setSidebarOpen(false)}>
									<Text>
										<Typography
											variant="body"
											align="left"
										>
											{t('SIDEBAR.NAVITEMS.MENU6_SUBMENU.SUBMENU1')}
										</Typography>
									</Text>
								</SubNavLink>
							</SubNavListItem>
							<SubNavListItem>
								<SubNavLink to={'/form-management/list'} onClick={() => setSidebarOpen(false)}>
									<Text>
										<Typography
											variant="body"
											align="left"
										>
											{t('SIDEBAR.NAVITEMS.MENU6_SUBMENU.SUBMENU2')}
										</Typography>
									</Text>
								</SubNavLink>
							</SubNavListItem>
							<SubNavListItem>
								<SubNavLink to={'/form-management/import-form'} onClick={() => setSidebarOpen(false)}>
									<Text>
										<Typography
											variant="body"
											align="left"
										>
											{t('SIDEBAR.NAVITEMS.MENU6_SUBMENU.SUBMENU4')}
										</Typography>
									</Text>
								</SubNavLink>
							</SubNavListItem>
						</SubNavItems>
					</ListItem>
				</NavItems>
				<Container>
					<VersionText>
						<Typography variant="body" align="left" color="light">
							{t('SIDEBAR.APPVER', { version: process.env.REACT_APP_VERSION })}
						</Typography>
					</VersionText>
				</Container>
			</Navbar>
			<Toggle onClick={closeSidebar} iconChange={sidebarOpen}>
				<MdArrowBack />
			</Toggle>
		</Container>
	);
};

export default Sidebar;