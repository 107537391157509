import styled from '@emotion/styled';

export const Container = styled.div`
    display: flex;
    align-items: center;
    position: fixed;
    left: 0;
    top: 0;
    background-color: ${(props) => props.theme.colors.primaryLight};
    width: 100%;
    height: 100vh;
    z-index: 10;
`;

export const Model = styled.div`
    max-width: 40%;
    width: 100%;
    padding: 15px;
    margin: auto;
    background-color: ${(props) => props.theme.colors.light};
    border-radius: 25px;
    text-align: center;
    box-shadow: 0px 4px 5px 1px ${(props) =>  props.theme.colors.shadow}10;
    ${(props) => props.theme.breakPoint.tabletLandscape} {
        max-width: 60%;
    }
    ${(props) => props.theme.breakPoint.mobileAndTablet} {
        max-width: 70%;
    }
    ${(props) => props.theme.breakPoint.mobile} {
        max-width: 100%;
        margin: 0 15px;
        border-radius: 15px;
    }
`;

export const Text = styled.div`
    margin: 15px 0;
`;

export const ButtonContainer = styled.div`
    margin: 25px 0 15px 0;
`;