import React, { useEffect, useState } from 'react';
import { t } from 'i18next';
import ReactPaginate from 'react-paginate';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { HiChevronDoubleLeft, HiChevronDoubleRight } from 'react-icons/hi';
import { AiOutlineEdit, AiOutlineDelete } from 'react-icons/ai';
import { GiCombinationLock } from 'react-icons/gi';
import isEmpty from 'lodash/isEmpty';
import Button from '../../components/Button';
import Typography from '../../components/Typography';
import Input from '../../components/Input';
import Table from '../../components/Table';
import SelectDropdown from '../../components/SelectDropdown';
import { getDoctors, getAssignHospitalList, deleteDoctor, forgetDoctorPassword } from '../../redux/doctors';
import useDebounce from '../../utils/useDebounceHook';
import {
	Buttons,
	Footer,
	TableContent,
	Header,
	Modal,
	Overlay,
	Container,
	Content,
	SearchInput,
	InputContainer,
	SelectContainer
} from './styled';
import Loader from '../../components/Loader';


const DoctorList = () => {
	const TableHeadings = ['ID', 'First Name', 'Last Name', 'User Name', 'Email', 'Number', 'Hospitals', ''];
	const TableFields = ['id', 'first_name', 'last_name', 'username', 'email', 'mobile_number', 'hospitals'];
	const TableActions = [
		{
			'name': 'reset_password',
			'handleClick': (item) => handleResetPassword(item.username),
			'size': 'xs',
			'variant': 'warning',
			'title': 'Rest Password',
			'text': <GiCombinationLock />
		},
		{
			'name': 'edit',
			'handleClick': (item) => handleEdit(item.id),
			'size': 'xs',
			'variant': 'primary',
			'title': 'Edit',
			'text': <AiOutlineEdit />
		},
		{
			'name': 'delete',
			'handleClick': (item) => handleDelete(item.id),
			'size': 'xs',
			'variant': 'danger',
			'title': 'Delete',
			'text': <AiOutlineDelete />
		}
	];
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { doctorList, assignHospitalList, isDoctorDeletedSuccess, doctorData, loading, resetPasswordResponse, resetPasswordErrorMsg } = useSelector(state => state.doctors);
	const [currentItems, setCurrentItems] = useState([]);
	const [pageCount, setPageCount] = useState(0);
	const [deleteMsg, setDeleteMsg] = useState({ show: false, id: null });
	const [resetPassword, setResetPassword] = useState({ show: false, msg: null });
	const [options, setOptions] = useState([{ value: 'all', label: 'All' }]);
	const [hospital, setHospital] = useState(options[0]);
	const [searchHospitalName, setSearchHospitalName] = useState('');
	const [page, setPage] = useState(1);
	const debouncedSearchTerm = useDebounce(searchHospitalName, 500);

	useEffect(() => {
		dispatch(getAssignHospitalList());
	}, []);

	useEffect(() => {
		if (!isEmpty(resetPasswordResponse)) {
			setResetPassword({ show: true, msg: resetPasswordResponse?.message });
		}
		if (!isEmpty(resetPasswordErrorMsg)) {
			setResetPassword({ show: true, msg: resetPasswordErrorMsg?.message });
		}
	}, [resetPasswordResponse, resetPasswordErrorMsg]);

	useEffect(() => {
		if (assignHospitalList.length > 0) {
			const getOption = assignHospitalList.map((item) => {
				return { value: item.id, label: item.name };
			});
			getOption.splice(0, 0, { value: 'all', label: 'All' });
			setOptions(getOption);
		}
	}, [assignHospitalList]);

	useEffect(() => {
		setCurrentItems(doctorList);
		doctorList.length ? setPageCount(doctorData.last_page) : setPageCount(0);
	}, [doctorList]);

	useEffect(() => {
		if (isDoctorDeletedSuccess) {
			dispatch(getDoctors({ page: page }));
		}
	}, [isDoctorDeletedSuccess]);

	useEffect(() => {
		if (hospital.value !== 'all' && debouncedSearchTerm == '') {
			dispatch(getDoctors({ page: page, hospital_id: hospital.value }));
		}
		else if (hospital.value !== 'all' && debouncedSearchTerm !== '') {
			dispatch(getDoctors({ page: page, hospital_id: hospital.value, search: debouncedSearchTerm }));
		}
		else if (hospital.value === 'all' && debouncedSearchTerm !== '') {
			dispatch(getDoctors({ page: page, search: debouncedSearchTerm }));
		}
		else {
			dispatch(getDoctors({ page: page }));
		}
	}, [page, hospital, debouncedSearchTerm]);

	const handleEdit = (id) => {
		navigate(`/dr-management/editdoctor/${id}`);
	};

	const handleResetPassword = async(username) => {
		const body = { username: username };
		dispatch(forgetDoctorPassword({ body }));
	};
	const handleDelete = (id) => {
		setDeleteMsg({
			show: true,
			id,
		});
	};
	const hendleDeleteYes = async() => {
		if (deleteMsg.show && deleteMsg.id) {
			const body = { user_id: deleteMsg.id };
			dispatch(deleteDoctor({ body }));
			setDeleteMsg({
				show: false,
				id: null,
			});
		}
	};
	const handlePageClick = (event) => {
		const pageNum = event.selected + 1;
		setPage(pageNum);
	};

	const handleByHospitalList = (event) => {
		setHospital(event);
		setPage(1);
	};
	const handleSearchByName = (event) => {
		const inputVal = event.target.value;
		setSearchHospitalName(inputVal);
		setPage(1);
	};

	return (
		<Container>
			<Content>
				<Header>
					<Typography
						variant="h4"
						color="black"
						weight="500"
					>
						{t('DOCTORLIST.HEADING')}
					</Typography>
				</Header>
				<SearchInput>
					<SelectContainer>
						<SelectDropdown
							options={options}
							placeholder={t('DOCTORLIST.PLACEHOLDER.SELECTHOSPITAL')}
							onChange={handleByHospitalList}
							value={hospital}
						/>
					</SelectContainer>
					<InputContainer>
						<Input
							placeholder={t('DOCTORLIST.PLACEHOLDER.SEARCH')}
							value={searchHospitalName}
							onChange={handleSearchByName} />
					</InputContainer>
				</SearchInput>
				{loading && <Overlay><Loader /></Overlay>}
				<TableContent>
					<Table tableHeading={TableHeadings} tableFields={TableFields} tableData={currentItems} tableActions={TableActions} />
					<Footer>
						<ReactPaginate
							breakLabel="..."
							nextLabel={<HiChevronDoubleRight />}
							onPageChange={handlePageClick}
							pageRangeDisplayed={5}
							pageCount={pageCount}
							previousLabel={<HiChevronDoubleLeft />}
							forcePage={pageCount === 0 ? -1 : page - 1}
						/>
					</Footer>
				</TableContent>
			</Content>
			{deleteMsg.show ?
				<Overlay>
					<Modal>
						<Typography
							variant="h4"
							color="black"
						>
							{t('DOCTORLIST.DELETECONFIRM')}
						</Typography>
						<Buttons>
							<Button title={t('DOCTORLIST.BTNNO')} onClick={() => setDeleteMsg(false)} size={'sm'}>{t('DOCTORLIST.BTNNO')}</Button>
							<Button title={t('DOCTORLIST.BTNYES')} size={'sm'} variant={'danger'} onClick={hendleDeleteYes}>{t('DOCTORLIST.BTNYES')}</Button>
						</Buttons>
					</Modal>
				</Overlay>
				: null}
			{resetPassword.show ?
				<Overlay>
					<Modal>
						<Typography
							variant="h4"
							color="black"
						>
							{resetPassword.msg}
						</Typography>
						<Buttons>
							<Button title={t('DOCTORLIST.BTNOK')} onClick={() => setResetPassword({ show: false, msg: null })} size={'sm'}>{t('DOCTORLIST.BTNOK')}</Button>
						</Buttons>
					</Modal>
				</Overlay>
				: null}
		</Container>
	);
};

export default DoctorList;