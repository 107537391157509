import React from 'react';
import { any, bool, func, number, string } from 'prop-types';
import { Wrapper, Alert } from './styled.js';

const TextArea = ({
	onKeyDown,
	onChange,
	error,
	value,
	placeholder,
	autoFocus,
	disabled,
	name,
	errormessage,
	maxLength,
	onBlur,
	themeType,
	required,
	readOnly,
	rows,
	...rest
}) => {
	const attributes = {
		onKeyDown,
		onChange,
		error,
		disabled,
		placeholder,
		autoFocus,
		name,
		errormessage,
		maxLength,
		onBlur,
		value,
		readOnly,
		required,
		rows
	};
	return (
		<Wrapper {...rest}>
			{themeType === 0 && <textarea {...attributes} className={`texarea ${(errormessage != undefined && error) ? 'error' : ''}`} />
			}
			{error && <Alert>{errormessage}</Alert>}
		</Wrapper>
	);
};

TextArea.propTypes = {
	onChange: func,
	errorMessage: string,
	value: any,
	disabled: bool,
	placeholder: string,
	name: string,
	themeType: number,
	maxLength: number,
	autoFocus: bool,
	error: number,
	rows: number,
};

TextArea.defaultProps = {
	themeType: 0,
	rows: 2,
};

export default TextArea;