import React, { useEffect, useState } from 'react';
import { t } from 'i18next';
import ReactPaginate from 'react-paginate';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { HiChevronDoubleLeft, HiChevronDoubleRight } from 'react-icons/hi';
import { FaRupeeSign } from 'react-icons/fa';
import Typography from '../../components/Typography';
import Input from '../../components/Input';
import Table from '../../components/Table';
import Loader from '../../components/Loader';
import useDebounce from '../../utils/useDebounceHook';
import { getBilling } from '../../redux/plan';
import {
	Footer,
	TableContent,
	Header,
	Container,
	Content,
	SearchInput,
	InputContainer,
	SelectContainer,
} from './styled';
import SelectDropdown from '../../components/SelectDropdown';

const BillingList = () => {
	const TableHeadings = ['Hospital', 'Plan Name', 'Connects', 'Purchased On', 'Expired On', 'Connect Status', 'Payment Status', ''];
	const TableFields = ['hospital_name', 'plan_name', 'connect', 'purchased_on', 'expired_on', 'connect_status', 'payment_status'];
	const TableActions = [
		{
			'name': 'billing_detail',
			'handleClick': (item) => handleDetail(item.hospital_id),
			'size': 'xs',
			'variant': 'primary',
			'title': 'Billing Detail',
			'text': <FaRupeeSign />
		}
	];
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { billingList, billingData, loading } = useSelector(state => state.plan);
	const [currentItems, setCurrentItems] = useState([]);
	const [pageCount, setPageCount] = useState(0);
	const [searchUserName, setSearchUserName] = useState('');
	const debouncedSearchTerm = useDebounce(searchUserName, 500);
	const [page, setPage] = useState(1);
	const options = [{ value: '', label: 'All' }, { value: 'active', label: 'Active' }, { value: 'expired', label: 'Expired' }];
	const [status, setStatus] = useState(options[0]);

	useEffect(() => {
		if (billingList?.length) {
			setCurrentItems(billingList);
			billingList.length ? setPageCount(billingData.last_page) : setPageCount(0);
		}
		else {
			setCurrentItems([]);
		}
	}, [billingList]);

	useEffect(() => {
		if (debouncedSearchTerm == '' && status.value == '') {
			dispatch(getBilling({ page: page }));
		}
		else if (debouncedSearchTerm !== '' && status.value == '') {
			dispatch(getBilling({ page: page, search: debouncedSearchTerm }));
		}
		else if (debouncedSearchTerm !== '' && status.value != '') {
			dispatch(getBilling({ page: page, search: debouncedSearchTerm, filter: status.value }));
		}
		else if (debouncedSearchTerm == '' && status.value != ''){
			dispatch(getBilling({ page: page, filter: status.value }));
		}
	}, [page, debouncedSearchTerm, status]);


	const handleDetail = (id) => {
		navigate(`/hospital-management/${id}/billing/${page}`);
	};
	const handleStatusChange = (event) => {
		setStatus(event);
		setPage(1);
	};
	const handlePageClick = (event) => {
		const pageNum = event.selected + 1;
		setPage(pageNum);
	};

	const handleSearchByName = (event) => {
		const inputVal = event.target.value;
		setSearchUserName(inputVal);
		setPage(1);
	};
	return (
		<Container>
			<Content>
				<Header>
					<Typography
						variant="h4"
						color="black"
						weight="500"
					>
						{t('BILLINGLIST.HEADING')}
					</Typography>
				</Header>
				<SearchInput>
					<SelectContainer>
						<SelectDropdown
							options={options}
							placeholder={t('DOCTORLIST.PLACEHOLDER.SELECTHOSPITAL')}
							onChange={handleStatusChange}
							value={status}
						/>
					</SelectContainer>
					<InputContainer>
						<Input placeholder={t('BILLINGLIST.PLACEHOLDER.INPUT')} onChange={handleSearchByName} value={searchUserName} />
					</InputContainer>
				</SearchInput>
				<TableContent>
					{loading ? <Loader /> : <Table tableHeading={TableHeadings} tableFields={TableFields} tableData={currentItems} tableActions={TableActions} />}
					<Footer>
						<ReactPaginate
							breakLabel="..."
							nextLabel={<HiChevronDoubleRight />}
							onPageChange={handlePageClick}
							pageRangeDisplayed={5}
							pageCount={pageCount}
							previousLabel={<HiChevronDoubleLeft />}
							forcePage={pageCount === 0 ? -1 : page - 1}
						/>
					</Footer>
				</TableContent>
			</Content>
		</Container>
	);
};

export default BillingList;