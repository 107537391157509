import React, { useEffect, useState } from 'react';
import { t } from 'i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Formik, Form } from 'formik';
import find from 'lodash/find';
import { updatePlan, getEditPlan } from '../../redux/plan';
import { editPlanValidationSchema } from '../../utils/validation';
import Typography from '../../components/Typography';
import Button from '../../components/Button';
import Input from '../../components/Input';
import SelectDropdown from '../../components/SelectDropdown';
import {
	Wrapper,
	Header,
	Container,
	InputField,
	Forms
} from './styled';

const EditPlan = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const params = useParams();
	const editPlainId = params.id;
	const [res, setRes] = useState({});
	const { updatePlanErrrorMsg, editPlanData, isFetchEditPlanSuccess, isPlanUpdateSuccess } = useSelector(state => state.plan);
	const [initData, setInitData] = useState({
		name: '',
		cost: '',
		days: '',
		carryforward: '',
		howmuchcf:'',
		status: '',
		type: '',
		connect: '',
		autorecharge:''
	});
	useEffect(() => {
		dispatch(getEditPlan({ plan_id: editPlainId }));
	}, [editPlainId]);

	useEffect(() => {
		if (isPlanUpdateSuccess) {
			setRes(res);
			navigate('/plan/list');
		}
		else if (updatePlanErrrorMsg) {
			// setResErrors(planAddErrorMsg);
		}
	}, [isPlanUpdateSuccess, updatePlanErrrorMsg]);

	const findSelectedOption = (options, value) => {
		const field = find(options, { value: value });
		return field ? field : null;
	};
	useEffect(() => {
		if (isFetchEditPlanSuccess) {
			setInitData(() => {
				return {
					name: editPlanData.plan_name,
					cost: editPlanData.cost,
					days: editPlanData.days,
					connect: editPlanData.connect,
					carryforward: findSelectedOption([{ value: 1, label: 'Yes' }, { value: 0, label: 'No' }], editPlanData.carry_forward),
					howmuchcf: editPlanData.how_much_carry_forward,
					autorecharge: findSelectedOption([{ value: 1, label: 'Yes' }, { value: 0, label: 'No' }], editPlanData.auto_recharge),
					status: findSelectedOption([{ value: 'active', label: 'Active' }, { value: 'inactive', label: 'Inactive' }], editPlanData.status),
				};
			});
		}
	}, [isFetchEditPlanSuccess, editPlanData]);
	const handleEditPlan = async({ name, cost, days, carryforward, howmuchcf, status, connect, autorecharge }) => {
		const body = {
			plan_id: editPlainId,
			plan_name:name,
			cost:cost,
			days:days,
			carry_forward:carryforward.value,
			how_much_carry_forward:howmuchcf,
			status:status.value,
			connect:connect,
			auto_recharge:autorecharge.value
		};
		dispatch(updatePlan({ body }));
	};

	return (
		<Wrapper>
			<Forms>
				<Header>
					<Typography
						variant="h4"
						color="black"
						weight="500"
					>
						{t('EDITPLAN.HEADING')}
					</Typography>
				</Header>
				<Formik
					initialValues={initData}
					validationSchema={editPlanValidationSchema(t)}
					onSubmit={handleEditPlan}
					enableReinitialize={true}
				>
					{({ values, errors, touched, handleChange, handleBlur, setFieldValue }) => (
						<Form>
							<Container>
								<InputField>
									<Input
										name='name'
										placeholder={t('EDITPLAN.PLACEHOLDER.NAME')}
										type='text'
										onChange={handleChange}
										onBlur={handleBlur}
										value={values.name}
										error={touched.name && 1}
										errormessage={errors.name} />
								</InputField>
								<InputField>
									<Input
										name='cost'
										placeholder={t('EDITPLAN.PLACEHOLDER.COST')}
										type='text'
										onChange={handleChange}
										onBlur={handleBlur}
										value={values.cost}
										error={touched.cost && 1}
										errormessage={errors.cost} />
								</InputField>
								<InputField>
									<Input
										name='days'
										placeholder={t('EDITPLAN.PLACEHOLDER.DAYS')}
										type='number'
										onChange={handleChange}
										onBlur={handleBlur}
										value={values.days}
										error={touched.days && 1}
										errormessage={errors.days} />
								</InputField>
								<InputField>
									<Input
										name='connect'
										placeholder={t('EDITPLAN.PLACEHOLDER.CONNECT')}
										type='number'
										onChange={handleChange}
										onBlur={handleBlur}
										value={values.connect}
										error={touched.connect && 1}
										errormessage={errors.connect} />
								</InputField>
								<InputField>
									<SelectDropdown
										name="carryforward"
										options={[{ value: '1', label: 'Yes' }, { value: '0', label: 'No' }]}
										placeholder={t('EDITPLAN.PLACEHOLDER.CARRYFORWARD')}
										onBlur={handleBlur}
										onChange={(e) => setFieldValue('carryforward', e)}
										value={values.carryforward}
										error={touched.carryforward && 1}
										errormessage={errors.carryforward}
									/>
								</InputField>
								{values.carryforward?.value === '1' && <InputField>
									<Input
										name="howmuchcf"
										placeholder={t('EDITPLAN.PLACEHOLDER.HOWMUCHCF')}
										type='number'
										onChange={handleChange}
										onBlur={handleBlur}
										value={values.howmuchcf}
										error={touched.howmuchcf && 1}
										errormessage={errors.howmuchcf}
									/>
								</InputField>}
								<InputField>
									<SelectDropdown
										name="autorecharge"
										options={[{ value: '1', label: 'Yes' }, { value: '0', label: 'No' }]}
										placeholder={t('EDITPLAN.PLACEHOLDER.AUTORECHARGE')}
										onBlur={handleBlur}
										onChange={(e) => setFieldValue('autorecharge', e)}
										value={values.autorecharge}
										error={touched.autorecharge && 1}
										errormessage={errors.autorecharge}
									/>
								</InputField>
								<InputField>
									<SelectDropdown
										name="status"
										options={[{ value: 'active', label: 'Active' }, { value: 'inactive', label: 'Inactive' }]}
										placeholder={t('EDITPLAN.PLACEHOLDER.STATUS')}
										onBlur={handleBlur}
										onChange={(e) => setFieldValue('status', e)}
										value={values.status}
										error={touched.status && 1}
										errormessage={errors.status}
									/>
								</InputField>
							</Container>
							<Button title={t('EDITPLAN.BTNTEXT')} type='submit' size={'sm'} >{t('EDITPLAN.BTNTEXT')}</Button>
						</Form>
					)}
				</Formik>
			</Forms>
		</Wrapper>
	);
};

export default EditPlan;