import styled from '@emotion/styled';
import { Link } from 'react-router-dom';

export const OverLay = styled.div`
    position: fixed;
    background: transparent;
    width: 100%;
    min-height: 100vh;
    top: 0;
    left: 0;
    z-index: 9;
`;

export const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    box-shadow: 0px 4px 5px 1px ${(props) => props.theme.colors.shadow}10;
    background-color: ${(props) => props.theme.colors.light};
    padding: 12px 15px;
    transition: all 0.5s;
    transform-origin: top top;
    position: ${({ active }) => active ? 'sticky;' : 'relative;'};
    top: ${({ active }) => active ? '0;' : 'auto;'};
    margin: ${({ active }) => active ? '0;' : '20px 30px 0px 30px;'};
    z-index: 3;
    border-radius: ${({ active }) => active ? '0px;' : '20px;'};
    width: ${({ active }) => active ? '100%;' : 'calc(100% - 60px);'};
    ${(props) => props.theme.breakPoint.mobile} {
        margin: ${({ active }) => active ? '0;' : '16px 16px 0px 16px;'};
        border-radius: ${({ active }) => active ? '0px;' : '15px;'};
        width: ${({ active }) => active ? '100%;' : 'calc(100% - 32px);'};
    }
`;

export const Content = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    ${(props) => props.theme.breakPoint.tabletLandscape} {
        justify-content: space-between;
        width: 100%;
    }
    ${(props) => props.theme.breakPoint.mobileAndTablet} {
        width: 100%;
        justify-content: space-between;
    }
`;

export const ToggleButtons = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
`;

export const Hamburger = styled.button`
    display: none;
    cursor: pointer;
    ${(props) => props.theme.breakPoint.tabletLandscape} {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 32px;
        background: transparent;
        outline: none; 
        border: none;
    }
    ${(props) => props.theme.breakPoint.mobileAndTablet} {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 32px;
        background: transparent;
        outline: none; 
        border: none;
    }
`;

export const HamburgerIcon = styled.div`
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background-color: ${(props) => props.theme.colors.primary};
`;

export const Profile = styled.div`
    width: 50px;
    height: 50px;
    border: 2px solid ${(props) => props.theme.colors.primary};
    border-radius: 100%;
    padding: 0px;
    z-index: 10;
    overflow: hidden;
    cursor: pointer;
    ${(props) => props.theme.breakPoint.mobile} {
        width: 40px;
        height: 40px;
        border-width: 1px;
    }
`;

export const UserImg = styled.img`
    width: 100%;
    height: 100%;
    height: auto;
`;

export const Modal = styled.div`
    display: block;
    position: absolute;
    font-size: 14px;
    width: 400px;
    top: 90px;
    right: 0px;
    z-index: 20;
    overflow: hidden;
    border: 0px;
    border-radius: 10px;
    color: ${(props) => props.theme.colors.lightBlack};
    background-color: ${(props) => props.theme.colors.light};
    box-shadow: 0px 4px 5px 1px ${(props) => props.theme.colors.shadow}60;
    ${(props) => props.theme.breakPoint.mobile} {
        right: 5px;
        width: 290px;
        top: 70px;
    }
    .flex {
        display: flex;
        flex-wrap: wrap;
    }
    .ac {
        align-items: center;
    }
    .row {
        display: flex;
    }
    .w100 {
        width: 100%;
    }
`;

export const ModalTop = styled.div`
    padding: 15px;
    background-color: ${(props) => props.theme.colors.primary};
    color: ${(props) => props.theme.colors.light};
`;

export const ModalBottom = styled.div`
    padding-bottom: 16px;
    max-height: 310px;
    overflow-y: auto;
    text-align: center;
`;

export const MenuItems = styled.ul`
    padding: 0;
    margin: 0px 0px 16px 0px;
`;

export const ListItem = styled.li`
    list-style-type: none;
    width: 100%;
    align-items: center;
    text-decoration: none;
    transition: all 0.3s;
    position: relative;
    border-bottom: 1px solid ${(props) => props.theme.colors.primary};
`;

export const ImgContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 48px;
    background-color: ${(props) => props.theme.colors.primaryLight};
    border-radius: 100%;
    margin-right: 16px;
    overflow: hidden;
    object-fit: cover;
    opacity: 1;
    svg {
        width: 18px;
        height: 18px;
        fill: ${(props) => props.theme.colors.primary};
    }
`;

export const ItemLink = styled(Link)`
    width: 100%;
    text-decoration: none;
    padding: 16px;
    color: ${(props) => props.theme.colors.primary};
    &:hover {
        background-color: ${(props) => props.theme.colors.primaryLight};
    }
`;