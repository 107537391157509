import React, { useEffect, useState } from 'react';
import { t } from 'i18next';
import ReactPaginate from 'react-paginate';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { HiChevronDoubleLeft, HiChevronDoubleRight } from 'react-icons/hi';
import { AiOutlineEdit, AiOutlineDelete } from 'react-icons/ai';
import { RiFileExcel2Line } from 'react-icons/ri';
import { FiFileText } from 'react-icons/fi';
import { FaRupeeSign } from 'react-icons/fa';
import { BsToggles } from 'react-icons/bs';
import Button from '../../components/Button';
import Typography from '../../components/Typography';
import Input from '../../components/Input';
import Table from '../../components/Table';
import PageTitle from '../../components/PageTitle';
import SimpleTable from '../../components/SimpleTable';
import useDebounce from '../../utils/useDebounceHook';
import { getHospital, deleteHospital, updateStatusHospital, getPatientDetails } from '../../redux/hospital';
import {
	Buttons,
	Footer,
	TableContent,
	Header,
	Modal,
	Overlay,
	Container,
	Content,
	SearchSelect,
	InputContainer,
} from './styled';

const HospitalList = () => {
	const TableHeadings = ['ID', 'Hospital Name', 'Contact No.', 'Address', 'City', 'State', 'Zip Code', 'ICU Bed\'s', 'G.Ward Bed\'s', 'Admitted Patients', 'Customer', 'Status', ''];
	const TableFields = ['id', 'name', 'telephone', 'address', 'city', 'state', 'zip', 'icu_beds', 'ward_beds', 'admitted_patients', 'doctor', 'status'];
	const TableFormatFields = [
		{
			'name': 'name',
			'handleClick': (item) => handleView(item.id),
			'variant': 'primary',
			'text': (item) => item.name
		},
		{
			'name': 'admitted_patients',
			'handleClick': (item) => handleAdmittedPatient(item.id),
			'variant': 'primary',
			'text': (item) => item.admitted_patients
		}
	];
	const TableActions = [
		{
			'name': 'access',
			'handleClick': (item) => handleAccess(item.id, (item.status == 'active' ? 'inactive' : 'active')),
			'size': 'xs',
			'variant': 'primary',
			'title': 'Status',
			'text': <BsToggles />,
		},
		{
			'name': 'plans',
			'handleClick': (item) => handleHospitalPlan(item.id),
			'size': 'xs',
			'variant': 'success',
			'title': 'Plans',
			'text': <FaRupeeSign />
		},
		{
			'name': 'forms',
			'handleClick': (item) => handleHospitalForm(item.id),
			'size': 'xs',
			'variant': 'warning',
			'title': 'Hospital Forms',
			'text': <FiFileText />
		},
		{
			'name': 'importexport',
			'handleClick': (item) => handleImportExportForm(item.id),
			'size': 'xs',
			'variant': 'success',
			'title': 'Import/Export Forms',
			'text': <RiFileExcel2Line />
		},
		{
			'name': 'edit',
			'handleClick': (item) => handleEdit(item.id),
			'size': 'xs',
			'variant': 'primary',
			'title': 'Edit',
			'text': <AiOutlineEdit />
		},
		{
			'name': 'delete',
			'handleClick': (item) => handleDelete(item.id),
			'size': 'xs',
			'variant': 'danger',
			'title': 'Delete',
			'text': <AiOutlineDelete />
		}
	];
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { hospitalList, isHospitalDeletedSuccess, hospitalData, isHospitalStatusChangeSuccess, patientList } = useSelector(state => state.hospital);
	const [currentItems, setCurrentItems] = useState([]);
	const [pageCount, setPageCount] = useState(0);
	const [searchUserName, setSearchUserName] = useState('');
	const [deleteMsg, setDeleteMsg] = useState({ show: false, id: null });
	const debouncedSearchTerm = useDebounce(searchUserName, 500);
	const [page, setPage] = useState(1);
	const [patientDetails, setPatientDetails] = useState({ show: false, list: [] });

	useEffect(() => {
		setCurrentItems(hospitalList);
		hospitalList.length ? setPageCount(hospitalData.last_page) : setPageCount(0);
	}, [hospitalList]);


	useEffect(() => {
		if (patientList.length) {
			const plist = patientList?.map((patient) => {
				return { name : patient.patient_name,  value : patient.date_of_admission };
			});
			setPatientDetails({
				show: true,
				list : plist
			});
		}
	}, [patientList]);

	useEffect(() => {
		if (debouncedSearchTerm !== '') {
			dispatch(getHospital({ page: page, search: debouncedSearchTerm }));
		}
		else {
			dispatch(getHospital({ page: page }));
		}
	}, [page, debouncedSearchTerm, isHospitalStatusChangeSuccess]);

	useEffect(() => {
		if (isHospitalDeletedSuccess) {
			dispatch(getHospital({ page: page }));
		}
	}, [isHospitalDeletedSuccess]);

	const handleAccess = (id, newStatus) => {
		const body = { hospital_id: id, status: newStatus };
		dispatch(updateStatusHospital({ body }));
	};
	const handleView = (id) => {
		navigate(`/hospital-management/viewhospital/${id}/${page}`);
	};

	const handleAdmittedPatient = (id) => {
		dispatch(getPatientDetails({ hospital_id: id }));
	};

	const handleEdit = (id) => {
		navigate(`/hospital-management/edithospital/${id}/${page}`);
	};

	const handleHospitalPlan = (id) => {
		navigate(`/hospital-management/${id}/billing/${page}`);
	};
	const handleHospitalForm = (id) => {
		navigate(`/hospital-management/${id}/formlist/${page}`);
	};
	const handleImportExportForm = (id) => {
		navigate(`/hospital-management/import-form/${id}/${page}`);
	};
	const handleDelete = (id) => {
		setDeleteMsg({
			show: true,
			id,
		});
	};

	const hendleDeleteYes = () => {
		if (deleteMsg.show && deleteMsg.id) {
			const body = { hospital_id: deleteMsg.id };
			dispatch(deleteHospital({ body }));
			setDeleteMsg({
				show: false,
				id: null,
			});
		}
	};

	const handlePageClick = (event) => {
		const pageNum = event.selected + 1;
		setPage(pageNum);
	};

	const handleSearchByName = (event) => {
		const inputVal = event.target.value;
		setSearchUserName(inputVal);
		setPage(1);
	};

	return (
		<Container>
			<Content>
				<Header>
					<Typography
						variant="h4"
						color="black"
						weight="500"
					>
						{t('HOSPITALLIST.HEADING')}
					</Typography>
				</Header>
				<SearchSelect>
					<InputContainer>
						<Input placeholder={t('HOSPITALLIST.PLACEHOLDER.INPUT')} onChange={handleSearchByName} value={searchUserName} />
					</InputContainer>
				</SearchSelect>

				<TableContent>
					<Table tableHeading={TableHeadings} tableFields={TableFields} tableData={currentItems} tableActions={TableActions} tableFormatFields={TableFormatFields} />
					<Footer>
						<ReactPaginate
							breakLabel="..."
							nextLabel={<HiChevronDoubleRight />}
							onPageChange={handlePageClick}
							pageRangeDisplayed={5}
							pageCount={pageCount}
							previousLabel={<HiChevronDoubleLeft />}
							forcePage={pageCount === 0 ? -1 : page - 1}
						/>
					</Footer>
				</TableContent>
			</Content>
			{deleteMsg.show ?
				<Overlay>
					<Modal>
						<Typography
							variant="h4"
							color="black"
						>
							{t('HOSPITALLIST.DELETECONFIRM')}
						</Typography>
						<Buttons>
							<Button title={t('HOSPITALLIST.BTNNO')} onClick={() => setDeleteMsg(false)} size={'sm'}>{t('HOSPITALLIST.BTNNO')}</Button>
							<Button title={t('HOSPITALLIST.BTNYES')} onClick={hendleDeleteYes} size={'sm'} variant={'danger'}>{t('HOSPITALLIST.BTNYES')}</Button>
						</Buttons>
					</Modal>
				</Overlay>
				: null}
			{patientDetails.show ?
				<Overlay>
					<Modal>
						<PageTitle variant="h4" color="black">
							{t('HOSPITALLIST.ADMITTEDPATIENTS')}
						</PageTitle>
						<SimpleTable tableFields={['name', 'value']} tableData={patientDetails.list} ></SimpleTable>
						<Buttons>
							<Button style={{ marginTop: '10px' }} variant={'danger'} title={t('HOSPITALLIST.CANCEL')} onClick={() => setPatientDetails({ show: false, list : [] })} size={'sm'}>{t('HOSPITALLIST.CANCEL')}</Button>
						</Buttons>
					</Modal>
				</Overlay>
				: null}
		</Container>
	);
};

export default HospitalList;