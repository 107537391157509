import React, { useEffect, useState } from 'react';
import { t } from 'i18next';
import ReactPaginate from 'react-paginate';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { HiChevronDoubleLeft, HiChevronDoubleRight } from 'react-icons/hi';
import { FaCheckCircle } from 'react-icons/fa';
import { AiOutlineEye } from 'react-icons/ai';
import { Formik, Form } from 'formik';
import find from 'lodash/find';
import Button from '../../components/Button';
import Typography from '../../components/Typography';
import Input from '../../components/Input';
import Table from '../../components/Table';
import SimpleTable from '../../components/SimpleTable';
import PageTitle from '../../components/PageTitle';
import useDebounce from '../../utils/useDebounceHook';
import { addHospitalPlan, addPayment, getBilling, getPlanList } from '../../redux/plan';
import {
	Buttons,
	Footer,
	TableContent,
	Header,
	Modal,
	Overlay,
	Container,
	Content,
	SearchInput,
	InputContainer,
	InputField,
	Modal2,
	ButtonsAction,
} from './styled';
import SelectDropdown from '../../components/SelectDropdown';
import { addPaymentValidationSchema, subscribePlanValidationSchema } from '../../utils/validation';


const HospitalBillingList = () => {
	const TableHeadings = ['ID', 'Plan Name', 'Connects', 'Purchased On', 'Expired On', 'Connect Status', 'Payment Status', ''];
	const TableFields = ['billing_id', 'plan_name', 'connect', 'purchased_on', 'expired_on', 'connect_status', 'payment_status'];
	const TableActions = [
		{
			'name': 'mark_paid',
			'handleClick': (item) => handleMarkPaid(item.billing_id),
			'size': 'xs',
			'variant': 'success',
			'title': 'Mark as Paid',
			'text': <FaCheckCircle />
		},
		{
			'name': 'billing_preview',
			'handleClick': (item) => handleView(item.billing_id),
			'size': 'xs',
			'variant': 'warning',
			'title': 'Preview',
			'text': <AiOutlineEye />
		}
	];
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const params = useParams();
	const [plans, setPlans] = useState([]);
	const { billingList, billingData, planData, isHospitalPlanAddedSuccess, hospitalPlanAddErrorMsg, isPaymentPaidSuccess, addPaymentErrorMsg } = useSelector(state => state.plan);
	const [subscribeError, setSubscribeError] = useState('');
	const [hospitalName, setHospitalName] = useState('');
	const [currentItems, setCurrentItems] = useState([]);
	const [pageCount, setPageCount] = useState(0);
	const [searchUserName, setSearchUserName] = useState('');
	const [billingDetail, setBillingDetail] = useState({ show: false, id: null });
	const [addPlan, setAddPlan] = useState({ show: false });
	const [markPaid, setMarkPaid] = useState({ show: false, billingId: null, error: null });
	const debouncedSearchTerm = useDebounce(searchUserName, 500);
	const [page, setPage] = useState(1);

	useEffect(() => {
		dispatch(getPlanList({ all: 1 }));
	}, []);

	useEffect(() => {
		if (planData?.length) {
			setPlans(planData.map((plan) => ({
				value: plan.id,
				label: plan.plan_name
			})));
		}
	}, [planData]);

	useEffect(() => {
		if (billingList.length) {
			let newFormList = billingList.map((element) => {
				return ({ ...element });
			});
			setHospitalName(billingList[0]['hospital_name']);
			setCurrentItems(newFormList);
			billingList.length ? setPageCount(billingData.last_page) : setPageCount(0);
		}
		else {
			setCurrentItems([]);
			setPageCount(0);
		}
	}, [billingList]);

	useEffect(() => {
		if (debouncedSearchTerm !== '') {
			dispatch(getBilling({ page: page, hospital_id: params.id, search: debouncedSearchTerm }));
		}
		else {
			dispatch(getBilling({ page: page, hospital_id: params.id }));
		}
	}, [page, debouncedSearchTerm]);

	useEffect(() => {
		if (isHospitalPlanAddedSuccess) {
			setAddPlan(false);
			dispatch(getBilling({ page: page, hospital_id: params.id, search: debouncedSearchTerm }));
		}
		if (hospitalPlanAddErrorMsg && hospitalPlanAddErrorMsg.error) {
			setSubscribeError(hospitalPlanAddErrorMsg.message);
		}
		if (isPaymentPaidSuccess) {
			setMarkPaid({ show: false, billingId: null, error: null });
			dispatch(getBilling({ page: page, hospital_id: params.id, search: debouncedSearchTerm }));
		}
	}, [isHospitalPlanAddedSuccess, hospitalPlanAddErrorMsg, isPaymentPaidSuccess ]);

	useEffect(() => {
		if (addPaymentErrorMsg){
			setMarkPaid(prevState => ({ ...prevState, error: addPaymentErrorMsg }));
		}
	}, [addPaymentErrorMsg]);
	const handleView = (billingId) => {
		const plan = find(currentItems, { billing_id: billingId });
		setBillingDetail({
			show: true,
			...plan
		});
	};
	const handleMarkPaid = (billingId) => {
		setMarkPaid({ show: true, billingId: billingId, error: null });
	};

	const handlePageClick = (event) => {
		const pageNum = event.selected + 1;
		setPage(pageNum);
	};

	const handleSearchByName = (event) => {
		const inputVal = event.target.value;
		setSearchUserName(inputVal);
		setPage(1);
	};
	const handleSubscribePlan = async({ planId }) => {
		dispatch(addHospitalPlan({ body: { plan_id: planId.value, hospital_id: params.id }}));
	};
	const handleMarkPaymentPaid = async({ payDate, payMode }) => {
		const originalDate = new Date(payDate);
		const year = originalDate.getFullYear();
		const month = String(originalDate.getMonth() + 1).padStart(2, '0');
		const day = String(originalDate.getDate()).padStart(2, '0');
		const hours = String(originalDate.getHours()).padStart(2, '0');
		const minutes = String(originalDate.getMinutes()).padStart(2, '0');
		const seconds = String(originalDate.getSeconds()).padStart(2, '0');
		const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

		dispatch(addPayment({ body: { billing_id: markPaid.billingId, pay_date: formattedDate, pay_mode: payMode.value, hospital_id: params.id }}));
	};
	return (
		<Container>
			<Content>
				<Header>
					<Typography
						variant="h4"
						color="black"
						weight="500"
					>
						{hospitalName} {t('HOSPITALBILLINGLIST.HEADING')}
					</Typography>
				</Header>
				<SearchInput>
					<InputContainer>
						<Input placeholder={t('HOSPITALBILLINGLIST.PLACEHOLDER.INPUT')} onChange={handleSearchByName} value={searchUserName} />
					</InputContainer>
					<Button title={t('HOSPITALBILLINGLIST.BTNADD')} onClick={() => {
						setAddPlan({ show: true });
						setSubscribeError('');
					}
					} size={'xs'}>
						{t('HOSPITALBILLINGLIST.BTNADD')}
					</Button>
					<Button title={t('EDITHOSPITAL.BACKBUTTON')}
						onClick={() => navigate(-1)}
						size={'xs'}
					>
						{t('EDITHOSPITAL.BACKBUTTON')}
					</Button>
				</SearchInput>
				<TableContent>
					<Table tableHeading={TableHeadings} tableFields={TableFields} tableData={currentItems} tableActions={TableActions} />
					<Footer>
						<ReactPaginate
							breakLabel="..."
							nextLabel={<HiChevronDoubleRight />}
							onPageChange={handlePageClick}
							pageRangeDisplayed={5}
							pageCount={pageCount}
							previousLabel={<HiChevronDoubleLeft />}
							forcePage={pageCount === 0 ? -1 : page - 1}
						/>
					</Footer>
				</TableContent>
			</Content>
			{
				addPlan.show ?
					<Overlay>
						<Modal2>
							<PageTitle>
								{t('HOSPITALBILLINGLIST.SUBSCRIBEPLAN')}
							</PageTitle>
							<Formik initialValues={{ planId: '' }} validationSchema={subscribePlanValidationSchema(t)} onSubmit={handleSubscribePlan}>
								{({ errors, touched, handleBlur, setFieldValue }) => (
									<Form>
										<Container>
											<InputField>
												<SelectDropdown
													name="planId"
													options={plans}
													placeholder={t('HOSPITALBILLINGLIST.PLACEHOLDER.PLAN')}
													onBlur={handleBlur}
													onChange={(e) => setFieldValue('planId', e)}
													error={touched.planId || subscribeError}
													errormessage={errors.planId || subscribeError}
												/>
											</InputField>
										</Container>
										<ButtonsAction>
											<Button title={t('HOSPITALBILLINGLIST.BTNSUBSCRIBE')} type='submit' size={'sm'}>{t('HOSPITALBILLINGLIST.BTNSUBSCRIBE')}</Button>
											<Button title={t('HOSPITALBILLINGLIST.BTNNO')} variant={'danger'} onClick={() => setAddPlan(false)} size={'sm'}>{t('HOSPITALBILLINGLIST.BTNNO')}</Button>
										</ButtonsAction>
									</Form>
								)}
							</Formik>
						</Modal2>
					</Overlay>
					: null
			}
			{
				markPaid.show ?
					<Overlay>
						<Modal2>
							<PageTitle>
								{t('HOSPITALBILLINGLIST.PAYMENTDETAIL')}
							</PageTitle>
							{markPaid.error && <Typography
								variant="h6"
								color="red" >
								{markPaid.error}
							</Typography>
							}
							<Formik initialValues={{ payDate: '', payMode: '' }} validationSchema={addPaymentValidationSchema(t)} onSubmit={handleMarkPaymentPaid}>
								{({ errors, touched, handleBlur, handleChange, setFieldValue }) => (
									<Form>
										<Container>
											<InputField>
												<Input
													type='datetime-local'
													name="payDate"
													placeholder={t('PLANLIST.PLACEHOLDER.INPUT')}
													onChange={handleChange}
													onBlur={handleBlur}
													error={touched.payDate && 1}
													errormessage={errors.payDate}
												/>
												<SelectDropdown
													name="payMode"
													options={[
														{ value: 'Cash', label: 'Cash' },
														{ value: 'Cheque', label: 'Cheque' },
														{ value: 'NEFT', label: 'NEFT' },
														{ value: 'UPI', label: 'UPI' }
													]}
													placeholder={t('HOSPITALBILLINGLIST.PLACEHOLDER.PAYMENTMODE')}
													onBlur={handleBlur}
													onChange={(e) => setFieldValue('payMode', e)}
													error={(touched.payMode || subscribeError) && 1}
													errormessage={errors.payMode || subscribeError}
												/>
											</InputField>
										</Container>
										<ButtonsAction>
											<Button title={t('HOSPITALBILLINGLIST.BTNSUBSCRIBE')} type='submit' size={'sm'}>{t('HOSPITALBILLINGLIST.BTNPAID')}</Button>
											<Button title={t('HOSPITALBILLINGLIST.BTNNO')} variant={'danger'} onClick={() => setMarkPaid({ show: false, billingId: null, error: null })} size={'sm'}>{t('HOSPITALBILLINGLIST.BTNNO')}</Button>
										</ButtonsAction>
									</Form>
								)}
							</Formik>
						</Modal2>
					</Overlay>
					: null
			}
			{
				billingDetail.show ?
					<Overlay>
						<Modal>
							<PageTitle variant="h4" color="black">
								{t('HOSPITALBILLINGLIST.DETAIL')}
							</PageTitle>
							<SimpleTable tableFields={['name', 'value']} tableData={[
								{ 'name': 'Billing ID', 'value': billingDetail.billing_id },
								{ 'name': 'Plan ID', 'value': billingDetail.plan_id },
								{ 'name': 'Plan Name', 'value': billingDetail.plan_name },
								{ 'name': 'Cost', 'value': billingDetail.cost },
								{ 'name': 'Days', 'value': billingDetail.days },
								{ 'name': 'Connect', 'value': billingDetail.connect },
								{ 'name': 'Purchased On', 'value': billingDetail.purchased_on },
								{ 'name': 'Expired On', 'value': billingDetail.expired_on },
								{ 'name': 'Connect Status', 'value': billingDetail.connect_status },
								{ 'name': 'Payment Status', 'value': billingDetail.payment_status },
								{ 'name': 'Payment Date', 'value': billingDetail.pay_date },
								{ 'name': 'Payment Mode', 'value': billingDetail.pay_mode },
								{ 'name': 'Payment Received By', 'value': billingDetail.paid_by },
							]} ></SimpleTable>
							<Buttons>
								<Button style={{ marginTop: '10px' }} variant={'danger'} title={t('HOSPITALBILLINGLIST.BTNNO')} onClick={() => setBillingDetail(false)} size={'sm'}>{t('HOSPITALBILLINGLIST.BTNNO')}</Button>
							</Buttons>
						</Modal>
					</Overlay>
					: null
			}
		</Container >
	);
};

export default HospitalBillingList;