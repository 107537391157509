import React from 'react';
import { Wrapper, Loading } from './style';

export default function Loader() {
	return (
		<Wrapper>
			<Loading />
		</Wrapper>
	);
}
