import React, { useEffect, useState } from 'react';
import { t } from 'i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import SortableList, { SortableItem } from 'react-easy-sort';
import arrayMove from 'array-move';
import { AiOutlineDrag } from 'react-icons/ai';
import Typography from '../../components/Typography';
import Button from '../../components/Button';
import { getEditForm, updateFormFieldOrdering } from '../../redux/form';
import { Container, DraggableItem, Forms, Header, Row, Wrapper } from './styled';

const SortingForm = () => {
	const { editFormData, isFetchEditFormSuccess } = useSelector(state => state.form);

	const navigate = useNavigate();
	const dispatch = useDispatch();
	const params = useParams();
	const formId = params.id;
	const [rowsData, setRowsData] = useState([]);
	const [formName, setFormName] = useState('');
	useEffect(() => {
		dispatch(getEditForm({ form_id: formId }));
	}, [formId]);

	useEffect(() => {
		if (isFetchEditFormSuccess) {
			setFormName(editFormData.form_name);
			let datas = [];
			editFormData.fields.forEach(element => {
				datas.push({ id: element?.id, title: element?.title, field_name: element?.field_name });
			});
			setRowsData(datas);
		}
	}, [isFetchEditFormSuccess, editFormData]);
	const onSortEnd = (oldIndex, newIndex) => {
		setRowsData((array) => arrayMove(array, oldIndex, newIndex));
		const payload = {
			form_id: formId,
			fields: arrayMove(rowsData, oldIndex, newIndex).map(obj => obj.field_name)
		};
		dispatch(updateFormFieldOrdering(payload));
	};

	return (
		<Wrapper>
			<Forms>
				<Header>
					<Typography variant='h4' color='black' weight='500'>
						{t('SORTINGFORM.HEADING')} :: {formName}
					</Typography>
					<Button
						title={t('SORTINGFORM.BACKBUTTON')}
						onClick={() => navigate('/form-management/list')}
						size={'xs'}
					>
						{t('SORTINGFORM.BACKBUTTON')}
					</Button>
				</Header>
				<Container>
					<SortableList
						onSortEnd={onSortEnd}
						className="list"
						draggedItemClassName="dragged"
						style={{ width: '100%', textAlign: 'center' }}
					>
						{rowsData && rowsData.map((field) => (
							<SortableItem key={field.id}>
								<Row key={field.id}>
									<AiOutlineDrag></AiOutlineDrag>
									<DraggableItem>{field.title}</DraggableItem>
								</Row>
							</SortableItem>
						))}
					</SortableList>
				</Container>
			</Forms>
		</Wrapper >
	);
};

export default SortingForm;