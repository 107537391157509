import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Form, Formik } from 'formik';
import { t } from 'i18next';
import Typography from '../../components/Typography';
import Button from '../../components/Button';
import Input from '../../components/Input';
import FormFieldRow from '../../components/FormFieldRow';
import Loader from '../../components/Loader';
import { getEditForm, updateForm } from '../../redux/form';
import { useDispatch, useSelector } from 'react-redux';
import { editFormValidationSchema } from '../../utils/validation';
import { Container, Forms, Header, InputFullWidthField, Label, Wrapper } from './styled';
import SelectDropdown from '../../components/SelectDropdown';

const EditForm = () => {
	const { editFormData, isFetchEditFormSuccess, isFormUpdateSuccess, Loading } = useSelector(state => state.form);
	const formType = [
		{ value: 'main', 'label': 'Main' },
		{ value: 'sub', 'label': 'Sub' }
	];
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const params = useParams();
	const editFormId = params.id;
	const [rowsData, setRowsData] = useState([]);
	const [formName, setFormName] = useState('');
	const [type, setType] = useState('');
	// const [list, setList] = useState();
	useEffect(() => {
		dispatch(getEditForm({ form_id: editFormId }));
	}, [editFormId]);

	useEffect(() => {
		if (isFetchEditFormSuccess) {
			// setList(editFormData);
			setFormName(editFormData.form_name);
			setType(editFormData.type);
			let datas = [];
			let defaultFlags = [];
			editFormData.fields.forEach(element => {
				defaultFlags = parseFlag(element);
				datas.push({ ...element, ...defaultFlags });
			});
			setRowsData(datas);
		}
	}, [isFetchEditFormSuccess, editFormData]);
	const parseFlag = (element) => {
		let fetchOldData = element.fetch_old_data, formdataId = element.formdata_id, type = element.type;
		let flags = [];
		flags['showFetchOldata'] = false;
		flags['showSubTitle'] = false;
		flags['showThemeType'] = false;
		flags['showFormData'] = false;
		flags['showTimerType'] = false;
		flags['showFetchField'] = false;
		if (type === 'text') {
			flags['showFetchOldata'] = true;
			flags['showSubTitle'] = true;
		}
		else if (type === 'textarea') {
			flags['showThemeType'] = true;
			flags['showSubTitle'] = true;
		}
		else if (type === 'date') {
			flags['showSubTitle'] = true;
		}
		else if (type === 'datetime') {
			flags['showSubTitle'] = true;
		}
		else if (type === 'time') {
			flags['showSubTitle'] = true;
		}
		else if (type === 'number') {
			flags['showSubTitle'] = true;
		}
		else if (type === 'lab-report-values') {
			flags['showSubTitle'] = true;
		}
		else if (type === 'formdata') {
			flags['showFormData'] = true;
		}
		else if (type === 'subform') {
			flags['showFetchOldata'] = true;
			flags['showFormData'] = true;
			flags['showTimerType'] = true;
		}
		if (fetchOldData === 1) {
			flags['showFormData'] = true;
			flags['showFetchField'] = true;
		}
		else if (fetchOldData === 0) {
			flags['showFormData'] = false;
			flags['showFetchField'] = false;
		}
		if (formdataId) {
			flags['showFormData'] = true;
			flags['showFetchField'] = true;
		}
		else {
			flags['showFormData'] = false;
			flags['showFetchField'] = false;

		}
		return flags;
	};
	/* useEffect(() => {
		if (list) {
			setFormName(list.form_name);
			setRowsData(list.fields);
		}
	}, [list]); */
	const deleteTableRows = (index) => {
		const rows = [...rowsData];
		rows.splice(index, 1);
		setRowsData(rows);
	};
	const handleRowChange = (index, name, value) => {
		const rowsInput = [...rowsData];
		rowsInput[index] = { ...rowsInput[index], [name]: value };
		// rowsInput[index][name] = value;
		if (name === 'type') {
			rowsInput[index]['showFetchOldata'] = false;
			rowsInput[index]['showSubTitle'] = false;
			rowsInput[index]['showThemeType'] = false;
			rowsInput[index]['showFormData'] = false;
			rowsInput[index]['showTimerType'] = false;
			if (value === 'text') {
				rowsInput[index]['showFetchOldata'] = true;
				rowsInput[index]['showSubTitle'] = true;
			}
			else if (value === 'textarea') {
				rowsInput[index]['showThemeType'] = true;
				rowsInput[index]['showSubTitle'] = true;
			}
			else if (value === 'date') {
				rowsInput[index]['showSubTitle'] = true;
			}
			else if (value === 'datetime') {
				rowsInput[index]['showSubTitle'] = true;
			}
			else if (value === 'time') {
				rowsInput[index]['showSubTitle'] = true;
			}
			else if (value === 'number') {
				rowsInput[index]['showSubTitle'] = true;
			}
			else if (value === 'lab-report-values') {
				rowsInput[index]['showSubTitle'] = true;
			}
			else if (value === 'formdata') {
				rowsInput[index]['showFormData'] = true;
			}
			else if (value === 'subform') {
				rowsInput[index]['showFetchOldata'] = true;
				rowsInput[index]['showFormData'] = true;
				rowsInput[index]['showTimerType'] = true;
			}
		}
		else if (name === 'fetch_old_data') {
			if (value === 1) {
				rowsInput[index]['showFormData'] = true;
				rowsInput[index]['showFetchField'] = true;
			}
			else {
				rowsInput[index]['showFormData'] = false;
				rowsInput[index]['showFetchField'] = false;
			}
		}
		else if (name === 'formdata_id') {
			if (value) {
				rowsInput[index]['showFormData'] = true;
				rowsInput[index]['showFetchField'] = true;
			}
			else {
				rowsInput[index]['showFormData'] = false;
				rowsInput[index]['showFetchField'] = false;
			}
		}
		setRowsData(rowsInput);
	};
	useEffect(() => {
		if (isFormUpdateSuccess) {
			navigate('/form-management/list', { state: { page: params.page }});
		}
	}, [isFormUpdateSuccess]);

	const handleEditForm = ({ formName, type }) => {
		let formData = new FormData;
		formData.append('form_id', editFormId);
		formData.append('form_name', formName);
		formData.append('type', type?.value || type);
		for (let i = 0; i < rowsData.length; i++) {
			for (const property in rowsData[i]) {
				formData.append(`field[${i}][${property}]`, rowsData[i][property] || '');
			}
		}
		dispatch(updateForm({ body: formData }));
	};
	return (
		<Wrapper>
			<Forms>
				<Header>
					<Typography variant="h4" color="black" weight="500">
						{t('EDITFORM.HEADING')}
					</Typography>
					<Button
						title={t('EDITFORM.BACKBUTTON')}
						onClick={() => navigate('/form-management/list')}
						size={'xs'}
					>
						{t('EDITFORM.BACKBUTTON')}
					</Button>
				</Header>
				{Loading ? <Loader /> :
					<Formik initialValues={{ formName, type }} validationSchema={editFormValidationSchema(t)}
						enableReinitialize={true} onSubmit={handleEditForm} >
						{({ errors, values, touched, setFieldValue, handleChange, handleBlur }) => (
							<Form>
								<Container>
									<InputFullWidthField>
										<Label>
											{t('EDITFORM.PLACEHOLDER.FORM_NAME')}
										</Label>
										<Input
											placeholder={t('EDITFORM.PLACEHOLDER.FORM_NAME')}
											name='formName'
											type='text'
											onChange={handleChange}
											onBlur={handleBlur}
											error={touched.formName && 1}
											errormessage={errors.formName}
											value={values.formName}
										/>
									</InputFullWidthField>
									<InputFullWidthField>
										<Label>
											{t('EDITFORM.PLACEHOLDER.FORM_TYPE')}
										</Label>
										<SelectDropdown
											options={formType}
											placeholder={'Form Type'}
											name='type'
											value={formType.find(option => option.value === values.type)}
											onChange={(event) => setFieldValue('type', event)}
											onBlur={handleBlur}
										/>
									</InputFullWidthField>
								</Container>
								<FormFieldRow dispatch={dispatch} rowsData={rowsData} setRowsData={setRowsData}  deleteTableRows={deleteTableRows} handleRowChange={handleRowChange} />
								<Container>
									<Button title={t('EDITFORM.BTNTEXT')} type='submit' size={'sm'}>{t('EDITFORM.BTNTEXT')}</Button>
								</Container>
							</Form>
						)}
					</Formik>}
			</Forms>
		</Wrapper>
	);
};

export default EditForm;