import React, { Suspense } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ProtectedRoute } from './protectedRoute';
import { PublicRoute } from './publicRoute';
import Login from '../pages/auth/login';
import ForgotPassword from '../pages/auth/forgot-password';
import Dashboard from '../pages/dashboard';
import ResetPassword from '../pages/auth/reset-password';
import LayoutComponent from '../components/LayoutComponent';
import AddUser from '../pages/add-user';
import UserList from '../pages/user-list';
import EditUser from '../pages/edit-user';
import AddHospital from '../pages/add-hospital';
import EditHospital from '../pages/edit-hospital';
import HospitalList from '../pages/hospital-list';
import AddDoctor from '../pages/add-doctor';
import DoctorList from '../pages/doctor-list';
import EditDoctor from '../pages/edit-doctor';
import ErrorPage from '../pages/error-page';
import AuthProvider from './AuthProvider';
import FormList from '../pages/form-list';
import CreateForm from '../pages/create-form';
import EditForm from '../pages/edit-form';
import Profile from '../pages/profile';
import ImportForm from '../pages/import-form';
import ImportExportFormHospital from '../pages/import-export-form-hospital';
import HospitalFormList from '../pages/hospital-form-list';
import HospitalFormCreate from '../pages/hospital-form-add';
import HospitalFormEdit from '../pages/hospital-form-edit';
import PreviewForm from '../pages/preview-form';
import HospitalFormPreview from '../pages/hospital-form-preview';
import ViewUser from '../pages/view-user';
import ViewHospital from '../pages/view-hospital';
import AddPlan from '../pages/add-plan';
import PlanList from '../pages/plan-list';
import EditPlan from '../pages/edit-plan';
import HospitalPlanList from '../pages/hospital-plan-list';
import BillingList from '../pages/billing-list';
import HospitalBillingList from '../pages/hospital-billing-list';
import SortingForm from '../pages/sorting-form';
import HospitalFormSorting from '../pages/hospital-form-sorting';

const LoadPublicRouteComponent = ({ component }) => <PublicRoute>{component}</PublicRoute>;
const LoadProtectedRouteComponent = ({ component }) => <LayoutComponent><ProtectedRoute>{component}</ProtectedRoute></LayoutComponent>;

const RouterContainer = () => (
	<BrowserRouter>
		<Suspense fallback={null}>
			<AuthProvider>
				<Routes>
					<Route
						path="/login"
						element={<LoadPublicRouteComponent component={<Login />} />}
					/>
					<Route
						path="/forgot-password"
						element={<LoadPublicRouteComponent component={<ForgotPassword />} />}
					/>
					<Route
						path="/reset-password"
						element={<LoadPublicRouteComponent component={<ResetPassword />} />}
					/>
					<Route
						path="/"
						element={<LoadProtectedRouteComponent component={<Dashboard />} />}
					/>
					<Route
						path="/profile"
						element={<LoadProtectedRouteComponent component={<Profile />} />}
					/>
					<Route
						path="user-management/add"
						element={<LoadProtectedRouteComponent component={<AddUser />} />}
					/>
					<Route
						path="user-management/list"
						element={<LoadProtectedRouteComponent component={<UserList />} />}
					/>
					<Route
						path="user-management/edituser/:id/:page"
						element={<LoadProtectedRouteComponent component={<EditUser />} />}
					/>
					<Route
						path="user-management/viewuser/:id/:page"
						element={<LoadProtectedRouteComponent component={<ViewUser />} />}
					/>
					<Route
						path="dr-management/list"
						element={<LoadProtectedRouteComponent component={<DoctorList />} />}
					/>
					<Route
						path="dr-management/editdoctor/:id"
						element={<LoadProtectedRouteComponent component={<EditDoctor />} />}
					/>
					<Route
						path="dr-management/add"
						element={<LoadProtectedRouteComponent component={<AddDoctor />} />}
					/>
					<Route
						path="hospital-management/add"
						element={<LoadProtectedRouteComponent component={<AddHospital />} />}
					/>
					<Route
						path="hospital-management/edithospital/:id/:page"
						element={<LoadProtectedRouteComponent component={<EditHospital />} />}
					/>
					<Route
						path="hospital-management/viewhospital/:id/:page"
						element={<LoadProtectedRouteComponent component={<ViewHospital />} />}
					/>
					<Route
						path="hospital-management/list"
						element={<LoadProtectedRouteComponent component={<HospitalList />} />}
					/>
					<Route
						path="hospital-management/:id/formlist/:page"
						element={<LoadProtectedRouteComponent component={<HospitalFormList />} />}
					/>
					<Route
						path="hospital-management/:id/plans/:page"
						element={<LoadProtectedRouteComponent component={<HospitalPlanList />} />}
					/>
					<Route
						path="hospital-management/:id/billing/:page"
						element={<LoadProtectedRouteComponent component={<HospitalBillingList />} />}
					/>
					<Route
						path="hospital-management/:id/formcreate/:page"
						element={<LoadProtectedRouteComponent component={<HospitalFormCreate />} />}
					/>
					<Route
						path="hospital-management/:id/formedit/:formid/:page"
						element={<LoadProtectedRouteComponent component={<HospitalFormEdit />} />}
					/>
					<Route
						path="hospital-management/:id/previewform/:formid/:page"
						element={<LoadProtectedRouteComponent component={<HospitalFormPreview />} />}
					/>
					<Route
						path="hospital-management/:id/sortingform/:formid/:page"
						element={<LoadProtectedRouteComponent component={<HospitalFormSorting />} />}
					/>
					<Route
						path="form-management/create"
						element={<LoadProtectedRouteComponent component={<CreateForm />} />}
					/>
					<Route
						path="form-management/list"
						element={<LoadProtectedRouteComponent component={<FormList />} />}
					/>
					<Route
						path="form-management/import-form"
						element={<LoadProtectedRouteComponent component={<ImportForm />} />}
					/>
					<Route
						path="hospital-management/import-form/:id/:page"
						element={<LoadProtectedRouteComponent component={<ImportExportFormHospital />} />}
					/>
					<Route
						path="form-management/editform/:id/:page"
						element={<LoadProtectedRouteComponent component={<EditForm />} />}
					/>
					<Route
						path="form-management/previewform/:id/:page"
						element={<LoadProtectedRouteComponent component={<PreviewForm />} />}
					/>
					<Route
						path="form-management/sortingform/:id/:page"
						element={<LoadProtectedRouteComponent component={<SortingForm />} />}
					/>
					<Route
						path="plan/add"
						element={<LoadProtectedRouteComponent component={<AddPlan />} />}
					/>
					<Route
						path="plan/list"
						element={<LoadProtectedRouteComponent component={<PlanList />} />}
					/>
					<Route
						path="plan/billing"
						element={<LoadProtectedRouteComponent component={<BillingList />} />}
					/>
					<Route
						path="plan/editplan/:id/:page"
						element={<LoadProtectedRouteComponent component={<EditPlan />} />}
					/>
					<Route
						path="*"
						element={<ErrorPage />}
					/>
				</Routes>
			</AuthProvider>
		</Suspense>
	</BrowserRouter>
);

export default RouterContainer;
