import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Form, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { t } from 'i18next';
import Typography from '../../components/Typography';
import Button from '../../components/Button';
import PreviewFormCompoent from '../../components/PreviewFormCompoent';
import { getEditForm } from '../../redux/form';
import { Container, Forms, Header, Wrapper } from './styled';

const HospitalFormPreview = () => {
	const { editFormData, isFetchEditFormSuccess } = useSelector(state => state.form);

	const navigate = useNavigate();
	const dispatch = useDispatch();
	const params = useParams();
	const formId = params.formid;
	const [rowsData, setRowsData] = useState([]);
	const [formName, setFormName] = useState('');
	useEffect(() => {
		dispatch(getEditForm({ form_id: formId, hospital_id : params.id }));
	}, [formId]);

	useEffect(() => {
		if (isFetchEditFormSuccess) {
			setFormName(editFormData.form_name);
			let datas = [];
			let defaultFlags = [];
			editFormData.fields.forEach(element => {
				defaultFlags = parseFlag(element);
				datas.push({ ...element, ...defaultFlags });
			});
			setRowsData(datas);
		}
	}, [isFetchEditFormSuccess, editFormData]);
	const parseFlag = (element) => {
		let fetchOldData = element.fetch_old_data, formdataId = element.formdata_id, type = element.type;
		let flags = [];
		flags['showFetchOldata'] = false;
		flags['showSubTitle'] = false;
		flags['showThemeType'] = false;
		flags['showFormData'] = false;
		flags['showTimerType'] = false;
		if (type === 'text') {
			flags['showFetchOldata'] = true;
			flags['showSubTitle'] = true;
		}
		else if (type === 'textarea') {
			flags['showThemeType'] = true;
			flags['showSubTitle'] = true;
		}
		else if (type === 'date') {
			flags['showSubTitle'] = true;
		}
		else if (type === 'datetime') {
			flags['showSubTitle'] = true;
		}
		else if (type === 'time') {
			flags['showSubTitle'] = true;
		}
		else if (type === 'number') {
			flags['showSubTitle'] = true;
		}
		else if (type === 'lab-report-values') {
			flags['showSubTitle'] = true;
		}
		else if (type === 'formdata') {
			flags['showFormData'] = true;
		}
		else if (type === 'subform') {
			flags['showFetchOldata'] = true;
			flags['showFormData'] = true;
			flags['showTimerType'] = true;
		}
		if (fetchOldData === 1) {
			flags['showFormData'] = true;
		}
		else if (fetchOldData === 0) {
			flags['showFormData'] = false;
		}
		if (formdataId) {
			flags['showFormData'] = true;
		}
		else {
			flags['showFormData'] = false;
		}
		return flags;
	};
	return (
		<Wrapper>
			<Forms>
				<Header>
					<Typography variant="h4" color="black" weight="500">
						{t('HOSPITALPREVIEWFORM.HEADING')} :: {formName}
					</Typography>
					<Button
						title={t('PREVIEWFORM.BACKBUTTON')}
						onClick={() => navigate(`/hospital-management/${params.id}/formlist/${params.page}`)}
						size={'xs'}
					>
						{t('PREVIEWFORM.BACKBUTTON')}
					</Button>
				</Header>
				<Formik>
					{() => (
						<Form>
							<Container>
								{ rowsData && rowsData.length > 0 ?
									<PreviewFormCompoent fields={rowsData} />
									: null
								}
							</Container>
						</Form>
					)}
				</Formik>
			</Forms>
		</Wrapper>
	);
};

export default HospitalFormPreview;